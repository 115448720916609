import React, {Component} from "react";
import "react-multi-carousel/lib/styles.css";
import {Spin} from "antd";
import Cookies from "universal-cookie";

import SingleJobInfoSections from "../Components/SingleJobInfoSections";
import axios from "axios";
import {withRouter} from "react-router-dom";
import {Helmet} from "react-helmet";
import MetaTags from "react-meta-tags";
import Image from "../Images/Group24451.png"
const cookies = new Cookies();
class SingleJobInfo extends Component {
    state = {
        singleJob: [],
        metaItemsName:[],
        metaItemsProperty:[],
        isLoading: false,
        metatittle:'',
        url:""
    };

    async componentDidMount() {
        window.scroll(0, 0)
        this.setState({url: this.props.match.url})

        let lang = "";
        if (cookies.get("direction") && cookies.get("direction") === "ltr" ) {
            this.setState({lang: "en"});
            lang = "en";
        } else {
            this.setState({lang: "ar"});
            lang = "ar";
        }

        await axios.get(process.env.REACT_APP_API_URL,{
            params: {
                "url": "api/resource/Jobs/"+encodeURIComponent(this.props.match.params.id)
            }
        })
            .then((response) => {
                let singleJob = response.data.data
                this.setState({singleJob,isLoading:false})
            });


    }

    render() {

        let rendered = this.state.isLoading ? (
            <>
                <div className="container my-5">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="example">
                                <Spin size="large" style={{margin: "auto"}}/>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        ) : (
            <>
               <SingleJobInfoSections singleJob={this.state.singleJob}/>
                <Helmet>
                    <link href={"https://variiance.com" + this.state.url} rel="canonical"/>
                </Helmet>
                <MetaTags>
                    <title>{this.state.singleJob.meta_title}</title>
                    <meta name="description" content={this.state.singleJob.meta_description}/>
                    <meta content="IE=edge" httpEquiv="X-UA-Compatible"/>
                    <meta property="og:type" content="website"/>
                    <meta property="og:url" content={"https://variiance.com" + this.state.url}/>
                    <meta property="og:description" content={this.state.singleJob.meta_description}/>
                    <meta property="og:title" content={this.state.singleJob.meta_title}/>
                    <meta property="og:image" content={Image}/>
                    <meta property="og:image:alt" content="single-job-nfo"/>
                    <meta property="og:site_name" content="Variiance"/>
                    <meta content={this.state.singleJob.meta_title} name="twitter:title"/>
                    <meta content={this.state.singleJob.meta_description} name="twitter:description"/>
                    <meta content={Image} name="twitter:image"/>
                    <meta content="summary" name="twitter:card"/>
                    <meta name="twitter:url" content={"https://variiance.com" + this.state.url}/>
                    <meta content="@Variianceglobal" name="twitter:site"/>
                </MetaTags>
            </>
        );
        return <div>
            {rendered}
        </div>
    }
}

export default withRouter(SingleJobInfo);




