import React, {Component} from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';


class TermsAndConditions extends Component {
    state = {
        allData: this.props.Terms,
    };

    render() {
        let {allData} = this.state;


        return (
            <>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 mt-5">
                            <h2 className="list1">{allData.title}</h2>

                            <div dangerouslySetInnerHTML={{__html: allData.description}}></div>

                        </div>
                        <div className="col-md-6">
                            <LazyLoadImage className={allData.image_1_alt} src={allData.image_1} />
                            <LazyLoadImage className={allData.image_2_alt} src={allData.image_2} />
                        </div>
                    </div>
                </div>

            </>
        );
    }
}

export default TermsAndConditions;
