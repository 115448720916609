import React from "react";
import {Link, withRouter} from "react-router-dom";
import logo from "../Images/Group 110.svg"
import icolang from "../Images/FontAwsome (globe-africa).svg"
import {NavHashLink} from "react-router-hash-link";
import i18n from "./i18n/i18n";
import {withTranslation} from 'react-i18next';
import avatar1 from "../Images/256-512.png";
import Cookies from 'universal-cookie';
import EcoSystem from "./NavBarEcoSystem";
import keycloak from "../keycloak";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown,} from "reactstrap";
import {RiArrowDropDownLine} from "react-icons/ri";
import IconX from "../Images/icon-x.png"
import * as Icon from "react-feather";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const cookies = new Cookies();
const scrollWithOffset = (el, offset) => {
    const elementPosition = el.offsetTop - offset;
    window.scroll({
        top: elementPosition,
        left: 0,
        behavior: "smooth"
    });
}
const onRemoveClick = (e) => {
    document.getElementById("offcanvasNavbar").classList.remove("show")
    if (document.querySelector(".offcanvas-backdrop") !== null) {
        document.querySelector(".offcanvas-backdrop").classList.remove("show")
        let canvas = document.querySelector('.offcanvas-backdrop');
        canvas.style.display = 'none';
    }
};

class NavBar extends React.Component {
    state = {
        General: this.props.General,
        country: localStorage.getItem('i18nextLng') === "en" || localStorage.getItem('i18nextLng') === "en-US" ? "English" : localStorage.getItem('i18nextLng') === "he" ? "العربية" : 'English',
        lanug: localStorage.getItem('i18nextLng') === "us" || localStorage.getItem('i18nextLng') === "en-US" ? "us" : localStorage.getItem('i18nextLng') === "he" ? "eg" : 'us',
        // lanug: cookies.get('lanug') ? cookies.get('lanug') : 'us',
        direction: cookies.get('direction') ? cookies.get('direction') : "ltr",
    };

    changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    }


    render() {
        let {t} = this.props
        return (
            <>
                <nav className="navbar navbar-expand-lg navbar-light  fixed-top changebg">
                    <div className="container-fluid p-lg-0 m-lg-0">
                        <Link className="navbar-brand" to="/">
                            <LazyLoadImage alt="logo variiance"  src={logo} className="logo"/>
                        </Link>
                        <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="offcanvas offcanvas-end" data-bs-scroll="true" tabIndex="-1"
                             id="offcanvasNavbar"
                             aria-labelledby="offcanvasNavbarLabel">
                            <div className="offcanvas-header">
                                <Link className="offcanvas-title" to='/' id="offcanvasNavbarLabel"><LazyLoadImage alt="logo"  src={logo} className="logo"/></Link>
                                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                                        aria-label="Close"></button>
                            </div>
                            <div className="offcanvas-body">
                                <ul className="navbar-nav justify-content-start flex-grow-1 pe-xl-3">
                                    <li className="nav-item">
                                        <NavHashLink onClick={onRemoveClick} to="/" className="nav-link"
                                                     activeClassName="current">
                                            {t('Home')}
                                        </NavHashLink>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link dropdown-toggle" href="#" id="navbarScrollingDropdown"
                                           role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <span style={{
                                                color: "#ef8900",
                                                fontWeight: "bold"
                                            }}>{t('ECO')}</span>{t('System')}
                                        </a>
                                        <ul className="dropdown-menu Eco" aria-labelledby="navbarScrollingDropdown">
                                            <EcoSystem Eco={this.state.General}/>
                                        </ul>

                                    </li>
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" href="#" id="navbarScrollingDropdown"
                                           role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            {t('Company')}
                                        </a>
                                        <ul className="dropdown-menu dropdown-menuCustom company" aria-labelledby="navbarScrollingDropdown">
                                            <LazyLoadImage ata-bs-dismiss="offcanvas"
                                                 aria-label="Close" alt="close"  src={IconX} width={30} height={30} className="m-auto imageMobileX" style={{maxWidth:"100%",cursor:"pointer"}} />
                                            <li data-bs-dismiss="offcanvas" className="itemBorder"
                                                aria-label="Close" >
                                                <Link className="dropdown-item dropdown-itemCustom" to="/AboutUS">{t('About Us')}</Link>
                                            </li>
                                            <li  data-bs-dismiss="offcanvas" className="itemBorder"
                                                aria-label="Close">
                                                <Link className="dropdown-item dropdown-itemCustom" to="/ContactUS"
                                                >{t('Contact Us')}</Link>
                                            </li>
                                            <li data-bs-dismiss="offcanvas" className="itemBorder"
                                                aria-label="Close">
                                                <Link className="dropdown-item dropdown-itemCustom" to="/events"
                                                >{t('events')}</Link>
                                            </li>
                                        </ul>
                                    </li>

                                    {/*<li className="nav-item " data-bs-dismiss="offcanvas"*/}
                                    {/*    aria-label="Close">*/}
                                    {/*    <a href={process.env.REACT_APP_PILLARS} target="_blank" className=" invest">*/}
                                    {/*        {t('Invest')}*/}
                                    {/*    </a>*/}
                                    {/*</li>*/}
                                    <li className="nav-item">
                                        <NavHashLink onClick={onRemoveClick} to="/Blogs" className="nav-link"
                                                     activeClassName="current">
                                            {t("Blogs")}
                                        </NavHashLink>
                                    </li>

                                    <li className="nav-item">
                                        <NavHashLink onClick={onRemoveClick} to="/careers" className="nav-link"
                                                     activeClassName="current">
                                            {t('Careers')}
                                        </NavHashLink>
                                    </li>

                                </ul>
                                <div className="navbar-nav justify-content-end flex-grow-1 pe-3">
                                    {keycloak && !keycloak.authenticated ?
                                        <>
                                            <li className="nav-item mx-xl-2 mx-lg-1 mobile-margin">
                                                    <button type="button" onClick={() =>
                                                        keycloak && !keycloak.authenticated
                                                            ? keycloak.register()
                                                            : keycloak.logout()
                                                    }
                                                            className="registerButton">{t('Create Account')}</button>
                                            </li>
                                            <li className="nav-item mx-xl-2 mx-lg-1 mobile-margin">
                                                    <button type="button" onClick={() =>
                                                        keycloak && !keycloak.authenticated
                                                            ? keycloak.login()
                                                            : keycloak.logout()
                                                    }
                                                            className="loginButton">{t('Login')}</button>
                                            </li>
                                        </> :

                                        <ul
                                            className="ulmob nav navbar-nav navbar-nav-user float-right"
                                            style={{}}
                                        >
                                            <UncontrolledDropdown
                                                tag="li"
                                                className="dropdown-user nav-item "
                                            >
                                                <DropdownToggle
                                                    tag="a"
                                                    className=" linksnav dropdown-user-link styleUL"
                                                >
                                                        <span data-tour="user">
                                                          <LazyLoadImage
                                                               className="pic ml-5"
                                                              alt="profile"
                                                              style={{borderRadius: "100%"}}
                                                              src={
                                                                  keycloak.tokenParsed.image
                                                                      ? keycloak.tokenParsed.image
                                                                      : avatar1
                                                              }
                                                          />
                                                        </span>
                                                    <span className="mx-1"
                                                          style={{color: "#908e8e",fontWeight:"600"}}> {keycloak.tokenParsed.given_name}
                                                        <RiArrowDropDownLine size={25}/> </span>

                                                </DropdownToggle>
                                                <DropdownMenu right className="langDrop">
                                                    <DropdownItem
                                                        tag="a"
                                                        onClick={() => {
                                                            keycloak.logout()
                                                        }}
                                                        className="btn-link align-middle dropdown-item "
                                                    >
                                                        <Icon.Power size={14} className="mr-50" />
                                                        <span
                                                            className=" align-middle"
                                                            onClick={() => {
                                                                keycloak.logout()
                                                            }}
                                                        >
                                                            {" "}{t("Logout")}{" "}
                        </span>
                                                    </DropdownItem>
                                                </DropdownMenu>


                                            </UncontrolledDropdown>
                                        </ul>}
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" href="#" id="navbarScrollingDropdown"
                                           role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            {cookies.get('direction') === "rtl"?
                                                <> <span><LazyLoadImage alt="Arabic language icon"  src={icolang}/></span><span className="mx-2">عربي</span></>:<> <span><LazyLoadImage alt="English language logo"  src={icolang}/><span className="mx-2">English</span></span></>}
                                        </a>
                                        <ul className="dropdown-menu langDrop"
                                            aria-labelledby="navbarScrollingDropdown">
                                            <li style={{borderBottom: "2px solid  #ef8900 "}}><a
                                                className="dropdown-item" onClick={(e) => {
                                                if (cookies.get('direction') === "rtl") {
                                                    this.setState({
                                                        lanug: "en",
                                                    })
                                                    localStorage.setItem('direction', "ltr")
                                                    cookies.set('lanug', 'en')
                                                    cookies.set('direction', 'ltr')
                                                    cookies.set('locale_changed', true)
                                                    cookies.set('locale','en')
                                                    cookies.set('language sso','en')
                                                    // setInterval(() => {
                                                    if (this.props.location.pathname.length === 3){
                                                        window.location.href="/";
                                                    }else {
                                                        if(!this.props.location.pathname.startsWith("/en")&& !this.props.location.pathname.startsWith("/ar")){
                                                            window.location.href=this.props.location.pathname
                                                        }else{
                                                            window.location.href=this.props.location.pathname.substring(3);

                                                        }                                                        // window.location.href=this.props.location.pathname.substring(3);
                                                    }
                                                    // }, 100);
                                                }


                                            }

                                            }>English</a></li>
                                            <li><a className="dropdown-item" onClick={(e) => {
                                                if (cookies.get('direction') === "ltr") {
                                                    this.setState({
                                                        lanug: "eg",
                                                    })
                                                    localStorage.setItem('direction', "rtl")
                                                    cookies.set('lanug', 'eg')
                                                    cookies.set('direction', 'rtl')
                                                    cookies.set('locale_changed', true)
                                                    cookies.set('locale','ar')
                                                    cookies.set('language sso','ar')
                                                    // setInterval(() => {

                                                    if (this.props.location.pathname.length === 3){
                                                        window.location.href="/";
                                                    }else {
                                                        if(!this.props.location.pathname.startsWith("/en") && !this.props.location.pathname.startsWith("/ar")){
                                                            window.location.href=this.props.location.pathname
                                                        }else{
                                                            window.location.href=this.props.location.pathname.substring(3);
                                                        }

                                                    }
                                                    // }, 100);
                                                }


                                            }

                                            }>العربية</a></li>
                                        </ul>

                                    </li>
                                </div>

                            </div>
                        </div>
                    </div>
                </nav>
            </>


        );
    }
}

export default withTranslation()(withRouter(NavBar));

