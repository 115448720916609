import React, {Component} from "react";
import logo from "../Images/Group 226.svg"
import {withTranslation} from "react-i18next";
import {AiFillCaretRight} from "react-icons/ai";
import {Link} from "react-router-dom";
import {HashLink} from "react-router-hash-link";
import { LazyLoadImage } from 'react-lazy-load-image-component';

class Footer extends Component {
    state = {
        General: this.props.General
    };

    render() {
        let {t} = this.props
        let {General} = this.state;
        let socialLink = General.social_network.map(res => {
            return (
                <a key={res.name} href={res.url}><span className="ico mx-1"> <LazyLoadImage alt={res.icon_alt}  src={res.icon} width={30}
                                                                                            height={30}/></span></a>
            )
        })

        let EcoSystemIconsActive = General.ecosystemservicesvariiance.map(res => {
            if(res.active === 1){
                return (
                    <div key={res.name} className="col-4">
                        <a href={res.url}> <LazyLoadImage alt={res.white_icon_alt}  className="icon" src={res.white_icon} /></a>
                    </div>
                )
            }else {
                return null
            }

        })
        let EcoSystemListActive = General.ecosystemservicesvariiance.map(res => {
            if(res.active === 1){
                return (
                    <div key={res.name} className="col-12">
                        <a href={res.url}> <li className="py-1">
                            <AiFillCaretRight/> {res.shortcut}
                        </li>
                        </a>
                    </div>
                )
            }else {
                return null
            }

        })
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear()
        return (
            <>
                <footer className="footer">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-3 mt-5">
                                <LazyLoadImage alt="logo variiance"  className="logovariianc" src={logo}/>
                                <div className="row px-5 pt-3">
                                    {EcoSystemIconsActive}
                                </div>
                            </div>
                            <div className="col-lg-2 listMarggin">
                                <strong className="head1">{t('Variiance')}</strong>
                                <ul className="list2">
                                    <li className="py-1">
                                        <HashLink to="/#Home">
                                            <AiFillCaretRight/> {t('Home')}
                                        </HashLink>
                                    </li>

                                    <li className="py-1">
                                        <HashLink to="/AboutUS">
                                            <AiFillCaretRight/> {t('About Us')}
                                        </HashLink>
                                    </li>
                                    <li className="py-1">
                                        <HashLink to="/ContactUS">
                                            <AiFillCaretRight/> {t('Contact Us')}
                                        </HashLink>

                                    </li>
                                    <li className="py-1">
                                        <HashLink to="/careers">
                                            <AiFillCaretRight/> {t('Careers')}
                                        </HashLink>

                                    </li>
                                    <li className="py-1">
                                        <HashLink to="/Blogs">
                                            <AiFillCaretRight/> {t("Blogs")}
                                        </HashLink>

                                    </li>
                                    <li className="py-1">
                                        <HashLink to="/FAQ">
                                            <AiFillCaretRight/> {t('FAQS')}
                                        </HashLink>
                                    </li>

                                    {/*<li className="py-1">*/}
                                    {/*    <AiFillCaretRight/> Blogs*/}
                                    {/*</li>*/}
                                    {/*<li className="py-1">*/}
                                    {/*    <AiFillCaretRight/> Careers*/}
                                    {/*</li>*/}

                                </ul>
                            </div>
                            <div className="col-lg-2 listMarggin">
                                <strong className="head1">{t('ECOSYSTEM')}</strong>
                                <ul className="list2">
                                    {EcoSystemListActive}
                                </ul>
                            </div>
                            <div className="col-lg-5 text">
                                <LazyLoadImage alt="variiance logo"  className="logo2" src={logo}/>
                                <p className="p">
                                    {General.description}
                                </p>
                                <h6 className="SocialMedia">{t('Social Media')}</h6>
                                <div className="icons">
                                    {socialLink}
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
                <div className="container-fluid ">
                    <div className="row underborder">
                        <div className="col-md-6">
                            <p id="copyright" className="copyright"><span className="copyright2">{t('Copyrights', {year: currentYear})}</span>
                            </p>
                        </div>
                        <div className="col-md-6">
                            <div className="PrivacyPolicy1"><span className="PrivacyPolicy2"><Link
                                to="/privacy">{t('Privacy & Policy')}</Link></span>
                                <span className="TermsConditions"> <Link
                                    to="#">{t('Terms & Conditions')}</Link></span></div>

                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withTranslation()(Footer);
