import React, {Component} from "react";
import ReCaptchaV2 from 'react-google-recaptcha'
import {AiFillMail, AiFillMessage} from "react-icons/ai";
import {FaPhoneAlt, FaUserAlt} from "react-icons/fa";
import {BsFillBagDashFill} from "react-icons/bs";
import {MdEmail} from "react-icons/md";
import {withTranslation, Translation} from 'react-i18next';
import i18n from "./i18n/i18n";
import axios from "axios";
import Select from "react-select"
import Lottie from "react-lottie-player";
import formSubmit from "../Images/33886-check-okey-done.json"
import { LazyLoadImage } from 'react-lazy-load-image-component';

class ContactSec extends Component {

    constructor(props) {
        super(props);
        this.state = {
            allData: this.props.contact,
            errors: {},
            isLoading: false,
            departments: [],
            department: "",
            name: "",
            phone: "",
            email: '',
            message: '',
            disableButton: false,
            formHide: false,
            Captcha: "",
            ID: ''
        }
    }



    onChange = (value) => {
        this.setState({Captcha: value}, () => {
        })
    }


    handleChangeGeneralInputs = (event, name) => {
        this.setState({[event.target.name]: event.target.value.replace(/\s\s/g, '')}, () => {
            let errors = {};
            let formIsValid = true;
            if (name === "name") {
                if (!this.state.name) {
                    formIsValid = false;
                    errors.name = <Translation i18n={i18n}>
                        {
                            (t, {i18n}) => <p>{t("Cannot be empty")}</p> // will be looked up from namespace ns1
                        }
                    </Translation>
                } else if (this.state.name.length < 3) {
                    formIsValid = false;
                    errors.name = <Translation i18n={i18n}>
                        {
                            (t, {i18n}) => <p>{t("Cannot less than three char")}</p> // will be looked up from namespace ns1
                        }
                    </Translation>
                } else if (typeof this.state.name !== "undefined") {
                    if (!this.state.name.match(/^[A-Za-z-أ-يa\s]+$/gi)) {
                        formIsValid = false;
                        errors.name = <Translation i18n={i18n}>
                            {
                                (t, {i18n}) => <p>{t("Cannot be empty and Only letters")}</p> // will be looked up from namespace ns1
                            }
                        </Translation>
                    }
                } else {
                    errors.name = ""
                    formIsValid = true;

                }
            }

            if (name === "email") {
                if (!this.state.email) {
                    formIsValid = false;

                    errors.email = <Translation i18n={i18n}>
                        {
                            (t, {i18n}) => <p>{t("Cannot be empty")}</p> // will be looked up from namespace ns1
                        }
                    </Translation>
                } else if (typeof this.state.email !== "undefined") {
                    if (
                        !this.state.email.match(
                            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                        )
                    ) {
                        formIsValid = false;
                        errors.email = <Translation i18n={i18n}>
                            {
                                (t, {i18n}) => <p>{t("Please enter valid mail")}</p> // will be looked up from namespace ns1
                            }
                        </Translation>
                    }
                } else {
                    errors.email = ("")
                    formIsValid = true
                }
            }

            if (name === "phone") {
                if (!this.state.phone) {
                    formIsValid = false;
                    errors.phone = <Translation i18n={i18n}>
                        {
                            (t, {i18n}) => <p>{t("Cannot be empty")}</p> // will be looked up from phonespace ns1
                        }
                    </Translation>
                } else if (this.state.phone.length < 10) {
                    formIsValid = false;
                    errors.phone = <Translation i18n={i18n}>
                        {
                            (t, {i18n}) => <p>{t("Cannot less than ten char")}</p> // will be looked up from phonespace ns1
                        }
                    </Translation>
                } else if (typeof this.state.phone !== "undefined") {
                    if (
                        !this.state.phone.match(
                            /^(01|00201|\+201|201)[0|1|2|5]{1}\d{8}/
                        )
                    ) {
                        formIsValid = false;
                        errors.phone = <Translation i18n={i18n}>
                            {
                                (t, {i18n}) => <p>{t("Please enter valid phone")}</p> // will be looked up from namespace ns1
                            }
                        </Translation>
                    }
                } else {
                    errors.phone = ""
                    formIsValid = true;

                }
            }

            if (name === "message") {
                if (!this.state.message) {
                    formIsValid = false;
                    errors.message = <Translation i18n={i18n}>
                        {
                            (t, {i18n}) => <p>{t("Cannot be empty")}</p> // will be looked up from messagespace ns1
                        }
                    </Translation>
                } else if (this.state.message.length < 10) {
                    formIsValid = false;
                    errors.message = <Translation i18n={i18n}>
                        {
                            (t, {i18n}) => <p>{t("Cannot less than ten char")}</p> // will be looked up from messagespace ns1
                        }
                    </Translation>
                } else {
                    errors.message = ""
                    formIsValid = true;

                }
            }


            this.setState({errors: errors});
            return formIsValid;
        });

    }
    handleValidation = () => {
        let errors = {};
        let formIsValid = true;

        if (!this.state.department) {
            formIsValid = false;
            errors.department = <Translation i18n={i18n}>
                {
                    (t, {i18n}) => <p>{t("Cannot be empty")}</p> // will be looked up from namespace ns1
                }
            </Translation>
        }


        if (this.state.name.length < 3) {
            formIsValid = false;
            errors.name = <Translation i18n={i18n}>
                {
                    (t, {i18n}) => <p>{t("Cannot less than three char")}</p> // will be looked up from namespace ns1
                }
            </Translation>
        }

        if (typeof this.state.name !== "undefined") {
            if (!this.state.name.match(/^[A-Za-z-أ-يa\s]+$/gi)) {
                formIsValid = false;
                errors.name = <Translation i18n={i18n}>
                    {
                        (t, {i18n}) => <p>{t("Cannot be empty and Only letters")}</p> // will be looked up from namespace ns1
                    }
                </Translation>
            }
        }

        if (!this.state.name) {
            formIsValid = false;
            errors.name = <Translation i18n={i18n}>
                {
                    (t, {i18n}) => <p>{t("Cannot be empty")}</p> // will be looked up from namespace ns1
                }
            </Translation>
        }
        if (!this.state.email) {
            formIsValid = false;

            errors.email = <Translation i18n={i18n}>
                {
                    (t, {i18n}) => <p>{t("Cannot be empty")}</p> // will be looked up from namespace ns1
                }
            </Translation>
        } else if (typeof this.state.email !== "undefined") {
            if (
                !this.state.email.match(
                    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                )
            ) {
                formIsValid = false;
                errors.email = <Translation i18n={i18n}>
                    {
                        (t, {i18n}) => <p>{t("Please enter valid mail")}</p> // will be looked up from namespace ns1
                    }
                </Translation>
            }
        }

        if (this.state.phone.length < 10) {
            formIsValid = false;
            errors.phone = <Translation i18n={i18n}>
                {
                    (t, {i18n}) => <p>{t("Cannot less than ten char")}</p> // will be looked up from phonespace ns1
                }
            </Translation>
        }
        if (typeof this.state.phone !== "undefined") {
            if (
                !this.state.phone.match(
                    /^(01|00201|\+201|201)[0|1|2|5]{1}\d{8}/
                )
            ) {
                formIsValid = false;
                errors.phone = <Translation i18n={i18n}>
                    {
                        (t, {i18n}) => <p>{t("Please enter valid phone")}</p> // will be looked up from namespace ns1
                    }
                </Translation>
            }
        }
        if (!this.state.phone) {
            formIsValid = false;
            errors.phone = <Translation i18n={i18n}>
                {
                    (t, {i18n}) => <p>{t("Cannot be empty")}</p> // will be looked up from phonespace ns1
                }
            </Translation>
        }

        if (this.state.message.length < 10) {
            formIsValid = false;
            errors.message = <Translation i18n={i18n}>
                {
                    (t, {i18n}) => <p>{t("Cannot less than ten char")}</p> // will be looked up from messagespace ns1
                }
            </Translation>
        }

        if (!this.state.message) {
            formIsValid = false;
            errors.message = <Translation i18n={i18n}>
                {
                    (t, {i18n}) => <p>{t("Cannot be empty")}</p> // will be looked up from messagespace ns1
                }
            </Translation>
        }
        if (this.state.Captcha === '') {
            formIsValid = false;
            errors.Captcha = <Translation i18n={i18n}>
                {
                    (t, {i18n}) => <p>{t("must Check")}</p> // will be looked up from namespace ns1
                }
            </Translation>
        }

        this.setState({errors: errors})
        return formIsValid;

    }

    handleSubmit = () => {
        if (this.handleValidation() === false) {
        } else {
            this.setState({disableButton: true})
            const newparams = {
                url: "variiance_website/en/contact_us",
                data: {
                    full_name: this.state.name,
                    phone: this.state.phone,
                    email: this.state.email,
                    message: this.state.message,
                    department: this.state.department,
                    "g-recaptcha-response": this.state.Captcha

                },
            };
            axios.post(process.env.REACT_APP_API_URL, newparams)
                .then((response) => {
                    let res = response.data.success;

                    if (res === true) {
                        this.setState({formHide: true})
                        setTimeout(() => {
                            this.setState({formHide: false})
                        }, 6000);
                        this.setState({
                            name: "",
                            email: "",
                            phone: "",
                            message: "",
                            department: "",
                            disableButton: false,
                            Captcha: ""
                        });
                    } else {

                    }
                });
        }

    }
    handleSelect = (e, name) => {
        this.setState({[name]: e.value}, () => {
            let errors = {};
            let formIsValid = true;
            if (!this.state.department) {
                formIsValid = false;
                errors.department = <Translation i18n={i18n}>
                    {
                        (t, {i18n}) => <p>{t("Cannot be empty")}</p> // will be looked up from namespace ns1
                    }
                </Translation>
            } else {
                errors.message = ""
                formIsValid = true;

            }

            this.setState({errors: errors});
            return formIsValid;

        });


    };




    render() {
        let {t} = this.props
        let {allData} = this.state;
        let singleDepartment = this.state.allData.departmentsvariiance.map(res => {
                return {value: res.name, label: res.title}
            }
        )
        return (
            <>
                <div className="container">
                    <div className="row contactus">
                        <div className="col-md-6 ">
                            <div>
                                <h2 className="contactHead">{allData.title}</h2>
                                <h4 className="contactDesc">{allData.subtitle}</h4>
                                <div className="text-center "><LazyLoadImage alt={allData.contact_image_alt} src={allData.contact_image}
                                                                   className="contactimg pt-5"/></div>
                            </div>
                        </div>
                        {this.state.formHide === false ? <div className="col-md-6">
                            <p className="alertred px-5">
                                {allData.hint}
                            </p>


                            <form onSubmit={(e) => e.preventDefault()} className="row g-3 px-5">
                                <div className="input-group flex-nowrap">
                                    <span className="input-group-text" id="addon-wrapping"><BsFillBagDashFill/></span>
                                    <Select
                                        className="form-control"
                                        classNamePrefix="select"
                                        placeholder={t('Choose...')}
                                        value={singleDepartment.find(
                                            (obj) => obj.value === this.state.department
                                        )}
                                        name="department"
                                        options={singleDepartment}
                                        // onChange={this.handleChangeGeneralInputs}
                                        onChange={(e) => this.handleSelect(e, 'department')}
                                    />
                                </div>
                                <span className="error">{this.state.errors.department}</span>


                                <div className="input-group flex-nowrap">
                                    <span className="input-group-text" id="addon-wrapping"><FaUserAlt/></span>
                                    <input type="text" className="form-control" placeholder={t('Username')}
                                           aria-label="name"
                                           maxLength="45"
                                           id="exampleFormControlInput1"
                                           name="name"
                                           value={this.state.name}
                                           onSelectCapture={(e) => this.handleChangeGeneralInputs(e, 'name')}
                                           onChange={(e) => this.handleChangeGeneralInputs(e, 'name')}
                                    />
                                </div>
                                <span className="error">{this.state.errors.name}</span>


                                <div className="input-group flex-nowrap">
                                    <span className="input-group-text" id="addon-wrapping"><MdEmail/></span>
                                    <input type="email" className="form-control" placeholder={t('email')}
                                           aria-label="email"
                                           value={this.state.email}
                                           name="email"
                                           onSelectCapture={(e) => this.handleChangeGeneralInputs(e, 'email')}
                                           onChange={(e) => this.handleChangeGeneralInputs(e, 'email')}
                                           aria-describedby="addon-wrapping"/>
                                </div>
                                <span className="error">{this.state.errors.email}</span>


                                <div className="input-group flex-nowrap">
                                    <span className="input-group-text" id="addon-wrapping"><FaPhoneAlt/></span>
                                    <input type="number" className="form-control" placeholder={t("Phone Number")}
                                           aria-label="phone"
                                           name="phone"
                                           value={this.state.phone}
                                           onSelectCapture={(e) => this.handleChangeGeneralInputs(e, 'phone')}
                                           onChange={(e) => this.handleChangeGeneralInputs(e, 'phone')}
                                           aria-describedby="addon-wrapping"/>
                                </div>
                                <span className="error">{this.state.errors.phone}</span>


                                <div className="input-group">
                                    <span className="input-group-text"><AiFillMessage/></span>
                                    <textarea type="text" className="form-control"
                                              aria-label="message"
                                              name="message"
                                              value={this.state.message}
                                              onSelectCapture={(e) => this.handleChangeGeneralInputs(e, 'message')}
                                              onChange={(e) => this.handleChangeGeneralInputs(e, 'message')}
                                              aria-describedby="addon-wrapping" placeholder={t("Message")}></textarea>
                                </div>
                                <span className="error">{this.state.errors.message}</span>

                                <ReCaptchaV2 onChange={this.onChange} className="capacha"
                                             sitekey={process.env.REACT_APP_SITE_KEY}/>
                                <div className="col-12 text-center">
                                    <button className="btn btn-dark" onClick={this.handleSubmit}
                                            type="submit">{t('Send')}</button>
                                </div>
                            </form>

                        </div> : <Lottie
                            loop={false}
                            animationData={formSubmit}
                            play
                            style={{width: 200, margin: "auto"}}
                        />}
                    </div>
                    <div className="row contactus">
                        <div className="col-md-6 ">
                            <div>
                                <h2 className="contactHead">{allData.info_title}</h2>
                                <h4 className="contactDesc pt-3">{allData.info_subtitle}</h4>
                                <div className="row pt-5">
                                    <div className="col-md-6">
                                        <span> <FaPhoneAlt color="#ee851d" size={25}/> <span
                                            className="mx-2"> {allData.info_phones}</span></span>
                                    </div>

                                    <div className="col-md-6">

                                        <span>  <AiFillMail color="#ee851d" size={25}/> <span
                                            className="mx-2"> {allData.info_emails}</span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 text-center">
                            <LazyLoadImage alt={allData.info_image_alt} src={allData.info_image} className="contactimg pt-5"/>
                        </div>
                    </div>
                </div>


                <div className="container-fluid p-0">
                    <div className="row contactus">
                        <div style={{height: "60vh", width: "100%"}}>
                            <iframe
                                src={allData.map_link}
                                width="100%" height="100%" style={{border: "0px"}} allowFullScreen=""
                                loading="lazy"></iframe>
                        </div>
                    </div>
                </div>
            </>
        )
    }


}

export default withTranslation()(ContactSec);
