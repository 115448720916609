import React, {Component} from "react";
import {withTranslation} from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import vast from "../Images/Group 30028.svg"


class VastSections extends Component {
    state = {
        allData: this.props.vast,
        hidePillars: false
    };

    hidePillarsIcon = () => {
        this.setState({hidePillars: true})
    }


    render() {
        let {t} = this.props

        let smartSingle= this.state.allData.smartequipmentvariiance.map(res=>{
            return(
                <div className="col-lg-4 col-md-6 noPadding" key={res.name}>
                    <div className="padding-card-fourth">
                        <LazyLoadImage src={res.icon} alt={res.icon_alt}  className="mt-4 mb-3" width="35px" height="40px"/>
                        <p className="title-card">{res.title}</p>
                        <p className="description-card">{res.description}</p>
                    </div>

                </div>
            )
        })

        let singleSlider = this.state.allData.gallayvariiance.map((res,index)=>{
            return(
                <div  className={index+1 === 1 ? "carousel-item active" :"carousel-item"} key={res.name}>
                    <LazyLoadImage src={res.image} alt={res.image_alt}  className="d-block w-100"/>
                </div>
            )
        })


        return (
            <>

                <div id="vast" className="container-fluid noPadding">
                    <video autoPlay muted loop id="myVideo">
                        <source src={this.state.allData.video} type="video/mp4"/>
                    </video>
                </div>
                <div id="Vast" className="container-fluid noPadding">
                    <div id="VastSecond">
                        <div className="row m-0">
                            <div className="col-lg-5">
                                <div className="padding-card-2">
                                    <p className="location mt-5">{this.state.allData.title_address}</p>
                                    <p className="locationDescription">{this.state.allData.address}</p>
                                    <hr className="mt-3 mb-3"
                                        style={{
                                            height: "3px",
                                            width: "37%",
                                            background: "#EE851D",
                                            opacity: "1",
                                            borderRadius: "3px"
                                        }}/>
                                    <p className="mapsDescription" dangerouslySetInnerHTML={{ __html: this.state.allData.near_by}}></p>

                                </div>
                                <div className="">
                                    <LazyLoadImage src={this.state.allData.right_image} alt={this.state.allData.right_image_alt}  width="100%"/>

                                </div>
                            </div>
                            <div className="col-lg-7">
                                <LazyLoadImage src={this.state.allData.left_image} alt={this.state.allData.left_image_alt}  width="100%" className="imageLogoVariance"/>
                            </div>
                        </div>
                    </div>
                    <div id="VastThird">
                        <div className="row text-center m-auto">
                            <div className="about-vast">
                                <span className="about mt-3">{t('About')}</span>
                                <LazyLoadImage alt="vast logo" src={vast} className="about-image mx-2"/>
                            </div>

                            <div className="text-center width-section mt-5">
                                <p className="paragraph-about text-center">{
                                    this.state.allData.about_vast_description
                                }
                                </p></div>

                        </div>
                    </div>
                    <div className="row background-color-vast">
                        <div className="col-lg-6 padding-card mobile-padding">

                            <p className="header-vast about-vast">{this.state.allData.title_2}</p>
                            <p className="descVast">{this.state.allData.description_2}</p>
                        </div>
                        <div className="col-lg-6  noPadding">
                            <LazyLoadImage alt={this.state.allData.image_2_alt} src={this.state.allData.image_2} width="100%" className="imageLogoVariance"/>
                        </div>
                    </div>
                    <div className="row background-color-vast">
                        <div className="col-lg-6  noPadding">
                            <LazyLoadImage alt={this.state.allData.image_3_alt} src={this.state.allData.image_3} width="100%" className="imageLogoVariance"/>
                        </div>
                        <div className="col-lg-6 padding-card-3">
                            <p className="header-vast about-vast">{this.state.allData.title_3}</p>
                            <p className="descVast">{this.state.allData.description_3}</p>

                        </div>

                    </div>
                    <div className="row background-color-vast mt-5">
                        <p className="smart text-center">{t('Smart Equipment')}</p>
                    </div>
                    <div className="row noPadding">
                        {smartSingle}

                    </div>
                    <div className="row noPadding">
                        <div id="carouselExampleControls" className="carousel slide noPadding" data-bs-ride="carousel">
                            <div className="carousel-inner">
                                {singleSlider}
                            </div>
                            <button className="carousel-control-prev" type="button"
                                    data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button"
                                    data-bs-target="#carouselExampleControls" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>


                </div>


            </>
        );
    }
}

export default withTranslation()(VastSections);
