import React, {Component} from "react";
import Veco from "../Images/Veco.png"
import bg from "../Images/video.png"
import {ReactVideo} from "reactjs-media";
import {withTranslation} from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import keycloak from "../keycloak";


class AboutSec extends Component {
    state = {
        allData: this.props.Data,
    };

    render() {
        let {t} = this.props
        const ValuesItems = this.state.allData.ourvaluesvariiance.map((res) => {
            return(
                <div key={res.name} className="col-lg-4">
                    <dh4 className="AboutSub">{res.title}</dh4>
                    <div className="text-center my-3"><LazyLoadImage alt={res.icon_alt}  width={100}
                                                           height={100} src={res.icon}/></div>
                    <p className="AboutDesc">{res.description}</p>
                </div>
            )
        })

        const MissionItems = this.state.allData.ourstrategyvariiance.map((res) => {
            return(


                <div key={res.name} className="col-lg-4">
                    <dh4 className="AboutSub">{res.title}</dh4>
                    <div className="text-center my-3"><LazyLoadImage alt={res.icon_alt}  width={100}
                                                           height={100} src={res.icon}/></div>
                    <p className="AboutDesc">{res.description}</p>
                </div>

            )
        })

        let {allData} = this.state


        return (
            <>
                <div className="container">
                    <div className="row aboutUS">
                        <div className="col-lg-5">
                            <h2 className="contactHead">{allData.title}</h2>
                            <div className="aboutBorder my-3"></div>
                            <h4 className="AboutSub">{allData.subtitle}</h4>
                            <p className="AboutDesc">{allData.description}</p>
                        </div>
                        <div className="col-lg-7">
                            <div className="text-center"><LazyLoadImage alt={allData.image_alt}  src={allData.image} className="aboutImg pt-5"/></div>
                        </div>

                    </div>
                </div>
                <div className="container-fluid p-0">
                    <div className="row aboutUS ">
                        <div className="col-lg-6">
                            <LazyLoadImage alt={allData.image_vision_alt}  src={allData.image_vision} className="aboutImgVision pt-5"/>
                        </div>
                        <div className="col-lg-6 ">
                            <div className="textMargin">
                                <h2 className="aboutVisionHead">{allData.title_vision}</h2>
                                <div className="aboutBorder2 my-3"></div>
                                <h4 className="aboutVisiondesc pt-3">{allData.description_vision}</h4>
                            </div>
                        </div>

                    </div>
                    <div className="row aboutUS">
                        <div className="col-lg-6 ">
                            <div className="textMargin2">
                                <h2 className="aboutVisionHead">{allData.title_mission}</h2>
                                <div className="aboutBorder2 my-3"></div>
                                <h4 className="aboutVisiondesc pt-3">{allData.description_mission}</h4>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <LazyLoadImage alt={allData.image_mission_alt}  src={allData.image_mission} className="aboutImgMission pt-5"/>
                        </div>

                    </div>
                </div>


                <div className="divBack py-5">
                    <div className="container divInBack p-0">
                        <div className="row py-5 justify-content-center align-items-center" style={{minHeight:"600px" ,maxWidth:"100%",margin:"0px"}}>
                            <div className="col-lg-12 text-center">
                                <LazyLoadImage alt="eco-system"  src={Veco} width={105} height={65}/>
                                <h2 className="aboutVisionHead my-4">{allData.title_ecosystem}</h2>
                            </div>

                            <div className="col-lg-7 py-2">
                                <div style={{width:"90%",margin:"auto"}}>
                                    <ReactVideo
                                        src={allData.videourl_ecosystem}
                                        poster={bg}
                                        primaryColor="white"
                                    />
                                </div>

                            </div>
                            <div className="col-lg-5 py-2">
                                <h2 className="aboutVideodesc">{allData.subtitle_ecosystem}</h2>
                                <div className="aboutBorder3 my-3"></div>
                                <h4 className="aboutVideodesc pt-3">{allData.description_ecosystem}
                                   </h4>
                            </div>
                        </div>
                    </div>

                </div>



                <div className="container">
                    <div className="row aboutUS text-center">
                        <div className="col-lg-12 my-5"><h2 className="contactHead text-center">{allData.title_values}</h2></div>
                        {ValuesItems}
                    </div>

                    <div className="row aboutUS text-center">
                        <div className="col-lg-12 my-5"><h2 className="contactHead text-center">{allData.title_strategy}</h2></div>
                        {MissionItems}
                    </div>


                    <div className="row aboutUS">
                        <div className="col-lg-7">
                            <div className="text-center"><LazyLoadImage alt={allData.image_certification_alt}  src={allData.image_certification} className="aboutImg"/></div>
                        </div>
                        <div className="col-lg-5">
                            <h2 className="aboutVisionHead">{allData.title_certification}</h2>
                            <div className="aboutBorder2 my-3"></div>
                            <p className="AboutDesc">{allData.description_certification}</p>
                        </div>

                        <div className="col-lg-12 my-5 text-center">
                            <button onClick={() =>
                                keycloak && !keycloak.authenticated
                                    ? keycloak.register():null} className="joinbtn btn">{t('Join Us')}</button>
                        </div>
                    </div>

                </div>

            </>
        )
    }


}

export default withTranslation()(AboutSec);
