import React, {Component} from "react";
import CareerJobsPageSections from "../Components/CareerJobsPageSections";
class jobsPage extends Component {



    render() {

        return (
           <CareerJobsPageSections />
        )
    }


}

export default jobsPage;
