import React, {useEffect, useState} from "react";
import "./App.css";
import {Redirect, Route, Router, Switch, useLocation, useRouteMatch, withRouter} from "react-router-dom";
//pages
import {history} from "./history";

import {useTranslation} from "react-i18next";

import {useKeycloak} from "@react-keycloak/web";
import i18n from "i18next";
import axios from "axios";
import keycloak from "./keycloak";
import Cookies from "universal-cookie";
import AboutUS from "./Pages/AboutUs";
import Vast from "./Pages/Vast";
import Terms from "./Pages/Terms&Conditions";
import FAQ from "./Pages/FAQ";
import ContactUS from "./Pages/ContactUS";
import Home from "./Pages/Home";
import Footer from "./Components/Footer";
import NavBar from "./Components/Navbar";
import NotFound from "./Components/Error404";
import {Helmet} from "react-helmet";
import translationsEN from './Components/i18n/en/en.json'
import translationsAR from './Components/i18n/ar/ar.json'
import SingleBlog from "./Pages/SingleBlog";
import Blogs from "./Pages/Blogs.js";
import Events from "./Pages/Events";
import SingleJobInfo from "./Pages/SingleJobInfo";
import JobsPage from "./Pages/JobsPage.js";
import Careers from "./Pages/Careers";
import FormJobs from "./Pages/FormJobs.js";
import SingleEvent from "./Pages/SingleEvent.js";

const cookies = new Cookies();


export function getUser() {
    const params = {
        url: "profile/ar/get_user",
        data: {
            sso_sub: keycloak.tokenParsed.sub,
            student: keycloak.tokenParsed.email,
            name: keycloak.tokenParsed.given_name,
            last_name: keycloak.tokenParsed.family_name,
            student_mobile_number: keycloak.tokenParsed.phone_number ? keycloak.tokenParsed.phone_number : '',
            service_source:"VARIIANCE"
        }
    }
    return  axios.post(process.env.REACT_APP_API_URL, params)
    //     .then((response) => {
    //     localStorage.setItem('student_id', response.data.data.name)
    // }
    //
    // );
        .then((response) => response.data.data.name);
}

const resources = {
    en: {
        translation: translationsEN
    },
    ar: {
        translation: translationsAR
    }
};
let langtList = 'en|ar';
let langArray = ['ar','en'];
const AppRouter = () => {
    const [footer, setFooter] = useState();
    const [navbar, setNavbar] = useState();

    let lang = "";
    let dir = "";
    if (cookies.get("direction") && cookies.get("direction") === "ltr") {
        lang = "en";
        dir = "ltr";
    } else {
        lang = "ar";
        dir = "rtl";
    }
    const params = new URLSearchParams();
    useEffect(() => {
        let pathNameLang = window.location.pathname.split('/')[1];
        if (pathNameLang && pathNameLang !== lang && langArray.indexOf(pathNameLang) !== -1) {
            cookies.set('direction', pathNameLang === "en" ? 'ltr' : 'rtl');
            lang = pathNameLang;
            document.body.dir = pathNameLang === "en" ? 'ltr' : 'rtl';
        } else {
            document.body.dir = dir;
        }
        if (lang === "en") {
            import('./GeneralCss/all.css')
        } else {
            import('./GeneralCss/all.rtl.css')
        }
        i18n.init({
            resources,
            lng: lang, // this is the default language. Try to put this
            fallbackLng: 'en', // default language
            keySeparator: false, // we do not use keys in form messages.welcome
            interpolation: {
                escapeValue: false // react already safes from xss
            }
        });
        params.append("url", "api/resource/VariianceInformation/Info-" + lang);
        axios.get(process.env.REACT_APP_API_URL, {
            params: {
                "url": "api/resource/VariianceInformation/Info-" + lang
            }

        }).then((response) => {
            setFooter(<Footer General={response.data.data}/>)
            setNavbar(<NavBar General={response.data.data}/>)
        });

    }, []);

    const {initialized} = useKeycloak();
    if (!initialized) {
        return true;
    } else {
        if (keycloak.authenticated) {
            // if (cookies.get('locale_changed')) {
            //     cookies.set('language sso', cookies.get('locale'))
            //     cookies.set('locale_changed', "false")
            // } else {
            //     cookies.set('language sso', keycloak.tokenParsed.language != 'undefined' ? cookies.get('language sso') : 'ar')
            // }
            // let languageSettings = [{
            //     "locale": "ar",
            //     'direction': 'rtl',
            //     'lang': 'eg',
            //     'country': 'العربية'
            // }, {"locale": "en", 'direction': 'ltr', 'lang': 'us', 'country': 'English'}];
            //
            // let locale = cookies.get('language sso') && cookies.get('language sso') != 'undefined' ? cookies.get('language sso') : 'ar';
            // cookies.set('lang', languageSettings.find(x => x.locale === locale).lang)
            // cookies.set('country', languageSettings.find(x => x.locale === locale).country)
            // cookies.set('direction', languageSettings.find(x => x.locale === locale).direction)

        }
    }
    if (!localStorage.getItem('student_id') && keycloak.authenticated) {
        getUser()
    }
    if (!keycloak.authenticated && localStorage.getItem('student_id')) {
        localStorage.removeItem('student_id')
    }

    return (
        <>
            <Helmet htmlAttributes={{
                lang: lang,
            }}>
            </Helmet>
            <Router history={history}>
                {navbar}
                <Switch>
                    <Route
                        exact path="/"
                        render={() => {
                            return (
                                <Redirect to={"/" + lang}/>
                            )
                        }}
                    />
                    <Route
                        exact path="/AboutUS"
                        render={() => {
                            return (
                                <Redirect to={"/" + lang + "/AboutUS"}/>
                            )
                        }}
                    />
                    <Route
                        exact path="/Vast"
                        render={() => {
                            return (
                                <Redirect to={"/" + lang + "/Vast"}/>
                            )
                        }}
                    />
                    <Route
                        exact path="/privacy"
                        render={() => {
                            return (
                                <Redirect to={"/" + lang + "/privacy"}/>
                            )
                        }}
                    />
                    <Route
                        exact path="/FAQ"
                        render={() => {
                            return (
                                <Redirect to={"/" + lang + "/FAQ"}/>
                            )
                        }}
                    />
                    <Route
                        exact path="/ContactUS"
                        render={() => {
                            return (
                                <Redirect to={"/" + lang + "/ContactUS"}/>
                            )
                        }}
                    />

                    {/*<Route*/}
                    {/*    exact path="/SingleBlog/:id"*/}
                    {/*    render={() => {*/}
                    {/*        return (*/}
                    {/*            <Redirect to={"/" + lang + "/SingleBlog"}/>*/}
                    {/*        )*/}
                    {/*    }}*/}
                    {/*/>*/}

                    <Route
                        exact path="/Blogs"
                        render={() => {
                            return (
                                <Redirect to={"/" + lang + "/Blogs"}/>
                            )
                        }}
                    />
                    <Route
                        exact path="/events"
                        render={() => {
                            return (
                                <Redirect to={"/" + lang + "/events"}/>
                            )
                        }}
                    />
                    <Route
                        exact path="/jobsPage"
                        render={() => {
                            return (
                                <Redirect to={"/" + lang + "/jobsPage"}/>
                            )
                        }}
                    />
                    <Route
                        exact path="/careers"
                        render={() => {
                            return (
                                <Redirect to={"/" + lang + "/careers"}/>
                            )
                        }}
                    />
                    <Route exact path={`/:lang(${langtList})`} component={Home}></Route>
                    <Route path={`/:lang(${langtList})/AboutUS`} component={AboutUS}></Route>
                    <Route path={`/:lang(${langtList})/Vast`} component={Vast}></Route>
                    <Route path={`/:lang(${langtList})/privacy`} component={Terms}></Route>
                    <Route path={`/:lang(${langtList})/FAQ`} component={FAQ}></Route>
                    <Route path={`/:lang(${langtList})/ContactUS`} component={ContactUS}></Route>
                    <Route path={`/:lang(${langtList})/Blogs`} component={Blogs}></Route>
                    <Route path={`/:lang(${langtList})/events`} component={Events}></Route>
                    <Route path={`/singleEvent/:title/:id`} component={SingleEvent}></Route>
                    <Redirect from="/x_SingleBlog/:title/:id" to='/SingleBlog/:title/:id'/>
                    {/*<Route path="/SingleBlog/:id"  render={(props) => <SingleBlog {...props}/> }/>*/}
                    <Route path={`/SingleBlog/:title/:id`} component={SingleBlog}></Route>
                    <Route path={`/SingleJobInfo/:title/:id`} component={SingleJobInfo}></Route>
                    <Route path={`/:lang(${langtList})/jobsPage`} component={JobsPage}></Route>
                    <Route path={`/:lang(${langtList})/careers`} component={Careers}></Route>
                    <Route path={`/formApplying/:title/:id`} component={FormJobs}></Route>
                    <Route component={NotFound}></Route>
                </Switch>
                {footer}

            </Router>
        </>
    );
};
export default AppRouter;
