import React, {Component} from "react";
import {Translation, withTranslation} from 'react-i18next';
import Cookies from "universal-cookie";
import i18n from "./i18n/i18n";
import {AiOutlineClose} from "react-icons/ai";
import 'animate.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const cookies = new Cookies();
let fileJson = null
let dir = cookies.get("direction")
    ? cookies.get("direction")
    : "rtl";
if (dir === "ltr") {
    fileJson =  require("./ECOSYSTEM.json")
}else{
    fileJson =  require("./ECOSYSTEMARABIC.json")

}
class PopupsEcoSystem extends Component {
    render() {

        return (
            <>
                        <div class="modal animate__animated animate__lightSpeedInLeft " id="VJOBSDiV" tabindex="-1" aria-labelledby="VJOBSDiVLabel" aria-hidden="true" >
                            <div className="modal-dialog modal-dialog-centered">
                                <div  data-aos="flip-left"
                                      data-aos-duration="2000" className="modal-content">
                                    <button type="button" className="text-end btn"
                                            data-bs-dismiss="modal"><AiOutlineClose color="#F7941D" size="20"/>
                                    </button>
                                    <div className="modal-body">
                                        <div className="text-center">
                                            <LazyLoadImage src={fileJson["VJOBS"].image} alt="VJOBS logo" className="text-center" height="50px" />
                                            <p className="tooltip-title mt-2">{fileJson["VJOBS"].title}</p>
                                            <hr className="mt-1 mb-1"
                                                style={{
                                                    height: "3px",
                                                    width: "100%",
                                                    background: "#F7941D",
                                                    opacity: "1",
                                                    borderRadius: "3px"
                                                }}/>
                                            <p className="tooltip-description mt-3">{fileJson["VJOBS"].description}</p>
                                            {fileJson["VJOBS"].ButtonUrl !== "" ? <a href={fileJson["VJOBS"].ButtonUrl} target="_blank">
                                                <button className="btn btn-tooltip mt-3 mb-3"><Translation i18n={i18n}>
                                                    {
                                                        (t, {i18n}) => <>{t("Visit website")}</> // will be looked up from namespace ns1
                                                    }
                                                </Translation></button>
                                            </a>:null }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                <div className="modal animate__animated animate__lightSpeedInLeft" id="VASTDiV" tabIndex="-1" aria-labelledby="VASTDiVLabel"
                     aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div data-aos="flip-left"
                             data-aos-duration="2000" className="modal-content">
                            <button type="button" className="text-end btn"
                                    data-bs-dismiss="modal"><AiOutlineClose color="#F7941D" size="20"/>
                            </button>
                            <div className="modal-body">
                                <div className="text-center">
                                    <LazyLoadImage src={fileJson["VAST"].image} alt="VAST logo" className="text-center" height="50px"/>
                                    <p className="tooltip-title mt-2">{fileJson["VAST"].title}</p>
                                    <hr className="mt-1 mb-1"
                                        style={{
                                            height: "3px",
                                            width: "100%",
                                            background: "#F7941D",
                                            opacity: "1",
                                            borderRadius: "3px"
                                        }}/>
                                    <p className="tooltip-description mt-3">{fileJson["VAST"].description}</p>
                                    {fileJson["VAST"].ButtonUrl !== "" ?
                                        <a href={fileJson["VAST"].ButtonUrl} target="_blank">
                                            <button className="btn btn-tooltip mt-3 mb-3"><Translation i18n={i18n}>
                                                {
                                                    (t, {i18n}) => <>{t("Visit website")}</> // will be looked up from namespace ns1
                                                }
                                            </Translation></button>
                                        </a> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="modal animate__animated animate__lightSpeedInLeft" id="VLCDiV" tabIndex="-1" aria-labelledby="VLCDiVLabel"
                     aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div data-aos="flip-left"
                             data-aos-duration="2000" className="modal-content">
                            <button type="button" className="text-end btn"
                                    data-bs-dismiss="modal"><AiOutlineClose color="#F7941D" size="20"/>
                            </button>
                            <div className="modal-body">
                                <div className="text-center">
                                    <LazyLoadImage src={fileJson["VLC"].image} alt="VLC logo" className="text-center" height="50px"/>
                                    <p className="tooltip-title mt-2">{fileJson["VLC"].title}</p>
                                    <hr className="mt-1 mb-1"
                                        style={{
                                            height: "3px",
                                            width: "100%",
                                            background: "#F7941D",
                                            opacity: "1",
                                            borderRadius: "3px"
                                        }}/>
                                    <p className="tooltip-description mt-3">{fileJson["VLC"].description}</p>
                                    {fileJson["VLC"].ButtonUrl !== "" ?
                                        <a href={fileJson["VLC"].ButtonUrl} target="_blank">
                                            <button className="btn btn-tooltip mt-3 mb-3"><Translation i18n={i18n}>
                                                {
                                                    (t, {i18n}) => <>{t("Visit website")}</> // will be looked up from namespace ns1
                                                }
                                            </Translation></button>
                                        </a> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="modal animate__animated animate__lightSpeedInLeft" id="VSSDiV" tabIndex="-1" aria-labelledby="VSSDiVLabel"
                     aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div data-aos="flip-left"
                             data-aos-duration="2000" className="modal-content">
                            <button type="button" className="text-end btn"
                                    data-bs-dismiss="modal"><AiOutlineClose color="#F7941D" size="20"/>
                            </button>
                            <div className="modal-body">
                                <div className="text-center">
                                    <LazyLoadImage src={fileJson["VSS"].image} alt="VSS logo" className="text-center" height="50px"/>
                                    <p className="tooltip-title mt-2">{fileJson["VSS"].title}</p>
                                    <hr className="mt-1 mb-1"
                                        style={{
                                            height: "3px",
                                            width: "100%",
                                            background: "#F7941D",
                                            opacity: "1",
                                            borderRadius: "3px"
                                        }}/>
                                    <p className="tooltip-description mt-3">{fileJson["VSS"].description}</p>
                                    {fileJson["VSS"].ButtonUrl !== "" ?
                                        <a href={fileJson["VSS"].ButtonUrl} target="_blank">
                                            <button className="btn btn-tooltip mt-3 mb-3"><Translation i18n={i18n}>
                                                {
                                                    (t, {i18n}) => <>{t("Visit website")}</> // will be looked up from namespace ns1
                                                }
                                            </Translation></button>
                                        </a> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="modal animate__animated animate__lightSpeedInLeft" id="VOSDiV" tabIndex="-1" aria-labelledby="VOSDiVLabel"
                     aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div data-aos="flip-left"
                             data-aos-duration="2000" className="modal-content">
                            <button type="button" className="text-end btn"
                                    data-bs-dismiss="modal"><AiOutlineClose color="#F7941D" size="20"/>
                            </button>
                            <div className="modal-body">
                                <div className="text-center">
                                    <LazyLoadImage src={fileJson["VOS"].image} alt="VOS logo" className="text-center" height="50px"/>
                                    <p className="tooltip-title mt-2">{fileJson["VOS"].title}</p>
                                    <hr className="mt-1 mb-1"
                                        style={{
                                            height: "3px",
                                            width: "100%",
                                            background: "#F7941D",
                                            opacity: "1",
                                            borderRadius: "3px"
                                        }}/>
                                    <p className="tooltip-description mt-3">{fileJson["VOS"].description}</p>
                                    {fileJson["VOS"].ButtonUrl !== "" ?
                                        <a href={fileJson["VOS"].ButtonUrl} target="_blank">
                                            <button className="btn btn-tooltip mt-3 mb-3"><Translation i18n={i18n}>
                                                {
                                                    (t, {i18n}) => <>{t("Visit website")}</> // will be looked up from namespace ns1
                                                }
                                            </Translation></button>
                                        </a> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="modal animate__animated animate__lightSpeedInLeft" id="VUSDiV" tabIndex="-1" aria-labelledby="VUSDiVLabel"
                     aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div data-aos="flip-left"
                             data-aos-duration="2000" className="modal-content">
                            <button type="button" className="text-end btn"
                                    data-bs-dismiss="modal"><AiOutlineClose color="#F7941D" size="20"/>
                            </button>
                            <div className="modal-body">
                                <div className="text-center">
                                    <LazyLoadImage src={fileJson["VUS"].image} alt="VUS logo" className="text-center" height="50px"/>
                                    <p className="tooltip-title mt-2">{fileJson["VUS"].title}</p>
                                    <hr className="mt-1 mb-1"
                                        style={{
                                            height: "3px",
                                            width: "100%",
                                            background: "#F7941D",
                                            opacity: "1",
                                            borderRadius: "3px"
                                        }}/>
                                    <p className="tooltip-description mt-3">{fileJson["VUS"].description}</p>
                                    {fileJson["VUS"].ButtonUrl !== "" ?
                                        <a href={fileJson["VUS"].ButtonUrl} target="_blank">
                                            <button className="btn btn-tooltip mt-3 mb-3"><Translation i18n={i18n}>
                                                {
                                                    (t, {i18n}) => <>{t("Visit website")}</> // will be looked up from namespace ns1
                                                }
                                            </Translation></button>
                                        </a> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="modal animate__animated animate__lightSpeedInLeft" id="VOLDiV" tabIndex="-1" aria-labelledby="VOLDiVLabel"
                     aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div data-aos="flip-left"
                             data-aos-duration="2000" className="modal-content">
                            <button type="button" className="text-end btn"
                                    data-bs-dismiss="modal"><AiOutlineClose color="#F7941D" size="20"/>
                            </button>
                            <div className="modal-body">
                                <div className="text-center">
                                    <LazyLoadImage src={fileJson["VOL"].image} alt="VOL logo" className="text-center" height="50px"/>
                                    <p className="tooltip-title mt-2">{fileJson["VOL"].title}</p>
                                    <hr className="mt-1 mb-1"
                                        style={{
                                            height: "3px",
                                            width: "100%",
                                            background: "#F7941D",
                                            opacity: "1",
                                            borderRadius: "3px"
                                        }}/>
                                    <p className="tooltip-description mt-3">{fileJson["VOL"].description}</p>
                                    {fileJson["VOL"].ButtonUrl !== "" ?
                                        <a href={fileJson["VOL"].ButtonUrl} target="_blank">
                                            <button className="btn btn-tooltip mt-3 mb-3"><Translation i18n={i18n}>
                                                {
                                                    (t, {i18n}) => <>{t("Visit website")}</> // will be looked up from namespace ns1
                                                }
                                            </Translation></button>
                                        </a> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="modal animate__animated animate__lightSpeedInLeft" id="VTSDiV" tabIndex="-1" aria-labelledby="VTSDiVLabel"
                     aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div data-aos="flip-left"
                             data-aos-duration="2000" className="modal-content">
                            <button type="button" className="text-end btn"
                                    data-bs-dismiss="modal"><AiOutlineClose color="#F7941D" size="20"/>
                            </button>
                            <div className="modal-body">
                                <div className="text-center">
                                    <LazyLoadImage src={fileJson["VTS"].image} alt="VTS logo" className="text-center" height="50px"/>
                                    <p className="tooltip-title mt-2">{fileJson["VTS"].title}</p>
                                    <hr className="mt-1 mb-1"
                                        style={{
                                            height: "3px",
                                            width: "100%",
                                            background: "#F7941D",
                                            opacity: "1",
                                            borderRadius: "3px"
                                        }}/>
                                    <p className="tooltip-description mt-3">{fileJson["VTS"].description}</p>
                                    {fileJson["VTS"].ButtonUrl !== "" ?
                                        <a href={fileJson["VTS"].ButtonUrl} target="_blank">
                                            <button className="btn btn-tooltip mt-3 mb-3"><Translation i18n={i18n}>
                                                {
                                                    (t, {i18n}) => <>{t("Visit website")}</> // will be looked up from namespace ns1
                                                }
                                            </Translation></button>
                                        </a> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        );
    }
}

export default withTranslation()(PopupsEcoSystem);
