import React, {Component} from "react";
import {withTranslation} from 'react-i18next';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {Link} from "react-router-dom";
import RightArrow from "../Images/Layer 2.png"
import LeftArrow from "../Images/Layer22.png"
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ButtonGroup = ({next, previous, goToSlide, ...rest}) => {
    const {carouselState: {currentSlide}} = rest;
    return (
        <div className="carousel-button-group">
            <div className="nextButton" onClick={() => previous()}><LazyLoadImage className="arrowImage" src={LeftArrow} alt="left-arrow"/></div>
            <div className={currentSlide === 0 ? 'disable backButton' : 'backButton'} onClick={() => next()}><LazyLoadImage
                src={RightArrow} className="arrowImage" alt="right-arrow"/></div>
        </div>
    );
};
const responsive = {
    desktop: {
        breakpoint: {max: 3000, min: 1100},
        items: 4,
        paritialVisibilityGutter: 60
    },
    tablet: {
        breakpoint: {max: 1100, min: 464},
        items: 2,
        paritialVisibilityGutter: 50
    },
    mobile: {
        breakpoint: {max: 464, min: 0},
        items: 1,
        paritialVisibilityGutter: 30
    }
};
const responsive2 = {
    desktop: {
        breakpoint: {max: 3000, min: 1100},
        items: 3,
        paritialVisibilityGutter: 60
    },
    tablet: {
        breakpoint: {max: 1100, min: 464},
        items: 2,
        paritialVisibilityGutter: 50
    },
    mobile: {
        breakpoint: {max: 464, min: 0},
        items: 1,
        paritialVisibilityGutter: 30
    }
};

class AllBlogs extends Component {
    state = {
        topBlogs: this.props.topBlogs,
        popularBlogs: this.props.popularBlogs,
    };



    render() {
        let {t} = this.props
        let {topBlogs, popularBlogs} = this.state

        let singleTop= topBlogs.map(res=>{
            return (
                <>
                    <Link to={"/SingleBlog/"+res.title.replace(/ /g,'-')+"/"+res.name} key={res.name}>
                        <div className="card2">
                            <div>
                                <LazyLoadImage className="cardimg" src={res.thumbnail_image} alt={res.thumbnail_image_alt}/>
                                <div className="mt-3">
                                    {/*<h3 className="categoryBlog">{res.category_name}</h3>*/}
                                    <p className="categoryBlogTitle">{res.title}</p>
                                    <p className="categoryBlogDescription">
                                        {res.short_description}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Link>
                </>
            )
        })
        let singlePopular= popularBlogs.map(res=>{
            return (
            <>
                <Link to={"/SingleBlog/"+res.blog_title.replace(/ /g,'-')+"/"+res.blog_id} key={res.blog_id}>
                    <div className="card2">
                        <div>
                            <LazyLoadImage className="cardimg" src={res.popular_image} alt={res.popular_image_alt}/>
                            <div className="mt-3">
                                {/*<h3 className="categoryBlog">{res.category_name}</h3>*/}
                                <p className="categoryBlogTitle">{res.blog_title}</p>

                            </div>
                        </div>
                    </div>
                </Link>
            </>
            )
        })

        return (
            <>
                <div id="Blogs" className="container-fluid noPadding">
                    <div id="BlogsSection">
                        <div className="container">
                            <div className="row">
                                <div className="about-blog">
                                    <span className="sectionBlog">{t("Variiance’s Blog Section")}</span>
                                    <h6 className="sectionTop mt-4">{t("Top New Topics")}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid noPadding background-color-Blogs">
                        <div className="container">
                            <div className="row mt-2 position-relative">
                                <h6 className="newPosts my-3">{t("Top New Posts")}</h6>
                                <Carousel
                                    autoPlay={false}
                                    infinite={false}
                                    // arrows={false}
                                    renderButtonGroupOutside={true} customButtonGroup={<ButtonGroup/>}
                                    itemClass="image-item"
                                    // removeArrowOnDeviceType={["tablet", "mobile"]}
                                    deviceType={this.props.deviceType}
                                    responsive={responsive}
                                >
                                    {singleTop}
                                </Carousel>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid noPadding background-color-Blogs-two">
                        <div className="container">
                            <div className="row position-relative">
                                <h6 className="newPostsTwo mt-5 mb-4">{t("Popular Posts")}</h6>
                                <Carousel
                                    autoPlay={false}
                                    infinite={false}
                                    // arrows={false}
                                    renderButtonGroupOutside={true} customButtonGroup={<ButtonGroup/>}
                                    itemClass="image-item"
                                    // removeArrowOnDeviceType={["tablet", "mobile"]}
                                    deviceType={this.props.deviceType}
                                    responsive={responsive2}
                                >
                                    {singlePopular}
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </div>


            </>
        );
    }
}

export default withTranslation()(AllBlogs);
