import "./App.css";

import './Components/i18n/i18n';
import Cookies from "universal-cookie";
import "antd/dist/antd.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import React from "react";
import keycloak from "./keycloak";
import {ReactKeycloakProvider} from '@react-keycloak/web'
import AppRouter from "./Router";
import "react-awesome-button/dist/styles.css";
import {Helmet} from "react-helmet"
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css"



// const cookies = new Cookies();
// let dir = cookies.get("direction")
//     ? cookies.get("direction")
//     : "rtl";
let siteLanguage = 'en'
// if (dir === "ltr") {
//     import('./GeneralCss/all.css')
//     siteLanguage = 'en'
// } else {
//     import('./GeneralCss/all.rtl.css')
//     siteLanguage = 'ar'
// }
AOS.init();

function App() {


    return (
        <>
        {/*<Helmet htmlAttributes={{*/}
        {/*    lang: siteLanguage,*/}
        {/*}}>*/}
        {/*    <title lang={siteLanguage}> </title>*/}
        {/*</ Helmet>*/}
        <ReactKeycloakProvider authClient={keycloak}>
            <div className="App">
                <ToastContainer/>
                <AppRouter/>
            </div>
        </ReactKeycloakProvider>
        </>

    );
}

export default App;


