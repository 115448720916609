import React, {Component} from "react";
import {withTranslation} from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import faqImage from '../Images/Questions-cuate.svg'

class FAQSECTION extends Component {
    state = {
        allData: this.props.FAQ,
        hidePillars:false
    };

    hidePillarsIcon = () =>{
        this.setState({hidePillars:true})
}
componentDidMount() {
}

    render() {
        let {t} = this.props

        let FAQ = this.state.allData.map((res,index)=>{
            return(
                <div data-aos="flip-left"
                     data-aos-duration="2000" className="accordion-item" key={res.name}>
                    <h2 className="accordion-header" id={"headingOne"+index+1}>
                        <button
                            className={index+1 === 1 ? "accordion-button" :"accordion-button collapsed"}
                            type="button" data-bs-toggle="collapse"
                                data-bs-target={"#collapseOne"+index+1} aria-expanded="true"
                                aria-controls={"collapseOne"+index+1}>
                            <div className="py-1">
                                <div className=" d-md-flex flex-row justify-content-start">

                                    <span className="number-question me-2"> Q{index+1} </span>
                                    <span
                                        className="questionAccordion "> {res.question} </span>
                                    <div>

                                    </div>
                                </div>
                            </div>
                        </button>
                    </h2>
                    <div id={"collapseOne"+index+1}
                         className={index+1 === 1 ? "accordion-collapse collapse show" :"accordion-collapse collapse"}
                         aria-labelledby={"headingOne"+index+1} data-bs-parent="#accordionExample">
                        <div className="accordion-body answer" dangerouslySetInnerHTML={{ __html: res.answer}}>
                        </div>
                    </div>
                </div>
            )
        })
        return (
            <>

                <div id="FAQ" className="container">
                    <div className="row mt-5">
                        <div className="col-md-12">
                            <p className="text-center faqTitle">{t('Frequently Asked Questions')}</p>
                            <p className="text-center faqDescription">{t('Seek for what you want and set your question if you want')}</p>
                        </div>

                    </div>

                    </div>
                <div className="container-fluid" style={{backgroundColor:"#F9F9F9"}}>
                    <div className="row">
                        <div className="col-md-2">
                        </div>
                        <div className="col-md-6">
                            <div className="accordion" id="accordionExample">
                                {FAQ}

                            </div>
                    </div>
                        <div className="col-md-3">
                            <LazyLoadImage alt="FAQ"  src={faqImage} width="100%" className="mt-5"/>
                        </div>

                    </div>
                </div>

            </>
        );
    }
}

export default withTranslation()(FAQSECTION);
