import React, {Component} from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import {Spin} from "antd";
import MetaTags from "react-meta-tags";
import {Helmet} from "react-helmet";
import CareersSec from "../Components/CareersSection";

const cookies = new Cookies();

class Events extends Component {
    state = {
        careersData: [],
        depsData: [],
        isLoading: true,
        metaItemsName: [],
        metaItemsProperty: [],
        metatittle: '',
        lang:""

    };

    async componentDidMount() {
        window.scroll(0, 0)

        let lang = "";
        if (cookies.get("direction") && cookies.get("direction") === "ltr" ) {
            lang = "en";
            this.setState({lang: "en"});

        } else {
            this.setState({lang: "ar"});
            lang = "ar";
        }
        axios.get(process.env.REACT_APP_API_URL , { params: {
                "url": "api/resource/VTSMetaTags/Careers_VARIIANCE_"+ lang
            }
        }).then((response) => {
            let metaItemsName = response.data.data.meta_tags.filter((res) => {
                if(res.type ==="name"){
                    return  res
                }
            })
            let metaItemsProperty = response.data.data.meta_tags.filter((res) => {
                if(res.type ==="property"){
                    return res
                }
            })
            this.setState({metaItemsName,metaItemsProperty})
            let metatittle = response.data.data.page_title;
            this.setState({metatittle: metatittle})

        });

        await axios.get(process.env.REACT_APP_API_URL, {
            params: {
                "url": "/api/resource/Careers%20Page/CarrerPage-" + lang
            }
        }).then((response) => {
            let careersData = response.data.data;
            this.setState({careersData, isLoading: false});
        });
        await axios.get(process.env.REACT_APP_API_URL, {
            params: {
                "url": "/api/method/variiance.variiance_web.doctype.blogs.api.v2.variiance_web_blogs.get_careers_departments?language=" + lang
            }
        }).then((response) => {
            let depsData = response.data.message['Careers Departments'];
            this.setState({depsData, isLoading: false});
        });

    }


    render() {
        let singleMetaName= this.state.metaItemsName.map(res=>{
            return <meta key={res.key} name={res.key} content={res.value}/>
        })
        let singleMetaProperty= this.state.metaItemsProperty.map(res=>{
            return <meta key={res.key} property={res.key} content={res.value}/>
        })
        let {careersData, depsData} = this.state
        let rendered = this.state.isLoading ? (
            <div className="container my-5">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="example">
                            <Spin size="large" style={{margin: "auto"}}/>
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <>
                <CareersSec careersData={careersData} depsData={depsData}/>
            </>
        );
        return <div>
            {rendered}
            <Helmet>
                <link href={`https://variiance.com/${this.state.lang}/careers`} rel="canonical"/>
            </Helmet>
            <MetaTags>
                <title>{this.state.metatittle}</title>
                {singleMetaName}
                {singleMetaProperty}
            </MetaTags>
        </div>
    }


}

export default Events;
