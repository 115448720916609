import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import { LazyLoadImage } from 'react-lazy-load-image-component';

class EcoSystem extends Component {
    state = {
        Eco: this.props.Eco,
    };

    async componentDidMount() {

    }


    render() {
        let {t} = this.props
        let {Eco} = this.state;
        let EcoSystemActive = Eco.ecosystemservicesvariiance.map(res => {
            if(res.active === 1){
                return (
                    <div key={res.name} className="col-lg-3 cardpadd ">
                        <a href={res.url}>
                            <div className="cardactive">
                                <h4 className="cardTittle">{res.title}</h4>
                                <p className="cardDesc"> {res.description}</p>
                                <div className="d-flex flex-row justify-content-between align-items-center">
                                    <a href={res.url}><button className="joinbtnVisit btn cardLink">{t('View Website')}</button></a>
                                    <LazyLoadImage alt={res.icon_alt}  src={res.icon} height={55}/>
                                </div>
                            </div>
                        </a>
                    </div>
                )
            }else {
                return null
            }

        })

        let EcoSystemNotActive = Eco.ecosystemservicesvariiance.map(res => {
            if(res.active === 0){
                return (
                    <div  key={res.name} className="col-lg-3 cardpadd" style={{cursor:"default"}}>
                        <div className="cardNotactive">
                            <h4 className="cardTittleNot">{res.title}</h4>
                            <p className="cardDesc"> {res.description}</p>
                            <div className="d-flex flex-row justify-content-between align-items-end">
                                <p className="cardLink"></p>
                                <p className="comingsoon">{t('coming Soon !')}</p>
                            </div>
                        </div>
                    </div>
                )
            }else {
                return null
            }

        })

        return (
            <>
                <div className="container-fluid">
                    <div className="row">
                        {EcoSystemActive}
                    </div>

                    <div className="row ">
                        {EcoSystemNotActive}
                    </div>
                </div>

            </>
        )
    }


}

export default withTranslation()(EcoSystem);
