import React, {Component} from "react";
import {withTranslation} from 'react-i18next';

import calendar from '../Images/Layer 2.svg'
import {Link, withRouter} from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';

class EventSec extends Component {
    state = {
        pageId: 0,
    };


    handlePage = (pageId) => {
        this.setState({pageId})
    }

    render() {
        let {t, eventsData} = this.props

        let tabs = eventsData.map((event, i) => (

                <div
                    key={event.name}
                    className={"col-lg-4 col-md-6"}
                    onClick={() => this.handlePage(i,event.title)}>
                    <Link to={"/singleEvent/"+event.title.replace(/ /g,'-')+"/"+event.name}>
                        <div className="px-xl-5 px-lg-2 px-md-2 px-2 py-2 ">
                    <div className='event__header__tabs-container-child--header' style={{
                        backgroundImage: `url(${event.event_image})`
                    }}>

                        <div className='event__header__tabs-container-child--header--logo'>
                            <LazyLoadImage src={event.logo} alt='event-logo' width='100%'/>
                        </div>
                    </div>
                    <div className='event__header__tabs-container-child--body'>
                        <div className='d-flex justify-content-between flex-md-row flex-column mx-3 pt-3'>
                            <div className='event__header__tabs-container-child--body--name'>
                                <h1>{event.title}</h1>
                            </div>
                            <div className='event__header__tabs-container-child--body--number'>
                                <h2>0{i + 1}</h2>
                            </div>
                        </div>
                        <div
                            className='d-flex flex-md-row flex-column event__header__tabs-container-child--body--date mx-3'>
                            <LazyLoadImage src={calendar} alt='event-calendar' className='d-none d-md-block'/>
                            <p>{event.published_date}</p>
                        </div>
                    </div>
                        </div>
                </Link>
                </div>



        ))
        return (
            <>
                <header className="container-fluid p-0">
                    <div className="event__header">
                        <div className='container'>
                            <h1 className='mb-3'>{t('Variiance Events')}</h1>
                            <p>{t('Event Section some little words here')}</p>
                        </div>
                    </div>
                </header>

                <div className="container-fluid">
                    <div className='row py-5 justify-content-center'>
                    {tabs}
                    </div>

                </div>
            </>
        )
    }
}

export default withTranslation()(withRouter(EventSec));
