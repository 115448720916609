import React, {Component} from "react";
import "react-multi-carousel/lib/styles.css";
import {Spin} from "antd";
import Cookies from "universal-cookie";
import {LazyLoadImage} from 'react-lazy-load-image-component';
import axios from "axios";
import keycloak from "../keycloak";
import {toast} from "react-toastify";
import moment from "moment";
import MetaTags from "react-meta-tags";

import {Link, Redirect, withRouter} from "react-router-dom";
import { AiFillFacebook, AiFillLike, AiFillLinkedin, AiFillTwitterSquare, AiOutlineEye, AiOutlineLike} from "react-icons/ai";
import { RiShareForwardLine } from "react-icons/ri";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { FaTelegramPlane } from "react-icons/fa";
import {Card, CardBody, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Spinner} from "reactstrap"
import {
    FacebookShareButton,
    LinkedinShareButton,
    TelegramShareButton,
    TwitterShareButton,
} from "react-share";
import {Translation, withTranslation} from "react-i18next";
import {getUser} from "../Router";
import i18n from "../Components/i18n/i18n";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import {Button, Popover} from "react-bootstrap";
import {Helmet} from "react-helmet";
import avatar1 from "../Images/256-512.png";
import {history} from "../history";

const cookies = new Cookies();


class SingleBlog extends Component {
    state = {
        allBlogData: {},
        MoreBlogs: [],
        Comments: [],
        errors: {},
        isLoading: true,
        updating: true,
        ShowReply: false,
        ID: "",
        isLiked: false,
        StudentID: "",
        comment: "",
        reply: "",
        Subscribe: "",
        disabled: "disabled",
        disabledReply: "disabled",
        disabledSubscribe: "disabled",
        posting: false,
        postingReply: false,
        postingSubscribe: false,
        NumberOfLikes: null,
        url: "",
        modal: false,
        noBlog: false,
        lang: "",
        redirectTo404: false

    };
    toggleModal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }))

    }

    async componentDidMount() {
        window.scroll(0, 0)
        this.setState({url: this.props.match.url})
        if (keycloak.authenticated) {
            await getUser().then(resp => this.setState({StudentID: resp}))
        }

        let lang = "";
        if (cookies.get("direction") && cookies.get("direction") === "ltr") {
            this.setState({lang: "en"});
            lang = "en";
        } else {
            this.setState({lang: "ar"});
            lang = "ar";
        }
        this.GetBLog(lang)
        this.GetMoreBlogs(lang)

    }


    GetBLog = (lang) => {
        const newparams = {
            url: "/api/method/variiance.variiance_web.doctype.blogs.api.v2.variiance_web_blogs.blog",
            data: {
                blog_id: this.props.match.params.id,
                student_id: this.state.StudentID,
                language: lang
            }
        }
        axios.post(process.env.REACT_APP_API_URL, newparams)
            .then((response) => {
                // if(response.data.message.Blog.length === 0){
                //     this.setState({noBlog:true})
                //
                // }
                if (response.data.message.Blog.length === 0) {
                    this.setState({redirectTo404: true})
                }
                let allBlogData = response.data.message;
                let Comments = response.data.message.Comments
                let NumberOfLikes = response.data.message.Number_Of_Likes
                let isLiked = response.data.message.is_student_likes
                this.setState({allBlogData, Comments, NumberOfLikes, isLiked});
                if (allBlogData.Blog.title) {
                    history.replace(`/SingleBlog/${allBlogData.Blog.title.replace(/ /g, '-')}/${allBlogData.Blog.parent_blog}`)
                }
                this.setState({
                    isLoading: false,
                });
            });
    }

    GetMoreBlogs = (lang) => {
        axios.get(process.env.REACT_APP_API_URL, {
            params: {
                "url": "/api/method/variiance.variiance_web.doctype.blogs.api.v2.variiance_web_blogs.more_blogs?blog_id=" + this.props.match.params.id + "&language=" + lang
            }
        })
            .then((response) => {
                let MoreBlogs = response.data.message;
                this.setState({MoreBlogs});

            });
    }

    NotAuthError = () => toast.error(("You should Login to Add Like or Comment "), {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    ShowReplyInput = (id) => {
        // When toggles the same reply field.
        if (this.state.prevReplyOpened === id) {
            this.setState({[id]: false, prevReplyOpened: ''})
            this.setState({reply: ''})  // Remove unuseful reply.
        }
        // When another reply field is openned.
        else if (this.state.prevReplyOpened) {
            this.setState({[this.state.prevReplyOpened]: false, [id]: true, prevReplyOpened: id})
            this.setState({reply: ''})  // Remove unuseful reply.
        }
        // For first time only.
        else {
            this.setState({[id]: true, prevReplyOpened: id})
            this.setState({reply: ''})  // Remove unuseful reply.
        }
    }

    handleChangeSubscribe = (event) => {
        if (event.target.value !== "") {
            this.setState({disabledSubscribe: false});
        } else {
            this.setState({disabledSubscribe: "disabled"});
        }
        this.setState({[event.target.name]: event.target.value});
    };

    _handleKeyDownSubscribe = (e) => {
        if (e.key === "Enter") {
            this.SubmitSubscribe()
        }
    };

    handleValidation = () => {
        let errors = {};
        let formIsValid = true;

        if (!this.state.Subscribe) {
            formIsValid = false;

            errors.Subscribe = <Translation i18n={i18n}>
                {
                    (t, {i18n}) => <p>{t("Cannot be empty")}</p> // will be looked up from namespace ns1
                }
            </Translation>
        } else if (typeof this.state.Subscribe !== "undefined") {
            if (
                !this.state.Subscribe.match(
                    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                )
            ) {
                formIsValid = false;
                errors.Subscribe = <Translation i18n={i18n}>
                    {
                        (t, {i18n}) => <p>{t("Please enter valid mail")}</p> // will be looked up from namespace ns1
                    }
                </Translation>
            }
        }
        this.setState({errors: errors})
        return formIsValid;

    }

    SubmitSubscribe = () => {
        if (this.handleValidation() === false) {
        } else {
            this.setState({disabledSubscribe: "disabled"});
            this.setState({postingSubscribe: true});
            const newparams = {
                url: "api/resource/Subscriptions",
                data:
                    {
                        email: this.state.Subscribe
                    }
            }
            axios.post(process.env.REACT_APP_API_URL, newparams)
                .then((response) => {
                    let id = response.data.data.name;
                    if (id !== "" || id !== undefined) {
                        this.setState({
                            postingSubscribe: false,
                            Subscribe: "",
                        })
                    }
                });
        }
    }

    SubmitLike = () => {
        if (keycloak.authenticated) {
            const newparams = {
                url: "/api/resource/Blog Likes",
                data: {
                    student: this.state.StudentID,
                    blog_id: this.props.match.params.id,
                }
            }
            axios.post(process.env.REACT_APP_API_URL, newparams)
                .then((response) => {
                    let id = response.data.data.name;
                    if (id !== "" || id !== undefined) {
                        this.setState({
                            isLiked: true
                        })
                        this.GetBLog(this.state.lang)
                    }
                });
        } else {
            this.toggleModal()
        }
    }

    handleChange = (event) => {
        if (event.target.value !== "") {
            this.setState({disabled: false});
        } else {
            this.setState({disabled: "disabled"});
        }
        this.setState({[event.target.name]: event.target.value});
    };

    _handleKeyDown = (e) => {
        if (e.key === "Enter") {
            this.SubmitComment();
        }
    };

    SubmitComment = () => {
        if (keycloak.authenticated) {
            this.setState({disabled: "disabled"});
            this.setState({posting: true});
            const newparams = {
                url: "/api/resource/Blog Comments",
                data: {
                    comment_parent: "",
                    text: this.state.comment,
                    student: this.state.StudentID,
                    blog_id: this.props.match.params.id,
                }
            }

            axios.post(process.env.REACT_APP_API_URL, newparams)
                .then((response) => {
                    let id = response.data.data.name;
                    if (id !== "" || id !== undefined) {
                        this.setState({
                            posting: false,
                            comment: "",
                            ShowReply: false
                        })
                        this.GetBLog(this.state.lang)
                    }
                });
        } else {
            this.toggleModal()
        }
    };

    handleChangeReply = (event) => {
        if (event.target.value !== "") {
            this.setState({disabledReply: false});
        } else {
            this.setState({disabledReply: "disabled"});
        }
        this.setState({[event.target.name]: event.target.value});
    };

    _handleKeyDownReply = (e, parent) => {
        if (e.key === "Enter") {
            this.SubmitReply(parent);
        }
    };

    SubmitReply = (parent) => {
        if (keycloak.authenticated) {
            this.setState({disabledReply: "disabled"});
            this.setState({postingReply: true});
            const newparams = {
                url: "/api/resource/Blog Comments",
                data: {
                    comment_parent: parent,
                    text: this.state.reply,
                    student: this.state.StudentID,
                    blog_id: this.props.match.params.id,
                }
            }

            axios.post(process.env.REACT_APP_API_URL, newparams)
                .then((response) => {
                    let id = response.data.data.name;
                    if (id !== "" || id !== undefined) {
                        this.setState({
                            postingReply: false,
                            reply: "",
                            ShowReply: false
                        })
                        this.GetBLog(this.state.lang)
                    }
                });
        } else {
            this.toggleModal()
        }
    };

    render() {

        let {t} = this.props
        const popover = (
            <Popover id="popover-basic">
                <Popover.Header as="h3">{t('Share to ?')}</Popover.Header>
                <Popover.Body>
            <span className="mx-1"><TwitterShareButton url={window.location.href}
                                                       children={<AiFillTwitterSquare size={33}
                                                                                      color="#EE851D"/>}/> </span>
                    <span className="mx-1 "><FacebookShareButton url={window.location.href}
                                                                 children={<AiFillFacebook size={33}
                                                                                           color="#EE851D"/>}/> </span>
                    <span className="mx-1"><LinkedinShareButton url={window.location.href}
                                                                children={<AiFillLinkedin size={33} color="#EE851D"/>}/></span>
                    <span className="mx-1"> <TelegramShareButton url={window.location.href}
                                                                 children={<FaTelegramPlane size={33}
                                                                                            color="#EE851D"/>}/></span>
                </Popover.Body>
            </Popover>
        );
        let {allBlogData, MoreBlogs, Comments, NumberOfLikes, isLiked} = this.state
        let SingleMoreBlog = MoreBlogs.map(res => {
                return (
                    <>

                        <div className="col-md-4" key={res.Blog_Name}>
                            <Link to={"/x_SingleBlog/" + res.blog_title.replace(/ /g, '-') + "/" + res.Blog_Name} replace>
                                <div className="py-2 px-1">
                                    <LazyLoadImage src={res.popular_image} className="MostBlogImg"
                                                   alt={res.popular_image_alt}/>
                                    {/*<p className="MostBlogCat mt-2  mb-0">{res.category_name}</p>*/}
                                    <h3 className="MostBlogTxt">{res.blog_title}</h3>
                                </div>
                            </Link>
                        </div>

                    </>
                )
            }
        )

        let SingleComment = Comments.map((res, i) => {
                let SingleReply = res.replies.map(resp => {
                        return (
                            <>
                                <div key={resp.comment_id}>
                                    <div
                                        className="ReplySec d-flex flex-row justify-content-start align-items-start">
                                        <LazyLoadImage style={{borderRadius: "50%"}} className="mx-3 ReplyIMG"
                                                       src={resp.image ? resp.image : avatar1}
                                                       alt={resp.image_alt}/>
                                        <div>
                                            <p className="ReplyUser mb-0">{resp.first_name + " " + resp.last_name}</p>
                                            <p className="MostBlogCat mb-1">{moment(resp.creation).format('DD / MM / YYYY')}</p>
                                            <p className="ReplyTXT">{resp.text}</p>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    }
                )

                return (
                    <>
                        <div className="CommentSec pt-5 px-1" key={res.comment_id}>
                            <div>
                                <div
                                    className=" ms-5 d-flex flex-row justify-content-start align-items-start">
                                    <LazyLoadImage className="mx-3 CommentIMG" style={{borderRadius: "50%"}}
                                                   src={res.image ? res.image : avatar1}
                                                   alt={res.image_alt}/>
                                    <div>
                                        <p className="commentUser mb-0">{res.first_name + " " + res.last_name}</p>
                                        <p className="MostBlogCat mb-1">{moment(res.creation).format('DD / MM / YYYY')}</p>
                                        <p className="commentTXT">{res.text}</p>
                                        <p onClick={() => this.ShowReplyInput(res.comment_id)}
                                           className="commentReply">{t("Reply")}</p>

                                        {this.state[res.comment_id] &&
                                            <div className=" BlogForm">
                                                <div
                                                    className="input-group commentDiv my-3 d-lg-flex flex--column flex-row justify-content-start align-items-baseline">
                                                    <input type="text" className="form-control CommentInput"
                                                           placeholder={t("Write your Reply here")}
                                                           aria-label="text"
                                                           name="reply"
                                                           value={this.state.reply}
                                                           onChange={this.handleChangeReply}
                                                           onKeyDown={(e) => this._handleKeyDownReply(e, res.comment_id)}
                                                           aria-describedby="addon-wrapping"/>
                                                    <button className="blogButton btn mx-lg-3 "
                                                            disabled={this.state.disabledReply}
                                                            onClick={() => this.SubmitReply(res.comment_id)}>
                                                        {this.state.postingReply ? (
                                                            <span>{t("posting")}</span>
                                                        ) : (
                                                            <span className="plus">{t("Comment")}</span>
                                                        )}</button>
                                                </div>

                                            </div>}
                                    </div>
                                </div>
                                <h3 className=" mb-0 pb-2 TotalReply">{res.replies.length} Replies</h3>
                                {SingleReply}
                            </div>
                        </div>
                    </>
                )
            }
        )
        let rendered = this.state.isLoading ? (
            <>
                <div className="container my-5">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="example">
                                <Spin size="large" style={{margin: "auto"}}/>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        ) : (
            <>

                <div className="container-fluid">
                    <div className="row">
                        <div className="BlogDIV">
                            <div className="singleBlogBG">
                                <h1 className="singleBlogHead">{allBlogData.Blog.title}</h1>
                                <div className="my-3 SingleBlogImg" style={{
                                    backgroundImage: `url(${allBlogData.Blog.cover_image})`
                                }}>
                                    <Link to="/Blogs"><IoIosArrowDropleftCircle className="SingleBlogArrow" size={30}
                                                                                color="#FFF"/></Link>
                                </div>
                            </div>
                            <div className="d-lg-flex d-md-block flex-row justify-content-between align-items-center">
                                <div className="d-flex flex-row justify-content-start align-items-center">
                                    <LazyLoadImage className="mx-3" style={{borderRadius: "50%"}}
                                                   src={allBlogData.Blog.writer_photo ?? avatar1}
                                                   alt={allBlogData.Blog.writer_photo_alt}
                                                   width={80} height={80}/>
                                    <div>
                                        <p className="singleBlogTxt mb-0">{t("Written By")}</p>
                                        <h3 className="singleBlogTxtOrange">{allBlogData.Blog.writer}</h3>
                                    </div>
                                </div>

                                <div
                                    className="d-lg-flex d-md-block flex-row justify-content-start align-items-baseline">
                                    <div className="mx-3 my-2">
                                        {!isLiked ?
                                            <span className="mx-1" onClick={this.SubmitLike}> <AiOutlineLike size={33}
                                                                                                             color="#EE851D"
                                                                                                             style={{cursor: "pointer"}}/></span> :
                                            <span className="mx-1"> <AiFillLike style={{cursor: "pointer"}} size={33}
                                                                                color="#EE851D"/></span>}
                                        <span
                                            className="singleBlogTxt p-0 mt-1">{NumberOfLikes} {t("Like")}</span>
                                    </div>

                                    <div className="mx-3 my-2">
                                        <span className="mx-1"> <AiOutlineEye size={33} color="#EE851D"/></span>
                                        <span
                                            className="singleBlogTxt p-0 mt-1"> {allBlogData.Number_Of_Views} {t("Views")}</span>
                                    </div>

                                    <div className="mx-3 my-2">
                                        <OverlayTrigger trigger="click" placement="top" overlay={popover}>
                                            <button className="mx-1 buttonShare p-0"><RiShareForwardLine
                                                size={33} color="#EE851D"/> <span
                                                className="singleBlogTxt p-0 mt-1 mx-1">{t("Share")}</span></button>
                                        </OverlayTrigger>
                                    </div>


                                    {/*<div className="mx-3 my-2">*/}
                                    {/*    <span className="mx-1"> <AiOutlineEdit size={33} color="#EE851D"/></span>*/}
                                    {/*    <span*/}
                                    {/*        className="singleBlogTxt p-0 mt-1">{t("Published")} {moment(allBlogData.Blog.blog_creation).format('DD / MM / YYYY--h:mm A')} </span>*/}
                                    {/*</div>*/}
                                </div>

                            </div>


                            <div className="mt-5" dangerouslySetInnerHTML={{__html: allBlogData.Blog.content}}/>


                            <div className="row my-5">
                                <div className="col-lg-4 my-2">
                                    <div>
                                        <p className="singleBlogTxt">{t("Share This Blog")}</p>
                                        <div className="d-flex flex-row justify-content-start align-items-baseline">
                                            <span className="mx-2"><TwitterShareButton url={window.location.href}
                                                                                       children={<AiFillTwitterSquare
                                                                                           size={33}
                                                                                           color="#EE851D"/>}/> </span>
                                            <span className="mx-2 "><FacebookShareButton url={window.location.href}
                                                                                         children={<AiFillFacebook
                                                                                             size={33}
                                                                                             color="#EE851D"/>}/> </span>
                                            <span className="mx-2"><LinkedinShareButton url={window.location.href}
                                                                                        children={<AiFillLinkedin
                                                                                            size={33}
                                                                                            color="#EE851D"/>}/></span>
                                            <span className="mx-2"> <TelegramShareButton url={window.location.href}
                                                                                         children={<FaTelegramPlane
                                                                                             size={32}
                                                                                             color="#EE851D"/>}/></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                </div>
                                <div className="col-lg-4 my-2">
                                    <div className="subscribeDev">
                                        <p className="subscribeTXT m-0 ">{t("SUBSCRIBE TO OUR NEWS FEED")}</p>
                                    </div>
                                    <div className=" BlogForm">
                                        <div className="input-group my-3">
                                            <input type="email" className="form-control"
                                                   placeholder={t("Write your email here")}
                                                   aria-label="email"
                                                   name="Subscribe"
                                                   value={this.state.Subscribe}
                                                   onChange={this.handleChangeSubscribe}
                                                   onKeyDown={this._handleKeyDownSubscribe}
                                                   aria-describedby="addon-wrapping"/>
                                        </div>
                                        <span className="error">{this.state.errors.Subscribe}</span>
                                        <button className="blogButton btn "
                                                disabled={this.state.disabledSubscribe}
                                                onClick={() => this.SubmitSubscribe()}>
                                            {this.state.postingSubscribe ? (
                                                <span>{t("posting")}</span>
                                            ) : (
                                                <span className="plus">{t("Subscribe")}</span>
                                            )}</button>
                                    </div>
                                </div>
                            </div>


                            <div className="row my-5">
                                {SingleMoreBlog.length !== 0 ?
                                    <h3 className="singleBlogTxtOrange">{t("Read More")}</h3> : null}
                                {SingleMoreBlog}
                            </div>

                            <div className="row my-5">
                                <div className="col-12">
                                    {Comments.length > 0 ?
                                        SingleComment :
                                        <div className="CommentSec py-5 px-1 text-center">
                                            <h2>{t("There are no comments")}</h2>
                                        </div>
                                    }
                                    <div className=" BlogForm">
                                        <div
                                            className="input-group commentDiv my-3 d-lg-flex flex--column flex-row justify-content-start align-items-baseline">
                                            <input type="text" className="form-control CommentInput"
                                                   placeholder={t("Write your comment here")}
                                                   aria-label="text"
                                                   name="comment"
                                                   value={this.state.comment}
                                                   onChange={this.handleChange}
                                                   onKeyDown={this._handleKeyDown}
                                                   aria-describedby="addon-wrapping"/>
                                            <button className="blogButton btn mx-lg-3 "
                                                    disabled={this.state.disabled}
                                                    onClick={() => this.SubmitComment()}>
                                                {this.state.posting ? (
                                                    <span>{t("Posting")}</span>
                                                ) : (
                                                    <span className="plus">{t("Comment")}</span>
                                                )}</button>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Helmet>
                    <link href={"https://variiance.com" + this.state.url} rel="canonical"/>
                </Helmet>
                <MetaTags>
                    <title>{allBlogData.Blog.meta_title}</title>
                    <meta name="description" content={allBlogData.Blog.meta_description}/>
                    <meta content="IE=edge" httpEquiv="X-UA-Compatible"/>
                    <meta property="og:type" content="website"/>
                    <meta property="og:url" content={"https://variiance.com" + this.state.url}/>
                    <meta property="og:description" content={allBlogData.Blog.meta_description}/>
                    <meta property="og:title" content={allBlogData.Blog.meta_title}/>
                    <meta property="og:image" content={allBlogData.Blog.cover_image}/>
                    <meta property="og:site_name" content="Variiance"/>
                    <meta property="og:image:alt"
                          content={allBlogData.Blog.cover_image_alt ? allBlogData.Blog.cover_image_alt : ""}/>
                    <meta content={allBlogData.Blog.meta_title} name="twitter:title"/>
                    <meta content={allBlogData.Blog.meta_description} name="twitter:description"/>
                    <meta content={allBlogData.Blog.cover_image} name="twitter:image"/>
                    <meta content="summary" name="twitter:card"/>
                    <meta name="twitter:url" content={"https://variiance.com" + this.state.url}/>
                    <meta content="@Variianceglobal" name="twitter:site"/>
                </MetaTags>
            </>
        )
        return <div>


            {!this.state.redirectTo404 ? rendered : <Redirect to={"/NotFoundPage"}/>}
            <Modal
                isOpen={this.state.modal}
                toggle={this.toggleModal}
                className="modal-dialog-centered"
            >
                <ModalHeader toggle={this.toggleModal}>
                    <p className="loginModal">
                        {t("You must to login or Sign Up")}
                    </p>
                </ModalHeader>
                <ModalBody>
                    <button type="button" onClick={() =>
                        keycloak && !keycloak.authenticated
                            ? keycloak.register()
                            : keycloak.logout()
                    }
                            className="loginButtonModal mx-1">{t('Create Account')}</button>


                    <button type="button" onClick={() =>
                        keycloak && !keycloak.authenticated
                            ? keycloak.login()
                            : keycloak.logout()
                    }
                            className="loginButtonModal">{t('Login')}</button>
                </ModalBody>
            </Modal>

        </div>
    }
}

export default withRouter(withTranslation()(SingleBlog));




