import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import {
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Form,
    Button
} from "reactstrap"
import {AvForm} from "availity-reactstrap-validation"
import axios from "axios";

class VuexyWizard extends React.Component {
    static getDerivedStateFromProps(props, state) {
        if (props.activeStep) {
            if (props.activeStep !== state.activeStep) {
                if (props.validate) {
                    if (state.errors.length === 0 && state.submitted)
                        return {activeStep: props.activeStep}
                } else return {activeStep: props.activeStep}
            }
        }
        return null
    }

    state = {
        activeStep: this.props.activeStep ? this.props.activeStep :0,
        errors: [],
        values: [],
        disableButton: false,

    }


    handleNextStep = (index, total, errors = []) => {
        if (this.props.valuesFirstObject.email_address === "" || this.props.valuesFirstObject.upload_cv === "") {
            if (this.props.valuesFirstObject.upload_cv === "") {
                document.querySelector('#cv-required').style.display = "block";
            }
        } else if (index === 1 && (this.props.valuesFirstObject.gender === "" || this.props.valuesFirstObject.military_status === "" || this.props.valuesFirstObject.social_status === "")) {
            if (this.props.valuesFirstObject.gender === "") {
                document.querySelector('#gender-required').style.display = "block";
            }
            if (this.props.valuesFirstObject.military_status === "") {
                document.querySelector('#military-required').style.display = "block";
            }
            if (this.props.valuesFirstObject.social_status === "") {
                document.querySelector('#social-required').style.display = "block";
            }
        } else if (index ===2 && (this.props.anotherValues.school === "" ||this.props.anotherValues.degree === "" || this.props.anotherValues.field_of_study === "" ||this.props.anotherValues.graduation_year === "" )) {
            // document.querySelector('#notice-required').style.display = "block";
            if (this.props.anotherValues.school === "") {
                document.querySelector('#school-required').style.display = "block";
            }
            if (this.props.anotherValues.degree === "") {
                document.querySelector('#degree-required').style.display = "block";
            }
            if (this.props.anotherValues.field_of_study === "") {
                document.querySelector('#field_of_study-required').style.display = "block";
            }
            if (this.props.anotherValues.graduation_year === "") {
                document.querySelector('#graduation_year-required').style.display = "block";
            }
        }
        else if (index === 6 && (this.props.valuesFirstObject.notice_period === "")) {
            document.querySelector('#notice-required').style.display = "block";
        }else {

            let activeStep = this.state.activeStep
            let validation = this.props.validate

            if (!validation) {
                if (activeStep <= index && activeStep !== total) {
                    this.setState({
                        activeStep: activeStep + 1
                    }, () => this.props.getTitle(this.props.steps[index + 1].title))
                }
            } else {
                if (errors.length === 0 && activeStep <= index && activeStep !== total) {
                    this.setState({
                        activeStep: activeStep + 1
                    }, () => this.props.getTitle(this.props.steps[index + 1].title))
                } else if (errors.length && this.props.onValidationError) {
                    this.props.onValidationError(this.state.errors)
                } else {
                    return
                }

            }
        }
    }

    handlePreviousStep = index => {
        let activeStep = this.state.activeStep
        if (activeStep >= index && activeStep !== 0) {
            this.setState({
                activeStep: activeStep - 1
            }, () => this.props.getTitle(this.props.steps[index - 1].title))
        }
    }


    handleSubmit = e => {
        if (
            this.props.steps.length - 1 === this.state.activeStep &&
            this.props.onFinish
        ) {

            this.props.onFinish(e)
        }
    }
    submitForm = () => {
        if(this.props.valuesFirstObject.signature === ""){

        }else{
            this.setState({disableButton: true})
            const newparams = {
                url: "api/resource/Career Applications",
                data: this.props.valuesFirstObject,
            };
            axios.post(process.env.REACT_APP_API_URL, newparams)
                .then((response) => {
                    if (response.status) {
                        this.props.sendFinish(true)
                    }else{
                        this.setState({disableButton: false})
                    }

                }).catch((error=>{
                this.setState({disableButton: false})
            }))
        }

    }

    render() {
        let FormTag = this.props.validate ? AvForm : Form

        return (
            <React.Fragment>
                <Nav
                    className={`vx-wizard NavCustomStyle mt-5 ${
                        this.props.className ? this.props.className : ""
                    }`}
                    tabs>
                    {this.props.steps.map((item, i) => {
                        return (
                            <NavItem
                                className="step-wrapper"
                                key={i}>
                                <NavLink
                                    className={classnames(`  step step-${i}`, {
                                        active: this.state.activeStep === i ? true : false,
                                        doneImage: i < this.state.activeStep
                                    })}>
                                    <span className="step-text">{item.image}</span>
                                </NavLink>
                            </NavItem>
                        )
                    })}
                </Nav>
                <Nav
                    className={`vx-wizard NavCustomStyle strike ${
                        this.props.className ? this.props.className : ""
                    }`}
                    tabs>
                    {this.props.steps.map((item, i) => {
                        return (
                            <NavItem
                                className="step-wrapper"
                                key={i}>
                                <NavLink
                                    className={classnames(` step step-${i}`, {
                                        active: this.state.activeStep === i ? true : false,
                                        doneBullets: i < this.state.activeStep
                                    })}>
                                    <div className="bulletSteps"></div>
                                </NavLink>
                            </NavItem>
                        )
                    })}
                </Nav>

                <Nav
                    className={`vx-wizard NavCustomStyle  ${
                        this.props.className ? this.props.className : ""
                    }`}
                    tabs>
                    {this.props.steps.map((item, i) => {
                        return (
                            <NavLink
                                className={classnames(`widthStepTittle step step-${i}`, {
                                    active: this.state.activeStep === i ? true : false,
                                    doneBullets: i < this.state.activeStep
                                })}>
                                <span className="step-tittle">{item.title}</span>
                            </NavLink>

                        )
                    })}
                </Nav>


                <TabContent
                    className={`vx-wizard-content ${
                        this.props.tabPaneClass ? this.props.tabPaneClass : ""
                    }`}
                    activeTab={this.state.activeStep}>
                    {this.props.steps.map((item, i) => {
                        return (
                            <TabPane
                                className={`step-content step-${i}-content`}
                                key={i}
                                tabId={i}>
                                <FormTag
                                    className="form-horizontal formCustom"
                                    onSubmit={(e, errors, values) => {
                                        this.setState({errors, values})
                                        if (!this.props.validate) e.preventDefault()
                                        this.handleNextStep(i, this.props.steps.length - 1, errors)
                                        this.handleSubmit(e)
                                    }}>
                                    {item.content}
                                    {this.props.pagination ? (
                                        <div className="wizard-actions d-flex justify-content-center mt-5">
                                            {this.state.activeStep !== 0 ?
                                                <div className="button-submit-container mx-auto my-3">

                                                    <div className="btn previousButtonSteps"
                                                         onClick={() => this.handlePreviousStep(i)}>
                                                        Back
                                                    </div>
                                                </div>
                                                : null}
                                            <div className="button-submit-container mx-auto my-3">
                                                {this.props.steps.length - 1 === i &&
                                                !this.props.finishBtnText
                                                    ? <button className="btn nextButtonSteps"
                                                           onClick={!this.state.disableButton ? this.submitForm : null}
                                                           type="submit">Apply</button> : this.props.steps.length - 1 === i &&
                                                    this.props.finishBtnText
                                                        ? this.props.finishBtnText :
                                                        <button className="btn nextButtonSteps"
                                                                type="submit">Next</button>}
                                                {/*<button className="btn nextButtonSteps"*/}
                                                {/*        type="submit"> {this.props.steps.length - 1 === i &&*/}
                                                {/*!this.props.finishBtnText*/}
                                                {/*    ? "Apply"*/}
                                                {/*    : this.props.steps.length - 1 === i &&*/}
                                                {/*    this.props.finishBtnText*/}
                                                {/*        ? this.props.finishBtnText*/}
                                                {/*        : "Next"}</button>*/}
                                            </div>
                                        </div>
                                    ) : null}
                                </FormTag>
                            </TabPane>
                        )
                    })}
                </TabContent>
            </React.Fragment>
        )
    }
}

VuexyWizard.propTypes = {
    className: PropTypes.string,
    steps: PropTypes.array.isRequired,
    validate: PropTypes.bool,
    enableAllSteps: PropTypes.bool,
    finishBtnText: PropTypes.string,
    onFinish: PropTypes.func,
    sendFinish: PropTypes.func,
    pagination: PropTypes.bool,
    onValidationError: PropTypes.func,
    activeStep: PropTypes.number,
    valuesFirstObject: PropTypes.object,
    anotherValues:PropTypes.object
}

VuexyWizard.defaultProps = {
    pagination: true
}

export default VuexyWizard
