import React, {Component} from "react";
import {Translation, withTranslation} from 'react-i18next';
import 'react-multi-carousel/lib/styles.css';
import Pagination from "react-js-pagination";
import {MdTimer} from "react-icons/md";
import {GoLocation} from "react-icons/go";
import {RiCalendarTodoLine} from "react-icons/ri";
import Select from "react-select";
import logo from "../Images/Group 110.svg"
import "moment/locale/ar";
import "moment/locale/en-au";
import {AwesomeButton} from "react-awesome-button";
import {Link} from "react-router-dom";
import axios from "axios";
import * as moment from "moment";
import Moment from "react-moment";
import { LazyLoadImage } from 'react-lazy-load-image-component';

import Cookies from "universal-cookie";
import {Spin} from "antd";
import ImageNotFound from '../Images/notfound.png'
import ContactSec from "./contactUsSections.js";
import i18n from "./i18n/i18n.js";
import {Helmet} from "react-helmet";
import MetaTags from "react-meta-tags";

const Countries = [{
    value: "Abukbir", label: "Abukbir"
}, {
    value: "Embabh", label: "Embabh"
}, {
    value: "Egypt", label: "Egypt"
}]

const Cities = [{
    value: "Abukbir", label: "Abukbir"
}, {
    value: "Embabh", label: "Embabh"
}, {
    value: "Egypt", label: "Egypt"
}]

const Departments = [{
    value: "Dev", label: "Dev"
}, {
    value: "Development", label: "Development"
}, {
    value: "Tech", label: "Tech"
}]
const customStyles = {
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? "rgba(220,116,15,0.89)" : 'unset',
        zIndex: 9999,
        "&:hover": {
            color: "white",
            backgroundColor: "rgba(220,116,15,0.89)"
        }
    }),
    menu: (provided, state) => ({
        ...provided,
        zIndex: 9999,
    }),
}
const cookies = new Cookies();

class CareerJobsPageSections extends Component {
    state = {
        isLoading: true,
        activePage: 1,
        total: 0,
        jobs: [],
        countries: [],
        Cities: [],
        departments: [],
        country: "",
        city:"",
        department:"",
        totalCounts:0,
        metaItemsName: [],
        metaItemsProperty: [],
        metatittle: '',
        lang:""
    }

    getJobCountries = () => {
        axios.get(process.env.REACT_APP_API_URL, {
            params: {
                'url': `/api/resource/Job Countries`,
            }
        }).then((response) => {
            let countries = response.data.data
            this.setState({countries})
        })
    }

    getJobCities = () => {
        axios.get(process.env.REACT_APP_API_URL, {
            params: {
                'url': `/api/resource/Job Cities?fields=["name","country"]`,
            }
        }).then((response) => {
            let Cities = response.data.data
            this.setState({Cities})
        })
    }

    getJobDepartments = (lang) => {

        axios.get(process.env.REACT_APP_API_URL, {
            params: {
                "url": "/api/method/variiance.variiance_web.doctype.blogs.api.v2.variiance_web_blogs.get_careers_departments?language=en"
            }
        }).then((response) => {
            let departments = response.data.message['Careers Departments']
            this.setState({departments})
        })
    }

    getJobs = async () => {
        let lang = "";
        if (cookies.get("direction") && cookies.get("direction") === "ltr") {
            lang = "en";
            moment.locale('en-au');
        } else {
            lang = "ar";
            moment.locale('ar');
        }
        await axios.get(process.env.REACT_APP_API_URL, {
            params: {
                'url': `/api/method/variiance.variiance_web.doctype.blogs.api.v2.variiance_web_blogs.get_jobs?page=1`,
            }
        }).then((response) => {
            if (response.data.message.Jobs_count !== 0) {
                let jobs = response.data.message.jobs;
                let total = response.data.message.Jobs_count
                let totalCounts =response.data.message.Total_Jobs
                this.setState({jobs, total,totalCounts, isLoading: false});
            } else {
                this.setState({jobs: [], isLoading: false});
            }
        });
    }

    componentDidMount() {
        let lang = "";
        if (cookies.get("direction") && cookies.get("direction") === "ltr" ) {
            lang = "en";
            this.setState({lang: "en"});

        } else {
            this.setState({lang: "ar"});
            lang = "ar";
        }
        axios.get(process.env.REACT_APP_API_URL , { params: {
                "url": "api/resource/VTSMetaTags/Jobs_VARIIANCE_"+ lang
            }
        }).then((response) => {
            let metaItemsName = response.data.data.meta_tags.filter((res) => {
                if(res.type ==="name"){
                    return  res
                }
            })
            let metaItemsProperty = response.data.data.meta_tags.filter((res) => {
                if(res.type ==="property"){
                    return res
                }
            })
            this.setState({metaItemsName,metaItemsProperty})
            let metatittle = response.data.data.page_title;
            this.setState({metatittle: metatittle})
        });
        this.getJobCountries()
        this.getJobCities()
        this.getJobDepartments(lang)
        this.getJobs()
    }
    selectRef = null;


    handleSelect = (e, name) => {
        this.setState({[name]: e.value ? e.value : ""}, () => {
            if(name === "country"){
                this.setState({city:""})
                axios.get(process.env.REACT_APP_API_URL, {
                    params: {
                        'url': `/api/resource/Job Cities?fields=["name","country"]&filters=[["country","=","${e.value}"]]`,
                    }
                }).then((response) => {
                    let Cities = response.data.data
                    this.setState({Cities})
                })
            }
        });
    };

    filterJobs = ()=>{
        this.setState({isLoading:true,activePage:1})
        let filter = null
        // if(this.state.country !== "" && this.state.city !== "" && this.state.department !== ""){
        //     filter =
        // }else if(this.state.department === ""){
        //     filter = "&country="+this.state.country+"&city="+this.state.city
        // }else if(this.state.city === "")
        axios.get(process.env.REACT_APP_API_URL, {
            params: {
                'url': `/api/method/variiance.variiance_web.doctype.blogs.api.v2.variiance_web_blogs.get_jobs?page=1${"&country="+encodeURIComponent(this.state.country)+"&city="+encodeURIComponent(this.state.city)+"&department="+encodeURIComponent(this.state.department)}`,
            }
        }).then((response) => {
            if (response.data.message.Jobs_count !== 0) {
                let jobs = response.data.message.jobs;
                let total = response.data.message.Jobs_count
                let totalCounts =response.data.message.Total_Jobs
                this.setState({jobs, total,totalCounts, isLoading: false});
            } else {
                let total = response.data.message.Jobs_count
                let totalCounts =response.data.message.Total_Jobs
                this.setState({jobs: [], total,totalCounts,isLoading: false});
            }
        });
    }


    handlePageChange(pageNumber) {
        if(this.state.activePage !== pageNumber){
            this.setState({
                isLoading: true,
            });
            this.setState({activePage: pageNumber});
            axios.get(process.env.REACT_APP_API_URL, {
                params: {
                    // "url": "api/resource/Events-" + lang,
                    'url': `/api/method/variiance.variiance_web.doctype.blogs.api.v2.variiance_web_blogs.get_jobs?page=${pageNumber}${"&country="+encodeURIComponent(this.state.country)+"&city="+encodeURIComponent(this.state.city)+"&department="+encodeURIComponent(this.state.department)}`,

                }
            })
                .then((response) => {
                    let jobs = response.data.message.jobs;
                    this.setState({jobs, isLoading: false});

                });
        }

    }

    render() {
        let {t} = this.props

        let singleMetaName= this.state.metaItemsName.map(res=>{
            return <meta key={res.key} name={res.key} content={res.value}/>
        })
        let singleMetaProperty= this.state.metaItemsProperty.map(res=>{
            return <meta key={res.key} property={res.key} content={res.value}/>
        })
        let singleJob = this.state.jobs.map(res => {
            return (

                <div className="singleJobCard my-4" key={res.name}>
                    <div className="row">
                        <div className="col-lg-3 cardImageSize">
                            {/*<LazyLoadImage src={logo} className="imageSize"/>*/}
                        </div>
                        <div className="col-lg-9">
                            <div>
                                <Link to={"/SingleJobInfo/"+res.title.replace(/ /g,'-')+"/"+res.name}>
                                <p className="titleJob">
                                    {res.title}
                                </p>
                                </Link>
                                <p className="descriptionJob" dangerouslySetInnerHTML={{__html: res.description}}>
                                </p>
                            </div>
                            <div
                                className="d-lg-flex d-mdd-lg-flex d-md-block flex-row justify-content-start align-items-baseline">
                                <div className="me-xl-3 me-xl-2 my-2">
                                    <span className="me-1"> <MdTimer size={25} color="#EE851D"/></span>
                                    <span className="singleJobText p-0 mt-1">{res.shift_type}</span>
                                </div>

                                <div className="me-xl-3 me-xl-2 my-2">
                                    <span className="me-1"> <GoLocation size={25} color="#EE851D"/></span>
                                    <span className="singleJobText p-0 mt-1">{res.country_id}, {res.city_id}</span>
                                </div>

                                <div className="me-xl-3 me-xl-2 my-2">
                                    <span className="me-1"> <RiCalendarTodoLine size={25} color="#EE851D"/></span>
                                    <span
                                        className="singleJobText p-0 mt-1">Posted  {(moment().diff(moment(res.creation), 'days')) !== 0 ? moment().diff(moment(res.creation), 'days') + " days ago": "Today"}</span>
                                </div>
                            </div>
                            <div className="mt-4 buttonDiv">
                                <Link to={"/SingleJobInfo/"+res.title.replace(/ /g,'-')+"/"+res.name}>
                                    <AwesomeButton type="secondary"
                                                   className="apply"
                                    >Apply now</AwesomeButton>
                                </Link>
                            </div>
                        </div>
                    </div>

                </div>
            )
        })
        let singleCountry = this.state.countries.map(res => {
            return {value: res.name, label: res.name}
        })

        let singleCity = this.state.Cities.map(res => {
            return {value: res.name, label: res.name}
        })

        let singleDepartment = this.state.departments.map(res => {
            return {value: res.name, label: res.title}
        })
        let rendered = this.state.isLoading ? (
            <div className="container my-5">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="example">
                            <Spin size="large" style={{margin: "auto"}}/>
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <>
                <div id="careerJobs" className="container-fluid noPadding"  style={{direction: 'ltr'}}>
                    <div id="careerJobsPage">
                        <div className="container">
                            <div className="row">
                                <div className="about-jobs">
                                    <span className="sectionJobs">Come work with us</span>
                                </div>
                            </div>
                        </div>
                    </div>

                        <div className="container mt-4">
                            <div className="row">
                                <div className="col-lg-3">
                                    <div className="filterCard text-center">
                                        <div className="pt-3">
                                            <p className="availableJobs mb-0">Available jobs</p>
                                            <p className="jobsResults">Showing {this.state.total} of {this.state.totalCounts} results</p>

                                            <form onSubmit={(e) => e.preventDefault()} className="row px-2">
                                                <p className="availableJobs mb-0">Filter by Country</p>
                                                <div className="input-group flex-nowrap mt-2">
                                                    <Select
                                                        className="form-control selectFilter"
                                                        classNamePrefix="select"
                                                        placeholder="All Countries"
                                                        styles={customStyles}
                                                        value={singleCountry.find(
                                                            (obj) => obj.value === this.state.country
                                                        )}
                                                        name="country"
                                                        options={singleCountry}
                                                        // onChange={this.handleChangeGeneralInputs}
                                                        onChange={(e) => this.handleSelect(e, 'country')}
                                                    />
                                                </div>
                                                <p className="availableJobs mb-0 mt-4">Filter by City</p>
                                                <div className="input-group flex-nowrap mt-2">
                                                    <Select
                                                        className="form-control selectFilter"
                                                        classNamePrefix="select"
                                                        placeholder="All cities"
                                                        value={singleCity.find(
                                                            (obj) => obj.value === this.state.city
                                                        )|| ""}
                                                        name="city"
                                                        styles={customStyles}
                                                        options={singleCity}
                                                        onChange={(e) => this.handleSelect(e, 'city')}
                                                    />
                                                </div>
                                                <p className="availableJobs mb-0 mt-4">Filter by Department</p>
                                                <div className="input-group flex-nowrap mt-2 mb-2">
                                                    <Select
                                                        className="form-control selectFilter"
                                                        classNamePrefix="select"
                                                        placeholder="All Departments"
                                                        value={singleDepartment.find(
                                                            (obj) => obj.value === this.state.department
                                                        )|| ""}
                                                        name="department"
                                                        styles={customStyles}
                                                        options={[{value: null, label:"All Departments"}].concat(singleDepartment)}
                                                        isClearable={false}
                                                        onChange={(e) => this.handleSelect(e, 'department')}
                                                    />
                                                </div>
                                                <div className="mt-4">
                                                    <AwesomeButton type="secondary" onPress={this.filterJobs}
                                                    >Search</AwesomeButton>
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                                {this.state.jobs.length > 0 ?
                                <div className="col-lg-9">
                                    <div className="jobsCard">

                                        {singleJob}
                                        <div className='row justify-content-center'>
                                            <Pagination
                                                innerClass='pagination paginationCustom'
                                                activePage={this.state.activePage}
                                                itemsCountPerPage={6}
                                                itemClass="page-item"
                                                prevPageText="PREVIOUS"
                                                linkClassPrev="previousArrow"
                                                hideFirstLastPages={true}
                                                nextPageText="NEXT"
                                                linkClassNext="nextArrow"
                                                linkClass="page-link pageItemLink"
                                                totalItemsCount={this.state.total}
                                                pageRangeDisplayed={5}
                                                onChange={this.handlePageChange.bind(this)}
                                            />
                                        </div>
                                    </div>

                                </div>
                                    : <>
                                                <div className="col-lg-9 m-auto text-center">
                                                    <LazyLoadImage className="picnot" src={ImageNotFound} alt="not found"/>
                                                    <h1 className="not_found my-4">No Jobs Found</h1>
                                                </div>
                                    </>}
                            </div>

                        </div>


                </div>
            </>
        );
        return <div>
            {rendered}
            <Helmet>
                <link href={`https://variiance.com/${this.state.lang}/jobsPage`} rel="canonical"/>
            </Helmet>
            <MetaTags>
                <title>{this.state.metatittle}</title>
                {singleMetaName}
                {singleMetaProperty}
            </MetaTags>
        </div>
    }
}

export default withTranslation()(CareerJobsPageSections);
