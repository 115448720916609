import React, {Component} from "react";
import {withTranslation} from 'react-i18next';
import LogoHomePage from '../Images/LogoHomePage.svg'
import {HashLink} from "react-router-hash-link";
import SVGG from "../Images/2.png";
import Cookies from "universal-cookie";
import Veco from "../Images/VECOTOOLTIP.png"
import PopUpS from "./PopupsEcoSystem";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const cookies = new Cookies();
let fileJson = null
let dir = cookies.get("direction")
    ? cookies.get("direction")
    : "rtl";
if (dir === "ltr") {
    fileJson = require("./ECOSYSTEM.json")
} else {
    fileJson = require("./ECOSYSTEMARABIC.json")

}

class HomeSections extends Component {

    state = {
        allData: this.props.Home,
        hidePillars: false,
        hide500: false
    };

    hidePillarsIcon = () => {
        this.setState({hidePillars: true})
    }

    hide500Form = () => {
        this.setState({hide500: true})
    }

    render() {
        let {t} = this.props

        let singleNext = this.state.allData.next_integrated_ecosystem.map(res => {
            return (
                <div className="mt-4" key={res.name}>
                    <span className="span-number">0{res.idx}</span>
                    <span className="span-text mt-5">{res.title}</span>
                </div>
            )
        })
        return (
            <>

                <div className="container-fluid">
                    <div className="row">
                        <div id="Home" className="col-lg-6">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12 padding-card-first">
                                        <LazyLoadImage alt="logo variiance home page" src={LogoHomePage} className="imageLogoVariance"/>
                                        <p className="Infinity mt-4">{this.state.allData.title}</p>
                                        <p className="Infinity-down mt-3">{this.state.allData.subtitle}</p>
                                        <hr className="mt-3 mb-2 m-auto"
                                            style={{
                                                height: "1px",
                                                width: "99%",
                                                backgroundColor: "#EE851D",
                                                opacity: "1"
                                            }}/>
                                        <p className="Infinity-down-two mt-3">{this.state.allData.description}</p>
                                        <HashLink to="/AboutUS">
                                            <button
                                                className="btn btn-primary mt-2 KnowMore mb-5"
                                            >
                                                {t('Know More...')}
                                            </button>
                                        </HashLink>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className="col-lg-6 noPadding">
                            <LazyLoadImage alt={this.state.allData.image_alt} src={this.state.allData.image} width="100%" height="100%"/>
                        </div>
                    </div>
                </div>

                {/*{keycloak && !keycloak.authenticated ?*/}
                {/*    <div*/}
                {/*        className=" animate__animated animate__lightSpeedInLeft modalsec2 modal-dialog modal-dialog-2 modal-dialog-centered "*/}
                {/*        style={{display: this.state.hide500 ? 'none' : null}}*/}
                {/*    >*/}
                {/*        <div className="modal-content modal-content-2">*/}
                {/*            <button type="button" className="text-end btn"*/}
                {/*            ><AiOutlineCloseCircle onClick={() => this.hide500Form()} color="#fff" size="35"/>*/}
                {/*            </button>*/}
                {/*            <div className="modal-body">*/}
                {/*                <div className="text-center">*/}
                {/*                    <LazyLoadImage*/}
                {/*                        src="http://vcloud.variiance.com/link/g5CnqcUZyM6uICG5XFq1fLvlqi8Piwo0LhQU9yhb.png"*/}
                {/*                        alt="500 logo"*/}
                {/*                        className="text-center popupImg"/>*/}
                {/*                    <div className="my-5">*/}
                {/*                        <p className="popupTxt">{t('popuptxt')}</p>*/}
                {/*                        <p className="popupTxt">{t('or')}</p>*/}
                {/*                        <p className="popupTxt mb-3">{t('Video conference packages on VLC')}</p>*/}
                {/*                       <a href={process.env.REACT_APP_500} target="_blank"> <button*/}
                {/*                                className="popupButton"><span*/}
                {/*                            className="popupButtonTxt">{t('popupButton')}</span></button></a>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>:null}*/}


                {/*<div*/}
                {/*    className="modalsec "*/}
                {/*     style={{display:this.state.hidePillars ? 'none' : null}}*/}
                {/*>*/}
                {/*    <div className="my-2 text-center">*/}
                {/*       <a href={process.env.REACT_APP_PILLARS} target="_blank"> <LazyLoadImage  alt="img" src={cookies.get("direction") === "ltr"?  Pillars:PillarsArabic} className="mx-1"/></a>*/}
                {/*        <LazyLoadImage alt="img" onClick={()=>this.hidePillarsIcon()}  src={hidePillars} className="btn-x" />*/}
                {/*        /!*<p className="modalText">{t('Ready to Invest?')}</p>*!/*/}
                {/*    </div>*/}
                {/*</div>*/}


                <div id="HomeSecond">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <p className="header-eco section-margin-top">{this.state.allData.next_title} <span
                                    className="spanNile">{t('NILE')}</span></p>
                                <p className="Infinity-down-two"
                                   style={{width: "80%"}}>{this.state.allData.next_subtitle}</p>
                                <hr className="mt-3 mb-2"
                                    style={{height: "1px", width: "60%", backgroundColor: "#EE851D", opacity: "1"}}/>
                                {singleNext}
                                <HashLink to="/AboutUS">
                                    <button
                                        className="btn btn-primary mt-5 KnowMore"
                                    >
                                        {t('Know More...')}
                                    </button>
                                </HashLink>

                            </div>
                            <div className="col-lg-6">
                                <LazyLoadImage alt={this.state.allData.next_image_alt} src={this.state.allData.next_image} width="100%"
                                     className="imageLogoVariance"/>
                            </div>
                        </div>
                    </div>

                </div>
                <div id="HomeThird">
                    <div className="container-fluid p-0">
                        <div className="row justify-content-center align-items-center m-0">
                            <div className="col-lg-12 text-center mt-5">
                                <LazyLoadImage alt="eco-system" src={Veco} width={105} height={65} style={{maxWidth: "100%"}}/>
                                <h2 className="abouttooltip mt-3">{t('Variiance Ecosystem')}</h2>
                                <p className="paragraphTooltip mt-3">{t('Variiance Software')}</p>
                            </div>
                        </div>
                        <div className="row m-0">
                            <div className="col-md-6 m-auto">
                                <img src={SVGG}
                                          className="text-center m-auto w-100 py-5"/>
                            </div>
                        </div>

                        <PopUpS/>

                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 padding-card">

                            <p className="header-eco section-margin-top">{this.state.allData.assets_title}</p>
                            <p className="Infinity-down-two-black">{this.state.allData.assets_subtitle}</p>
                            <hr className="mt-3 mb-2"
                                style={{height: "1px", width: "93%", backgroundColor: "#EE851D", opacity: "1"}}/>
                            <p className="Infinity-down-two-black mt-3">{this.state.allData.assets_description}</p>
                            {/*<button*/}
                            {/*    className="btn btn-primary mt-5 KnowMore mb-5"*/}
                            {/*>*/}
                            {/*    {t('Join Us')}*/}
                            {/*</button>*/}
                        </div>
                        <div className="col-lg-6  noPadding">
                            <LazyLoadImage src={this.state.allData.assets_image} width="100%" alt={this.state.allData.assets_image_alt}
                                 className="imageLogoVariance"/>
                        </div>
                    </div>
                </div>

            </>
        );
    }
}

export default withTranslation()(HomeSections);
