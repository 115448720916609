import React, {Component} from "react";
import {withTranslation} from 'react-i18next';
import Cookies from "universal-cookie";
import {AwesomeButton} from "react-awesome-button";
import {AiFillFacebook, AiFillLinkedin, AiFillTwitterSquare, AiOutlineClockCircle} from "react-icons/ai";
import {BsFillPeopleFill} from "react-icons/bs";
import {FaTelegramPlane} from "react-icons/fa";
import {VscLocation} from "react-icons/vsc";
import {MdDateRange} from "react-icons/md";
import {IoLocationOutline} from "react-icons/io5";
import {Link, withRouter} from "react-router-dom";
import * as moment from "moment";
import {FacebookShareButton, LinkedinShareButton, TelegramShareButton, TwitterShareButton} from "react-share";

const cookies = new Cookies();

class SingleJobInfoSections extends Component {

    state = {
        singleJob: this.props.singleJob
    };


    render() {
        let {t} = this.props
        let {singleJob} = this.state

        return (
            <>


                <div id="JobSection">
                    <div className="container">
                        <div className="row">
                            <div className="about-blog">
                                <h2 className="SingleJobTxtBG">{this.props.singleJob.title}</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container" style={{direction: 'ltr'}}>
                    <div className="row">
                        <div className="col-12 text-center">
                            <p className="singleJobHead">We’re looking for the <span
                                className="singleJobSpan">Talents</span></p>
                        </div>
                        <div className=" col-lg-4">
                            <div
                                className="d-flex flex-column justify-content-start align-items-baseline">
                                <div className="my-2">
                                    <span className="mx-3"> <AiOutlineClockCircle size={33} color="#EE851D"/></span>
                                    <span className="singleJobTxt p-0 mt-1">{this.props.singleJob.shift_type}</span>
                                </div>

                                <div className="my-2">
                                    <span className="mx-3"> <VscLocation size={33} color="#EE851D"/></span>
                                    <span
                                        className="singleJobTxt p-0 mt-1">{this.props.singleJob.country_id}, {this.props.singleJob.city_id}</span>
                                </div>

                                <div className="my-2">
                                    <span className="mx-3"> <MdDateRange size={33} color="#EE851D"/></span>
                                    <span
                                        className="singleJobTxt p-0 mt-1">Posted {(moment().diff(moment(this.props.singleJob.creation), 'days')) !== 0 ? moment().diff(moment(this.props.singleJob.creation), 'days') + " days ago" : "Today"}</span>
                                </div>
                            </div>
                        </div>
                        <div className=" col-lg-4">
                        </div>
                        <div className=" col-lg-4">

                            <div className="mb-3">
                                <p className="singleJobTxt mb-0">Share With Friends</p>
                                <div className="d-flex flex-row justify-content-start align-items-baseline">
                                    <span className="mx-1"><TwitterShareButton url={window.location.href}
                                                                               children={<AiFillTwitterSquare size={33}
                                                                                                              color="#EE851D"/>}/> </span>
                                    <span className="mx-1 "><FacebookShareButton url={window.location.href}
                                                                                 children={<AiFillFacebook size={33}
                                                                                                           color="#EE851D"/>}/> </span>
                                    <span className="mx-1"><LinkedinShareButton url={window.location.href}
                                                                                children={<AiFillLinkedin size={33}
                                                                                                          color="#EE851D"/>}/></span>
                                    <span className="mx-1"> <TelegramShareButton url={window.location.href}
                                                                                 children={<FaTelegramPlane size={33}
                                                                                                            color="#EE851D"/>}/></span>
                                </div>
                            </div>

                            <div
                                className="d-flex flex-column justify-content-start align-items-baseline">
                                <div className="mt-2">
                                    <p className="singleJobTxt mb-0">Company Info</p>
                                </div>
                                <div className="my-2">
                                    <span className="mx-3"> <IoLocationOutline size={33} color="#EE851D"/></span>
                                    <span
                                        className="singleJobTxt p-0 mt-1">{this.props.singleJob.country_id}, {this.props.singleJob.city_id}</span>
                                </div>

                                <div className="my-2">
                                    <span className="mx-3"> <BsFillPeopleFill size={33} color="#EE851D"/></span>
                                    <span
                                        className="singleJobTxt p-0 mt-1">More Than {this.props.singleJob.employees_number_more_than} Employee</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <h3 className="singleJobHead my-3">Job Description</h3>
                            <p className="singleJobTxt mb-3"
                               dangerouslySetInnerHTML={{__html: this.props.singleJob.description}}></p>

                            <div className="text-center my-5">
                                <Link
                                    to={"/formApplying/" + this.props.match.params.title + "/" + this.props.singleJob.name}>
                                    <AwesomeButton type="secondary"
                                    >Apply now</AwesomeButton>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        );
    }
}

export default withTranslation()(withRouter(SingleJobInfoSections));
