import React, {Component} from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import {Spin} from "antd";
import MetaTags from "react-meta-tags";
import VastSections from "../Components/VastSections";
import {Helmet} from "react-helmet";

const cookies = new Cookies();

class Vast extends Component {
    state = {
        isLoading: true,
        vastData: [],
        metaItemsName:[],
        metaItemsProperty:[],
        metatittle:'',
        lang:""


    };

    async componentDidMount() {
        window.scroll(0, 0)

        let lang = "";
        if (cookies.get("direction") && cookies.get("direction") === "ltr") {
            this.setState({lang: "en"});

            lang = "en";
        } else {
            this.setState({lang: "ar"});

            lang = "ar";
        }


        await axios.get(process.env.REACT_APP_API_URL,{
            params: {
                "url": "api/resource/VASTVariiance/Vast-" + lang
            }
        })
            .then((response) => {
                let vastData = response.data.data;
                this.setState({vastData});
                this.setState({
                    isLoading: false,
                });
            });

        axios.get(process.env.REACT_APP_API_URL , {
            params: {
                "url": "api/resource/VTSMetaTags/ِVAST_VARIIANCE_"+ lang
            }
        }).then((response) => {
            let metaItemsName = response.data.data.meta_tags.filter((res) => {
                if(res.type ==="name"){
                    return  res
                }
            })
            let metaItemsProperty = response.data.data.meta_tags.filter((res) => {
                if(res.type ==="property"){
                    return res
                }
            })
            this.setState({metaItemsName,metaItemsProperty})
            let metatittle = response.data.data.page_title;
            this.setState({metatittle: metatittle})
            this.setState({
                isLoading: false,
            });

        });
    }


    render() {
        let singleMetaName= this.state.metaItemsName.map(res=>{
            return <meta key={res.key} name={res.key} content={res.value}/>
        })
        let singleMetaProperty= this.state.metaItemsProperty.map(res=>{
            return <meta key={res.key} property={res.key} content={res.value}/>
        })
        let rendered = this.state.isLoading ? (
            <div className="container my-5">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="example">
                            <Spin size="large" style={{margin: "auto"}}/>
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <>
                <VastSections vast={this.state.vastData}/>
            </>
        );
        return <div>
            {rendered}
            <Helmet>
                <link href={`https://variiance.com/${this.state.lang}/vast`} rel="canonical"/>
            </Helmet>
            <MetaTags>
                <title>{this.state.metatittle}</title>
                {singleMetaName}
                {singleMetaProperty}
            </MetaTags>
        </div>
    }

}

export default Vast;
