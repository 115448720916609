import React, {Component} from "react";
import AllFormSections from "../Components/AllFormSections.js";
class FormJobs extends Component {



    render() {

        return (
           <AllFormSections />
        )
    }


}

export default FormJobs;
