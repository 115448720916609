import React, {Component, createRef, Fragment} from "react";
import {withTranslation} from 'react-i18next';
import 'react-multi-carousel/lib/styles.css';
import Wizard from "./WizardComponent"
import {AvInput, AvGroup, AvFeedback, AvField,AvRadioGroup,AvRadio} from "availity-reactstrap-validation"
// import AvSelect from '@availity/reactstrap-validation-select';
// import '@availity/reactstrap-validation-select/styles.scss';
import ThanksImg from '../Images/Group 24334.svg'
import {AwesomeButton} from "react-awesome-button";
import {
    Label, Row, Col, Card, CardBody, CardTitle, CardHeader
} from "reactstrap"
import {Translation} from "react-i18next";
import i18n from "./i18n/i18n";
import step1 from "../Images/step1.png"
import Flatpickr from "react-flatpickr";
import step2 from "../Images/personal-information.png"
import step3 from "../Images/education.png"
import step4 from "../Images/skills (1).png"
import step5 from "../Images/work-history.png"
import step6 from "../Images/Reference.png"
import step7 from "../Images/addtional.png"
import step8 from "../Images/confirmation.png"
import {DatePicker, Progress, Slider, Spin} from "antd";
import Select from "react-select";
import {Link, withRouter} from "react-router-dom";
import axios from "axios";
import moment from "moment";
// Import the plugins
import Uppy from '@uppy/core'
import {DragDrop, StatusBar} from '@uppy/react'
import thumbnailGenerator from '@uppy/thumbnail-generator'
// And their styles (for UI plugins)
// With webpack and `style-loader`, you can import them like this:
import 'uppy/dist/uppy.css'
import '@uppy/status-bar/dist/style.css'
import {X} from "react-feather";
import {toast} from "react-toastify";
import Cookies from "universal-cookie";
import "moment/locale/en-au";
import {Helmet} from "react-helmet";
import MetaTags from "react-meta-tags";
import image from "../Images/Group24451.png"
import { LazyLoadImage } from 'react-lazy-load-image-component';

const cookies = new Cookies();

const Gender = [{
    value: "Male", label: "Male"
}, {
    value: "Female", label: "Female"
}]
const Military = [{
    value: "Not Applicable", label: "Not Applicable"
}, {
    value: "Exempted", label: "Exempted"
}, {
    value: "Completed", label: "Completed"
}, {
    value: "Postponed", label: "Postponed"
}]
const Social = [{
    value: "Unspecified", label: "Unspecified"
}, {
    value: "Single", label: "Single"
}, {
    value: "Married", label: "Married"
}]

const Notice = [{
    value: "None", label: "None"
},{
    value: "One Week", label: "One Week"
},
    {
    value: "Two Weeks", label: "Two Weeks"
}, {
    value: "Three Weeks", label: "Three Weeks"
}
    , {
        value: "One Month", label: "One Month"
    } , {
        value: "Two Months", label: "Two Months"
    }
    , {
        value: "Three Months", label: "Three Months"
    }]

const Degree = [{
    value: "Bachelor's Degree", label: "Bachelor's Degree"
}, {
    value: "Master's Degree", label: "Master's Degree"
}, {
    value: "Doctorate Degree", label: "Doctorate Degree"
}
    , {
        value: "High School", label: "High School"
    } , {
        value: "Vocational", label: "Vocational"
    }
    , {
        value: "Diploma", label: "Diploma"
    }]
const customStyles = {
    option: (provided, state) => ({
        ...provided, backgroundColor: state.isSelected ? "rgba(220,116,15,0.89)" : 'unset', zIndex: 9999, "&:hover": {
            color: "white", backgroundColor: "rgba(220,116,15,0.89)"
        }
    }), menu: (provided, state) => ({
        ...provided, zIndex: 9999,
    }),
}


const marks = {
    0: '', 1: '', 2: '', 3: '', 4: '', 5: '', 6: '', 7: '', 8: '', 9: '', 10: '',
};


class AllFormSections extends Component {
    constructor(props) {
        super(props)
        this.state = {
            steps: [],
            counter: 1,
            singleJob: [],
            email: '',
            portfolio: '',
            jobName: '',
            chooseDateTime: '',
            isLoading: true,
            previewArr: [],
            arrayLength: [],
            cvFile: "",
            cvTitle: "",
            photoFile: "",
            photoTitle: "",
            fullName: "",
            gender: "",
            address: "",
            mobile: "",
            date: "",
            nationality: "",
            nationalId: "",
            military: "",
            social: "",
            school: "",
            degree:"",
            field_of_study:"",
            graduation_year:"",
            arrayCount: [],
            arrayLengthEducation: 0,
            EducationValues:[],
            singleEducationObject:{"school":"","degree":"","field_of_study":"","graduation_year":""},
            workCount:[],
            WorkValues:[],
            singleWorkObject:{"company_name":"","job_title":"","start_date":"","end_date":"","responsibilities":""},
            english:0,
            arabic:0,
            microsoft:0,
            knowledgeSkills:"",
            currentEmployer:"",
            relatives:"",
            reference1:"",
            reference1JobTitle:"",
            reference1MobileNumber:"",
            reference2:"",
            reference2JobTitle:"",
            reference2MobileNumber:"",
            expectedSalary:"",
            idealCandidate:"",
            notice:"",
            willingToWork:"",
            smoker:"",
            medical:"",
            signature:"",
            values: [],
            hide:false,
            url:''


        }
        this.uppy = new Uppy({
            meta: {type: 'avatar'},
            autoProceed: true,
            restrictions: {maxNumberOfFiles: 1, allowedFileTypes: ['.pdf', '.docx']},
            allowMultipleUploads: true
        })
        this.uppy1 = new Uppy({
            meta: {type: 'avatar'}, autoProceed: true, restrictions: {
                maxNumberOfFiles: 1, allowedFileTypes: ['.png', '.jpeg', '.apng', '.avif', '.gif', '.jpg', '.svg', '.webp']
            }, allowMultipleUploads: true
        })
        this.uppy.use(thumbnailGenerator, {
            id: 'ThumbnailGenerator',
            thumbnailWidth: 200,
            thumbnailHeight: 200,
            thumbnailType: 'application/pdf',
            waitForThumbnailsBeforeUpload: false
        })
        this.uppy1.use(thumbnailGenerator, {
            id: 'ThumbnailGenerator',
            thumbnailWidth: 200,
            thumbnailHeight: 200,
            thumbnailType: 'application/pdf',
            waitForThumbnailsBeforeUpload: false
        })
        this.uppy.on('file-added', (file) => {
            this.uploadToCloud(file.data).then(r => {

                this.setState({cvFile: r.link, cvTitle: r.title}, () => {

                    document.querySelector('#cvUpdated').style.display = "none";
                    document.querySelector('#cv-required').style.display = "none";
                    document.querySelector('#cvUploaded').style.display = "Block";
                    document.querySelector('#cvUploaded').innerHTML=r.title
                })

            })

        })
        this.uppy1.on('file-added', (file) => {
            this.uploadToCloud(file.data).then(r => {
                this.setState({photoFile: r.link, photoTitle: r.title})
                document.querySelector('#photoUpdated').style.display = "none";
                document.querySelector('#photoUploaded').style.display = "Block";
                document.querySelector('#photoUploaded').innerHTML=r.title

            })

        })
        this.uppy.on('upload', (data) => {

        })
        this.uppy1.on('upload', (data) => {

        })
        this.onValueChange = this.onValueChange.bind(this);
        this.inputRef = createRef();


    }
    onWheel = () => {
        this.inputRef.current.blur();
    };
    listArrayOfEducation = () => ([])

    handleChangeInputs = (event, name) => {
        this.setState({[event.target.name]: event.target.value.replace(/\s\s/g, '')},()=>{
        })
    }
    handleDate = (value, name) => {
        this.setState({[name]: moment(value[0]).format('YYYY/MM/DD')}, () => {
        });
    };

    handleNumber = (event,name)=>{
        this.setState({[name]:event})
    }
    onValueChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    handleChangeInputsDaynmic = (event, name,props)=>{
        if(props !== "degree"){
            this.setState({[props]:event.target.value},()=>{
                if (props === "school") {
                    if (this.state.school !== "") {
                        document.querySelector('#school-required').style.display = "none";
                    }
                }
                if (name === "field_of_study") {
                    if (this.state.field_of_study !== "") {
                        document.querySelector('#field_of_study-required').style.display = "none";
                    }
                }

                if (name === "graduation_year") {
                    if (this.state.graduation_year !== "") {
                        document.querySelector('#graduation_year-required').style.display = "none";
                    }
                }
            })
        }else{

            this.setState({[props]:event.value},()=>{

                if (name === "degree") {
                    if (this.state.degree !== "") {
                        document.querySelector('#degree-required').style.display = "none";
                    }
                }
            })
        }

        if(name === "school"){
            this.setState({
                singleEducationObject: {
                    ...this.state.singleEducationObject,
                    school:event.target.value
                }
            },()=>{
            });
        }
        if(name === "field_of_study"){
            this.setState({
                singleEducationObject: {
                    ...this.state.singleEducationObject,
                    field_of_study:event.target.value
                }
            },()=>{
            });
        }
        if(name === "graduation_year"){
            this.setState({
                singleEducationObject: {
                    ...this.state.singleEducationObject,
                    graduation_year:event.target.value
                }
            },()=>{
            });
        }
        if(name === "degree"){
            this.setState({
                singleEducationObject: {
                    ...this.state.singleEducationObject,
                    degree:event.value
                }
            },()=>{
            });
        }


    }

    handleChangeInputsDaynmicWork = (event, name)=>{
        if(name === "company"){
            this.setState({
                singleWorkObject: {
                    ...this.state.singleWorkObject,
                    company_name:event.target.value
                }
            },()=>{
            });
        }
        if(name === "jobTitleCompany"){
            this.setState({
                singleWorkObject: {
                    ...this.state.singleWorkObject,
                    job_title:event.target.value
                }
            },()=>{
            });
        }
        if(name === "responsibilities"){
            this.setState({
                singleWorkObject: {
                    ...this.state.singleWorkObject,
                    responsibilities:event.target.value
                }
            },()=>{
            });
        }
        if(name === "startDate"){
            this.setState({
                singleWorkObject: {
                    ...this.state.singleWorkObject,
                    start_date:event.target.value
                }
            },()=>{
            });
        }
        if(name === "endDate"){
            this.setState({
                singleWorkObject: {
                    ...this.state.singleWorkObject,
                    end_date:event.target.value
                }
            },()=>{
            });
        }


    }


    handleClick = () => {
       if(this.state.school === "" || this.state.degree === "" || this.state.field_of_study === "" || this.state.graduation_year === ""){
           if(this.state.school === ""){
               document.querySelector('#school-required').style.display = "block";

           } if( this.state.degree === ""){
               document.querySelector('#degree-required').style.display = "block";
           }
           if(this.state.field_of_study === ""){
               document.querySelector('#field_of_study-required').style.display = "block";
           }
           if(this.state.graduation_year === ""){
               document.querySelector('#graduation_year-required').style.display = "block";
           }

       }else{
           let prevsingleEducationObject = this.state.EducationValues
           prevsingleEducationObject.push(this.state.singleEducationObject)
           this.setState({EducationValues:prevsingleEducationObject},()=>{
           })
           let prevArrayCount = this.state.arrayCount
           prevArrayCount.push(<>
               <div className="email-container">
                   <p className="email-container__title">School: </p>
                   <AvGroup>
                       <AvField type='text' placeholder='University / School Name'
                                name={`school`}
                                validate={{
                                    pattern: {
                                        value: '/^[^\\s]+[a-zA-Z\\s]+[^\\s]+$/',
                                        errorMessage: 'School must be only letters'
                                    },
                                    minLength: {
                                        value: 3,
                                        errorMessage: 'School cannot less than 3 char'
                                    },
                                    maxLength: {
                                        value: 70,
                                        errorMessage: 'School cannot more than 70 char'
                                    }
                                }}
                                onChange={(e) => this.handleChangeInputsDaynmic(e, `school`,'school')}
                                onSelectCapture={(e) => this.handleChangeInputsDaynmic(e, `school`,'school')}
                                className="email-container__input"/>
                   </AvGroup>
               </div>

               <div className="portfolio-container">
                   <p className="portfolio-container__title">Degree </p>
                   <Select
                       className="form-control selectFilterFormApply"
                       classNamePrefix="select"
                       placeholder={this.props.t('Your Degree')}
                       styles={customStyles}
                       name="degree"
                       options={Degree}
                       onChange={(e) => this.handleChangeInputsDaynmic(e, 'degree','degree')}
                   />
               </div>

               <div className="apply-date-container">
                   <p className="apply-date-container__title">Field of study: </p>
                   <AvGroup>
                       <AvField type='text' placeholder='Your Major'
                                name="field_of_study"
                                validate={{
                                    pattern: {
                                        value: '/^[^\\s]+[a-zA-Z\\s]+[^\\s]+$/',
                                        errorMessage: 'Major must be only letters'
                                    },
                                    minLength: {
                                        value: 3,
                                        errorMessage: 'Major cannot less than 3 char'
                                    },
                                    maxLength: {
                                        value: 70,
                                        errorMessage: 'Major cannot more than 70 char'
                                    }
                                }}
                                onChange={(e) => this.handleChangeInputsDaynmic(e, 'field_of_study','field_of_study')}
                                onSelectCapture={(e) => this.handleChangeInputsDaynmic(e, 'field_of_study','field_of_study')}
                                className="email-container__input"/>
                   </AvGroup>
               </div>


               <div className="applying-container">
                   <p className="applying-container__title">Graduation Year: </p>
                   <AvGroup>
                       <AvField type='number' placeholder='Graduation year'
                                name="graduation_year"
                                min={1950}
                                max={2030}
                                validate={{

                                    maxLength: {
                                        value: 4,
                                        errorMessage: 'Invalid Graduation Year'
                                    },
                                }}
                                onChange={(e) => this.handleChangeInputsDaynmic(e, 'graduation_year','graduation_year')}
                                onSelectCapture={(e) => this.handleChangeInputsDaynmic(e, 'graduation_year','graduation_year')}
                                className="email-container__input"/>
                   </AvGroup>
               </div>
           </>)
           this.setState({arrayCount: prevArrayCount,errors:true},()=>{
           })
       }
    };

    handleClickWork = () => {
        let prevsingleWorkObject = this.state.WorkValues
        prevsingleWorkObject.push(this.state.singleWorkObject)
        this.setState({WorkValues:prevsingleWorkObject},()=>{
        })
        let prevArrayCountwork = this.state.workCount
        prevArrayCountwork.push(
            <>
                <div className="email-container">
                    <p className="email-container__title">Company name: </p>
                    <AvGroup>
                        <AvField type='text' placeholder='Company Name'
                                 name="company"
                                 validate={{
                                     pattern: {
                                         value: '/^[^\\s]+[a-zA-Z\\s]+[^\\s]+$/',
                                         errorMessage: 'Company Name must be only letters'
                                     },
                                     minLength: {
                                         value: 3,
                                         errorMessage: 'Company Name cannot less than 3 char'
                                     },
                                     maxLength: {
                                         value: 70,
                                         errorMessage: 'Company Name cannot more than 70 char'
                                     }
                                 }}
                                 onChange={(e) => this.handleChangeInputsDaynmicWork(e, 'company')}
                                 onSelectCapture={(e) => this.handleChangeInputsDaynmicWork(e, 'company')}
                                 className="email-container__input"/>
                    </AvGroup>
                </div>
                <div className="email-container">
                    <p className="email-container__title">Job title: </p>
                    <AvGroup>
                        <AvField type='text' placeholder='Your job title'
                                 name="jobTitleCompany"
                                 validate={{
                                     pattern: {
                                         value: '/^[^\\s]+[a-zA-Z\\s]+[^\\s]+$/',
                                         errorMessage: 'job title must be only letters'
                                     },
                                     minLength: {
                                         value: 3,
                                         errorMessage: 'job title cannot less than 3 char'
                                     },
                                     maxLength: {
                                         value: 70,
                                         errorMessage: 'job title cannot more than 70 char'
                                     }
                                 }}
                                 onChange={(e) => this.handleChangeInputsDaynmicWork(e, 'jobTitleCompany')}
                                 onSelectCapture={(e) => this.handleChangeInputsDaynmicWork(e, 'jobTitleCompany')}
                                 className="email-container__input"/>
                    </AvGroup>
                </div>

                <div className="buttons-container d-sm-block">
                    <div className="apply-date-container">
                        <p className="apply-date-container__title">Start Date: </p>
                        <AvGroup>
                            <AvField  type="date" name="startDate"
                                      onChange={(e) => this.handleChangeInputsDaynmicWork(e, 'startDate')}
                                      onSelectCapture={(e) => this.handleChangeInputsDaynmicWork(e, 'startDate')}
                                      className="email-container__input"
                            />

                        </AvGroup>
                    </div>

                    <div className="apply-date-container">
                        <p className="apply-date-container__title">End Date</p>
                        <AvField  type="date" name="endDate"
                                  onChange={(e) => this.handleChangeInputsDaynmicWork(e, 'endDate')}
                                  onSelectCapture={(e) => this.handleChangeInputsDaynmicWork(e, 'endDate')}
                                  className="email-container__input"
                        />
                    </div>
                </div>
                <div className="applying-container">
                    <p className="applying-container__title">responsibilities: </p>
                    <AvGroup>
                        <AvField type='textarea'
                                 onChange={(e) => this.handleChangeInputsDaynmicWork(e, 'responsibilities')}
                                 onSelectCapture={(e) => this.handleChangeInputsDaynmicWork(e, 'responsibilities')}
                                 name="responsibilities"
                                 validate={{
                                     pattern: {
                                         value: '^[^\\s]+(\\s+[^\\s]+)*$',
                                         errorMessage: 'please enter valid responsibilities'
                                     },
                                     minLength: {
                                         value: 3,
                                         errorMessage: 'Responsibilities cannot less than 3 char'
                                     },
                                 }}
                                 placeholder='Job responsibilities'
                                 rows={7}
                                 className="email-container__input"/>
                    </AvGroup>
                </div>
            </>)
        this.setState({workCount: prevArrayCountwork})
    };


    handleSelect = (e, name) => {
        this.setState({[name]: e.value}, () => {
            if (name === "gender") {
                if (this.state.gender !== "") {
                    document.querySelector('#gender-required').style.display = "none";
                }
            }
            if (name === "military") {
                if (this.state.military !== "") {
                    document.querySelector('#military-required').style.display = "none";
                }
            }
            if (name === "social") {
                if (this.state.social !== "") {
                    document.querySelector('#social-required').style.display = "none";
                }
            }
            if (name === "notice") {
                if (this.state.notice !== "") {
                    document.querySelector('#notice-required').style.display = "none";
                }
            }

        });
    };

    getTitle = (titleForm) => {
        let elements = document.getElementsByClassName('ant-tooltip');

        if (titleForm === 'Skills') {
            for (let element of elements) {
                element.style.visibility = "visible";
            }
        } else {
            for (let element of elements) {
                element.style.visibility = "hidden";
            }
        }
    }


    componentDidMount() {
        if (cookies.get("direction") && cookies.get("direction") === "ltr") {
            moment.locale('en-au');
        } else {
            moment.locale('en-au');
        }
        this.setState({url: this.props.match.url})

        this.setState({
            arrayCount:
                [<>
                    <div className="email-container">
                        <p className="email-container__title">School: *</p>
                        <AvGroup>
                        <AvField type='text' placeholder='University / School Name'
                                 name="school"
                                 props="parent"
                                 validate={{
                                     pattern: {
                                         value: '/^[^\\s]+[a-zA-Z\\s]+[^\\s]+$/',
                                         errorMessage: 'School must be only letters'
                                     },
                                     minLength: {
                                         value: 3,
                                         errorMessage: 'School cannot less than 3 char'
                                     },
                                     maxLength: {
                                         value: 70,
                                         errorMessage: 'School cannot more than 70 char'
                                     }
                                 }}
                                 onChange={(e) => this.handleChangeInputsDaynmic(e, 'school','school')}
                                 onSelectCapture={(e) => this.handleChangeInputsDaynmic(e, 'school','school')}
                               className="email-container__input"/>
                        </AvGroup>
                        <div id="school-required"
                             className="display-error invalid-feedback p-0 m-0">Please enter a School
                        </div>

                    </div>

                    <div className="portfolio-container">
                        <p className="portfolio-container__title">Degree *</p>
                        <Select
                            className="form-control selectFilterFormApply"
                            classNamePrefix="select"
                            placeholder={this.props.t('Your Degree')}
                            styles={customStyles}
                            name="degree"
                            options={Degree}
                            onChange={(e) => this.handleChangeInputsDaynmic(e, 'degree','degree')}
                        />
                        <div id="degree-required"
                             className="display-error invalid-feedback p-0 m-0">Field required
                        </div>
                    </div>

                    <div className="apply-date-container">
                        <p className="apply-date-container__title">Field of study: *</p>
                        <AvGroup>
                            <AvField type='text' placeholder='Your Major'
                                     name="field_of_study"
                                     validate={{
                                         pattern: {
                                             value: '/^[^\\s]+[a-zA-Z\\s]+[^\\s]+$/',
                                             errorMessage: 'Major must be only letters'
                                         },
                                         minLength: {
                                             value: 3,
                                             errorMessage: 'Major cannot less than 3 char'
                                         },
                                         maxLength: {
                                             value: 70,
                                             errorMessage: 'Major cannot more than 70 char'
                                         }
                                     }}
                                     onChange={(e) => this.handleChangeInputsDaynmic(e, 'field_of_study','field_of_study')}
                                     onSelectCapture={(e) => this.handleChangeInputsDaynmic(e, 'field_of_study','field_of_study')}
                                     className="email-container__input"/>
                        </AvGroup>
                        <div id="field_of_study-required"
                             className="display-error invalid-feedback p-0 m-0">Please enter a Major
                        </div>
                    </div>


                    <div className="applying-container">
                        <p className="applying-container__title">Graduation Year: *</p>
                        <AvGroup>
                            <AvField type='number' placeholder='Graduation year'
                                     name="graduation_year"
                                     min={1950}
                                     max={2030}
                                     validate={{
                                         maxLength: {
                                             value: 4,
                                             errorMessage: 'Invalid Graduation Year'
                                         },
                                     }}
                                     onChange={(e) => this.handleChangeInputsDaynmic(e, 'graduation_year','graduation_year')}
                                     onSelectCapture={(e) => this.handleChangeInputsDaynmic(e, 'graduation_year','graduation_year')}
                                     className="email-container__input"/>
                        </AvGroup>
                        <div id="graduation_year-required"
                             className="display-error invalid-feedback p-0 m-0">Please enter your Graduation Year
                        </div>
                    </div>
                </>],
            workCount:[
                <>
                <div className="email-container">
                    <p className="email-container__title">Company name: </p>
                    <AvGroup>
                        <AvField type='text' placeholder='Company Name'
                                 name="company"
                                 validate={{
                                     pattern: {
                                         value: '/^[^\\s]+[a-zA-Z\\s]+[^\\s]+$/',
                                         errorMessage: 'Company Name must be only letters'
                                     },
                                     minLength: {
                                         value: 3,
                                         errorMessage: 'Company Name cannot less than 3 char'
                                     },
                                     maxLength: {
                                         value: 70,
                                         errorMessage: 'Company Name cannot more than 70 char'
                                     }
                                 }}
                                 onChange={(e) => this.handleChangeInputsDaynmicWork(e, 'company')}
                                 onSelectCapture={(e) => this.handleChangeInputsDaynmicWork(e, 'company')}
                                 className="email-container__input"/>
                    </AvGroup>
                </div>
                <div className="email-container">
                    <p className="email-container__title">Job title: </p>
                    <AvGroup>
                        <AvField type='text' placeholder='Your job title'
                                 name="jobTitleCompany"
                                 validate={{
                                     pattern: {
                                         value: '/^[^\\s]+[a-zA-Z\\s]+[^\\s]+$/',
                                         errorMessage: 'job title must be only letters'
                                     },
                                     minLength: {
                                         value: 3,
                                         errorMessage: 'job title cannot less than 3 char'
                                     },
                                     maxLength: {
                                         value: 70,
                                         errorMessage: 'job title cannot more than 70 char'
                                     }
                                 }}
                                 onChange={(e) => this.handleChangeInputsDaynmicWork(e, 'jobTitleCompany')}
                                 onSelectCapture={(e) => this.handleChangeInputsDaynmicWork(e, 'jobTitleCompany')}
                                 className="email-container__input"/>
                    </AvGroup>
                </div>

                <div className="buttons-container d-sm-block">
                    <div className="apply-date-container">
                        <p className="apply-date-container__title">Start Date: </p>
                        <AvGroup>
                            <AvField  type="date" name="startDate"
                                      onChange={(e) => this.handleChangeInputsDaynmicWork(e, 'startDate')}
                                      onSelectCapture={(e) => this.handleChangeInputsDaynmicWork(e, 'startDate')}
                                      className="email-container__input"
                            />

                        </AvGroup>
                    </div>

                    <div className="apply-date-container">
                        <p className="apply-date-container__title">End Date</p>
                        <AvField  type="date" name="endDate"
                                  onChange={(e) => this.handleChangeInputsDaynmicWork(e, 'endDate')}
                                  onSelectCapture={(e) => this.handleChangeInputsDaynmicWork(e, 'endDate')}
                                  className="email-container__input"
                        />
                    </div>
                </div>
                <div className="applying-container">
                    <p className="applying-container__title">responsibilities: </p>
                    <AvGroup>
                        <AvField type='textarea'
                                 onChange={(e) => this.handleChangeInputsDaynmicWork(e, 'responsibilities')}
                                 onSelectCapture={(e) => this.handleChangeInputsDaynmicWork(e, 'responsibilities')}
                                 name="responsibilities"
                                 validate={{
                                     pattern: {
                                         value: '^[^\\s]+(\\s+[^\\s]+)*$',
                                         errorMessage: 'please enter valid responsibilities'
                                     },
                                     minLength: {
                                         value: 3,
                                         errorMessage: 'Responsibilities cannot less than 3 char'
                                     },
                                 }}
                                 placeholder='Job responsibilities'
                                 rows={7}
                                 className="email-container__input"/>
                    </AvGroup>
                </div>
            </>]
        })
        axios.get(process.env.REACT_APP_API_URL, {
            params: {
                "url": "api/resource/Jobs/" + encodeURIComponent(this.props.match.params.id)
            }
        })
            .then((response) => {
                let singleJob = response.data.data
                let title = response.data.data.title
                this.setState({
                    steps: [{
                        image: <LazyLoadImage className="imageSteps" alt="application" src={step1}/>,
                        title: "Application",
                        content: <main className="container application-form__main">
                            <div className="card-container">
                                <div className="card-container__fields-container">
                                    <div className="email-container">
                                        <p className="email-container__title">E-mail Address: *</p>
                                        <AvGroup>
                                            <AvField type='text' name="email" placeholder='Your E-mail Address'
                                                     validate={{
                                                         required: {value: true, errorMessage: 'Please enter a email'},
                                                         pattern: {
                                                             value: '/^(?![^@]*([-._])\\1)(?![^@]*([-.])(?:[^@]*\\2){2})\\w(?:[\\w.-]*\\w)?@(?:[a-zA-Z]+\\.)+[a-zA-Z]+$/',
                                                             errorMessage: 'Please enter valid mail'
                                                         }
                                                     }}
                                                     onChange={(e) => this.handleChangeInputs(e, 'email')}
                                                     onSelectCapture={(e) => this.handleChangeInputs(e, 'email')}
                                                     className="email-container__input"/>
                                        </AvGroup>
                                    </div>
                                    <div className="buttons-container-old">

                                            <span>
                                                <StatusBar
                                                    uppy={this.uppy}
                                                    showProgressDetails
                                                    id="cvUpdated"
                                                    hideAfterFinish={true}
                                                />
                                            </span>
                                        <span>
                                                <StatusBar
                                                    uppy={this.uppy1}
                                                    id="photoUpdated"
                                                    showProgressDetails
                                                    hideAfterFinish={true}
                                                />
                                            </span>
                                    </div>
                                    <div className="buttons-container pb-0 m-0">
                                            <span className="btn btn-default btn-file">

                                            <DragDrop uppy={this.uppy}
                                                      locale={{
                                                          strings: {
                                                              dropHereOr: "Upload CV *", browse: "browse"
                                                          }
                                                      }}
                                            />

                                            </span>

                                        <span className="btn btn-default btn-file">

                                            <DragDrop uppy={this.uppy1}
                                                      locale={{
                                                          strings: {
                                                              dropHereOr: "Upload recent photo", browse: "browse"
                                                          }
                                                      }}
                                            />
                                            </span>
                                    </div>
                                    <div className="buttons-container-old widths" >

                                            <span>
                                                <div id="cvUploaded"
                                                     className="display-error cv-feedback p-0 m-0">
                                    </div>
                                            </span>
                                        <span>
                                            <div id="photoUploaded"
                                                 className="display-error cv-feedback p-0 m-0">
                                            </div>

                                            </span>
                                    </div>
                                    <div id="cv-required" className="display-error invalid-feedback p-0 m-0">cv
                                        required
                                    </div>
                                    <div className="portfolio-container">
                                        <p className="portfolio-container__title">Portfolio URL (if any)</p>

                                        <AvGroup>
                                            <AvField type='text' placeholder='Your portfolio URL'
                                               name="portfolio"
                                               validate={{
                                                   required:{value:false},
                                                   pattern: {
                                                       value: '/^(?:(?:https?|ftp):\\/\\/)(?:\\S+(?::\\S*)?@)?(?:(?!10(?:\\.\\d{1,3}){3})(?!127(?:\\.\\d{1,3}){3})(?!169\\.254(?:\\.\\d{1,3}){2})(?!192\\.168(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)(?:\\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)*(?:\\.(?:[a-z\u00a1-\uffff]{2,})))(?::\\d{2,5})?(?:\\/[^\\s]*)?$/i',
                                                       errorMessage: 'please enter valid url'
                                                   },
                                               }}
                                               onChange={(e) => this.handleChangeInputs(e, 'portfolio')}
                                               onSelectCapture={(e) => this.handleChangeInputs(e, 'portfolio')}
                                               className="email-container__input"/>
                                        </AvGroup>
                                    </div>
                                    <div className="apply-date-container">
                                        <p className="apply-date-container__title">Applying date: </p>
                                        <Flatpickr
                                            className="form-control apply-date-container__input"
                                            name="chooseDateTime"
                                            ena
                                            placeholder="mm/yyyy"
                                            wrap={true}
                                            value={moment().format('YYYY/MM/DD')}
                                            disabled
                                            onChange={date => this.handleDate(date, 'chooseDateTime')}
                                            options={{
                                                enableTime: false, disableMobile: "true",
                                            }}
                                        />
                                    </div>
                                    <div className="applying-container">
                                        <p className="applying-container__title">Applying for: </p>
                                        <input type='text' placeholder='Job name' disabled={true}
                                               name="jobName"
                                               onChange={(e) => this.handleChangeInputs(e, 'jobName')}
                                               onSelectCapture={(e) => this.handleChangeInputs(e, 'jobName')}
                                               value={title}
                                               className="email-container__input"/>
                                    </div>
                                </div>
                            </div>

                        </main>
                    }, {
                        image: <LazyLoadImage className="imageSteps" alt="personal" src={step2}/>,
                        title: "Personal Information",
                        content: <main className="container application-form__main">
                            <div className="card-container">
                                <div className="card-container__fields-container">
                                    <div className="email-container">
                                        <p className="email-container__title">Name: *</p>
                                        <AvGroup>
                                            <AvField type='text' placeholder='Your Full Name'
                                                     name="fullName"
                                                     validate={{
                                                         required: {
                                                             value: true,
                                                             errorMessage: 'Please enter a full name'
                                                         },
                                                         pattern: {
                                                             value: '/^[^\\s]+[a-zA-Z\\s]+[^\\s]+$/',
                                                             errorMessage: 'Full name must be only letters'
                                                         },
                                                         minLength: {
                                                             value: 3,
                                                             errorMessage: 'Full name cannot less than 3 char'
                                                         },
                                                         maxLength: {
                                                             value: 70,
                                                             errorMessage: 'Full name cannot more than 70 char'
                                                         }
                                                     }}
                                                     onChange={(e) => this.handleChangeInputs(e, 'fullName')}
                                                     onSelectCapture={(e) => this.handleChangeInputs(e, 'fullName')}
                                                     className="email-container__input"/>
                                        </AvGroup>

                                    </div>

                                    <div className="portfolio-container">
                                        <p className="portfolio-container__title">Gender: *</p>
                                        <AvGroup>
                                            <Select
                                                className="form-control selectFilterFormApply"
                                                classNamePrefix="select"
                                                placeholder={this.props.t('Select your Gender')}
                                                styles={customStyles}
                                                value={Gender.find(
                                                    (obj) => obj.value === this.state.gender
                                                )}
                                                name="gender"
                                                options={Gender}
                                                onChange={(e) => this.handleSelect(e, 'gender')}
                                            />
                                        </AvGroup>
                                        <div id="gender-required"
                                             className="display-error invalid-feedback p-0 m-0">Field required
                                        </div>
                                    </div>

                                    <div className="apply-date-container">
                                        <p className="apply-date-container__title">Address: </p>
                                        <AvGroup>
                                            <AvField type='text' placeholder='Your home address'
                                                     name="address"
                                                     validate={{
                                                         pattern: {
                                                             value: '^[^\\s]+(\\s+[^\\s]+)*$',
                                                             errorMessage: 'please enter valid address'
                                                         },
                                                         minLength: {
                                                             value: 3,
                                                             errorMessage: 'Address cannot less than 3 char'
                                                         },
                                                     }}
                                                     onChange={(e) => this.handleChangeInputs(e, 'address')}
                                                     onSelectCapture={(e) => this.handleChangeInputs(e, 'address')}
                                                     className="email-container__input"/>
                                        </AvGroup>
                                    </div>


                                    <div className="applying-container">
                                        <p className="applying-container__title">Mobile Number: *</p>
                                        <AvGroup>
                                            <AvField type='number' placeholder='Your mobile number'
                                                     name="mobile"
                                                     validate={{
                                                         required: {
                                                             value: true,
                                                             errorMessage: 'Please enter your mobiles'
                                                         },
                                                         pattern: {
                                                             value: '/^(01|00201|\\+201|201)[0|1|2|5]{1}\\d{8}/',
                                                             errorMessage: 'Please enter valid phone number'
                                                         },
                                                         maxLength: {
                                                             value: 12,
                                                             errorMessage: 'Address cannot more than 12 char'
                                                         },
                                                     }}
                                                     onWheel={ event => event.currentTarget.blur() }                                                     onChange={(e) => this.handleChangeInputs(e, 'mobile')}
                                                     onSelectCapture={(e) => this.handleChangeInputs(e, 'mobile')}
                                                     className="email-container__input"/>
                                        </AvGroup>
                                    </div>
                                    <div className="apply-date-container">
                                        <p className="apply-date-container__title">Date of birth: *</p>
                                        <AvGroup>
                                            <AvField name="date" type="date"
                                                     lang="en"
                                                     placeholder='Your Date of birth'
                                                     onChange={(e) => this.handleChangeInputs(e, 'date')}
                                                     onSelectCapture={(e) => this.handleChangeInputs(e, 'date')}
                                                     validate={{
                                                         required: {
                                                             value: true,
                                                             errorMessage: 'Please enter your date of birth'
                                                         },
                                                         dateRange: {
                                                             format: 'DD/MM/YYYY',
                                                             start: {value: -100, units: 'years',},
                                                             end: {value: -10, units: 'years',}
                                                         }

                                                     }}
                                                     className="email-container__input"
                                            />
                                        </AvGroup>
                                    </div>
                                    <div className="applying-container">
                                        <p className="applying-container__title">Nationality: *</p>
                                        <AvGroup>
                                            <AvField type='text' placeholder='Your Nationality'
                                                     name="nationality"
                                                     validate={{
                                                         required: {
                                                             value: true,
                                                             errorMessage: 'Please enter a nationality'
                                                         },
                                                         pattern: {
                                                             value: '/^[^\\s]+[a-zA-Z\\s]+[^\\s]+$/',
                                                             errorMessage: 'Nationality must be only letters'
                                                         },
                                                         minLength: {
                                                             value: 3,
                                                             errorMessage: 'Nationality cannot less than 3 char'
                                                         },
                                                         maxLength: {
                                                             value: 70,
                                                             errorMessage: 'Nationality cannot more than 70 char'
                                                         }
                                                     }}
                                                     onChange={(e) => this.handleChangeInputs(e, 'nationality')}
                                                     onSelectCapture={(e) => this.handleChangeInputs(e, 'nationality')}
                                                     className="email-container__input"/>
                                        </AvGroup>
                                    </div>
                                    <div className="portfolio-container">
                                        <p className="portfolio-container__title">Military status: *</p>
                                        <Select
                                            className="form-control selectFilterFormApply"
                                            classNamePrefix="select"
                                            placeholder={this.props.t('Choose your military status')}
                                            styles={customStyles}
                                            value={Military.find(
                                                (obj) => obj.value === this.state.military
                                            )}
                                            name="military"
                                            options={Military}
                                            onChange={(e) => this.handleSelect(e, 'military')}
                                        />
                                        <div id="military-required"
                                             className="display-error invalid-feedback p-0 m-0">Field required
                                        </div>
                                    </div>
                                    <div className="portfolio-container">
                                        <p className="portfolio-container__title">Social status: *</p>
                                        <Select
                                            className="form-control selectFilterFormApply"
                                            classNamePrefix="select"
                                            placeholder={this.props.t('Choose your Social status')}
                                            styles={customStyles}
                                            value={Social.find(
                                                (obj) => obj.value === this.state.social
                                            )}
                                            name="social"
                                            options={Social}
                                            onChange={(e) => this.handleSelect(e, 'social')}
                                        />
                                        <div id="social-required"
                                             className="display-error invalid-feedback p-0 m-0">Field required
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </main>
                    }, {
                        image: <LazyLoadImage className="imageSteps" alt="education" src={step3}/>,
                        title: "Education",
                        content: <main className="container application-form__main">
                            <div className="card-container">
                                <div className="card-container__fields-container">

                                    {this.state.arrayCount}
                                    <div className="add-another-job mt-4 " onClick={this.handleClick}>+
                                        Add
                                        Another
                                        Education
                                    </div>

                                </div>
                            </div>

                        </main>
                    }, {
                        image: <LazyLoadImage className="imageSteps" alt="skills" src={step4}/>,
                        title: "Skills",
                        content: <main className="container application-form__main">
                            <div className="card-container">
                                <div className="card-container__fields-container">
                                    <div className="email-container">
                                        <p className="email-container__title">English Language: *</p>
                                        <Slider min={0} max={10} marks={marks} tooltipPlacement="bottom" tooltipVisible
                                                name="english"
                                                onChange={(e) => this.handleNumber(e,"english")}/>
                                    </div>

                                    <div className="portfolio-container mt-3">
                                        <p className="portfolio-container__title">Arabic Language: *</p>
                                        <Slider min={0} max={10} marks={marks} tooltipPlacement="bottom" tooltipVisible
                                                name="arabic"
                                                onChange={(e) => this.handleNumber(e,"arabic")}/>

                                    </div>

                                    <div className="apply-date-container mt-3">
                                        <p className="apply-date-container__title">Microsoft office: *</p>
                                        <Slider min={0} max={10} marks={marks} tooltipPlacement="bottom" tooltipVisible
                                                name="microsoft"
                                                onChange={(e) => this.handleNumber(e,"microsoft")}/>
                                    </div>


                                    <div className="applying-container">
                                        <p className="applying-container__title">Please describe your Knowledge,
                                            skills
                                            &
                                            abilities: </p>
                                        <AvGroup>
                                        <AvField type='textarea'
                                                 onChange={(e) => this.handleChangeInputs(e, 'knowledgeSkills')}
                                                 onSelectCapture={(e) => this.handleChangeInputs(e, 'knowledgeSkills')}
                                                 name="knowledgeSkills"
                                                 validate={{
                                                     pattern: {
                                                         value: '^[^\\s]+(\\s+[^\\s]+)*$',
                                                         errorMessage: 'please enter valid description'
                                                     },
                                                     minLength: {
                                                         value: 3,
                                                         errorMessage: 'Description cannot less than 3 char'
                                                     },
                                                 }}
                                                  placeholder='Describe your Knowledge, skills & abilities'
                                                  rows={7}
                                                  className="email-container__input"/>
                                        </AvGroup>
                                        </div>
                                </div>
                            </div>

                        </main>
                    }, {
                        image: <LazyLoadImage className="imageSteps" alt="work" src={step5}/>,
                        title: "Work History",
                        content: <main className="container application-form__main">
                            <div className="card-container">
                                <div className="card-container__fields-container">

                                    {this.state.workCount}
                                    <div className="add-another-job mt-4 "
                                         onClick={() => this.handleClickWork()}>+
                                        Add
                                        Another work experience
                                    </div>
                                </div>
                            </div>

                        </main>
                    }, {
                        image: <LazyLoadImage className="imageSteps" alt="references" src={step6}/>,
                        title: "References",
                        content: <main className="container application-form__main">
                            <div className="card-container">
                                <div className="card-container__fields-container">
                                    <div className="email-container mt-2">

                                        <AvRadioGroup name="currentEmployer" className="" required errorMessage="please select one answer">
                                            <p className="email-container__title-Radio">Can we call your current
                                                employer?</p>
                                            <AvRadio label="yes, and I will mention its contacts in my references below" value="Yes" onChange={this.onValueChange} />
                                            <AvRadio label="No" value="No" onChange={this.onValueChange}/>
                                        </AvRadioGroup>
                                    </div>
                                    <div className="email-container mt-3">

                                        <AvRadioGroup name="relatives" className="" required errorMessage="please select one answer">
                                            <p className="email-container__title-Radio">Do you have relatives
                                                working for
                                                our
                                                company?</p>
                                            <AvRadio label="Yes" value="Yes" onChange={this.onValueChange} />
                                            <AvRadio label="No" value="No" onChange={this.onValueChange}/>
                                        </AvRadioGroup>
                                    </div>
                                    <p className="email-container__title-Radio mt-4">Reference 1:</p>
                                    <div className="email-container mx-lg-5">
                                        <p className="email-container__title">Name: </p>
                                        <AvGroup>
                                        <AvField type='text' placeholder='Enter Reference Name'
                                                 name="reference1"
                                                 validate={{
                                                     pattern: {
                                                         value: '/^[^\\s]+[a-zA-Z\\s]+[^\\s]+$/',
                                                         errorMessage: 'Reference Name must be only letters'
                                                     },
                                                     minLength: {
                                                         value: 3,
                                                         errorMessage: 'Reference Name cannot less than 3 char'
                                                     },
                                                     maxLength: {
                                                         value: 70,
                                                         errorMessage: 'Reference Name cannot more than 70 char'
                                                     }
                                                 }}
                                                 onChange={(e) => this.handleChangeInputs(e, 'reference1')}
                                                 onSelectCapture={(e) => this.handleChangeInputs(e, 'reference1')}
                                               className="email-container__input"/>
                                        </AvGroup>
                                    </div>
                                    <div className="email-container mx-lg-5">
                                        <p className="email-container__title">Job title: </p>
                                        <AvGroup>
                                        <AvField type='text' placeholder='his position'
                                               name="reference1JobTitle"
                                               validate={{
                                                   pattern: {
                                                       value: '/^[^\\s]+[a-zA-Z\\s]+[^\\s]+$/',
                                                       errorMessage: 'Job title must be only letters'
                                                   },
                                                   minLength: {
                                                       value: 3,
                                                       errorMessage: 'Job title cannot less than 3 char'
                                                   },
                                                   maxLength: {
                                                       value: 70,
                                                       errorMessage: 'Job title cannot more than 70 char'
                                                   }
                                               }}
                                               onChange={(e) => this.handleChangeInputs(e, 'reference1JobTitle')}
                                               onSelectCapture={(e) => this.handleChangeInputs(e, 'reference1JobTitle')}
                                               className="email-container__input"/>
                                        </AvGroup>
                                    </div>
                                    <div className="email-container mx-lg-5">
                                        <p className="email-container__title">Mobile Number: </p>
                                        <AvGroup>
                                            <AvField type='number' placeholder='His mobile number'
                                                     name="reference1MobileNumber"
                                                     validate={{
                                                         pattern: {
                                                             value: '/^(01|00201|\\+201|201)[0|1|2|5]{1}\\d{8}/',
                                                             errorMessage: 'Please enter valid phone number'
                                                         },
                                                         maxLength: {
                                                             value: 12,
                                                             errorMessage: 'Address cannot more than 12 char'
                                                         },
                                                     }}
                                                     onChange={(e) => this.handleChangeInputs(e, 'reference1MobileNumber')}
                                                     onSelectCapture={(e) => this.handleChangeInputs(e, 'reference1MobileNumber')}
                                                     className="email-container__input"/>
                                        </AvGroup>
                                    </div>
                                    <p className="email-container__title-Radio mt-4">Reference 2:</p>
                                    <div className="email-container mx-lg-5">
                                        <p className="email-container__title">Name: </p>
                                    <AvGroup>
                                        <AvField type='text' placeholder='Enter Reference Name'
                                                 name="reference2"
                                                 validate={{
                                                     pattern: {
                                                         value: '/^[^\\s]+[a-zA-Z\\s]+[^\\s]+$/',
                                                         errorMessage: 'Reference Name must be only letters'
                                                     },
                                                     minLength: {
                                                         value: 3,
                                                         errorMessage: 'Reference Name cannot less than 3 char'
                                                     },
                                                     maxLength: {
                                                         value: 70,
                                                         errorMessage: 'Reference Name cannot more than 70 char'
                                                     }
                                                 }}
                                                 onChange={(e) => this.handleChangeInputs(e, 'reference2')}
                                                 onSelectCapture={(e) => this.handleChangeInputs(e, 'reference2')}
                                                 className="email-container__input"/>
                                    </AvGroup>
                                    </div>
                                    <div className="email-container mx-lg-5">
                                        <p className="email-container__title">Job title: </p>
                                        <AvGroup>
                                            <AvField type='text' placeholder='his position'
                                                     name="reference2JobTitle"
                                                     validate={{
                                                         pattern: {
                                                             value: '/^[^\\s]+[a-zA-Z\\s]+[^\\s]+$/',
                                                             errorMessage: 'Job title must be only letters'
                                                         },
                                                         minLength: {
                                                             value: 3,
                                                             errorMessage: 'Job title cannot less than 3 char'
                                                         },
                                                         maxLength: {
                                                             value: 70,
                                                             errorMessage: 'Job title cannot more than 70 char'
                                                         }
                                                     }}
                                                     onChange={(e) => this.handleChangeInputs(e, 'reference2JobTitle')}
                                                     onSelectCapture={(e) => this.handleChangeInputs(e, 'reference2JobTitle')}
                                                     className="email-container__input"/>
                                        </AvGroup>
                                    </div>
                                    <div className="email-container mx-lg-5">
                                        <p className="email-container__title">Mobile Number: </p>
                                        <AvGroup>
                                            <AvField type='number' placeholder='His mobile number'
                                                     name="reference2MobileNumber"
                                                     validate={{
                                                         pattern: {
                                                             value: '/^(01|00201|\\+201|201)[0|1|2|5]{1}\\d{8}/',
                                                             errorMessage: 'Please enter valid phone number'
                                                         },
                                                         maxLength: {
                                                             value: 12,
                                                             errorMessage: 'Address cannot more than 12 char'
                                                         },
                                                     }}
                                                     onChange={(e) => this.handleChangeInputs(e, 'reference2MobileNumber')}
                                                     onSelectCapture={(e) => this.handleChangeInputs(e, 'reference2MobileNumber')}
                                                     className="email-container__input"/>
                                        </AvGroup>
                                    </div>
                                </div>
                            </div>

                        </main>
                    }, {
                        image: <LazyLoadImage className="imageSteps" alt="additional" src={step7}/>,
                        title: "Additional Information",
                        content: <main className="container application-form__main">
                            <div className="card-container">
                                <div className="card-container__fields-container">
                                    <div className="email-container">
                                        <p className="email-container__title">Net Expected Salary: *</p>
                                        <AvGroup>
                                        <AvField type='number' placeholder='Expected salary'
                                                 name="expectedSalary"
                                                 min={1000}
                                                 max={200000}
                                                 validate={{
                                                     required: {
                                                         value: true,
                                                         errorMessage: 'Please enter your expected salary'
                                                     },
                                                 }}
                                                 onChange={(e) => this.handleChangeInputs(e, 'expectedSalary')}
                                                 onSelectCapture={(e) => this.handleChangeInputs(e, 'expectedSalary')}
                                               className="email-container__input"/>
                                        </AvGroup>
                                    </div>
                                    <div className="applying-container">
                                        <p className="applying-container__title">What makes you Ideal Candidate
                                            for this
                                            position?: *</p>
                                        <AvGroup>
                                            <AvField type='textarea'
                                                     validate={{
                                                         pattern: {
                                                             value: '^[^\\s]+(\\s+[^\\s]+)*$',
                                                             errorMessage: 'please enter valid description'
                                                         },
                                                         required: {
                                                             value: true,
                                                             errorMessage: 'This field can’t be empty'
                                                         },
                                                         minLength: {
                                                             value: 3,
                                                             errorMessage: 'Ideal Candidate cannot less than 3 char'
                                                         }
                                                     }}
                                                     onChange={(e) => this.handleChangeInputs(e, 'idealCandidate')}
                                                     onSelectCapture={(e) => this.handleChangeInputs(e, 'idealCandidate')}
                                                     name="idealCandidate"
                                                     placeholder='What makes you Ideal Candidate...'
                                                     rows={7}
                                                     className="email-container__input"/>
                                        </AvGroup>
                                    </div>
                                    <div className="portfolio-container">
                                        <p className="apply-date-container__title">Notice period: *</p>
                                        <Select
                                            className="form-control selectFilterFormApply"
                                            classNamePrefix="select"
                                            placeholder={this.props.t('when you will be able to join us approximately')}
                                            styles={customStyles}
                                            value={Notice.find(
                                                (obj) => obj.value === this.state.notice
                                            )}
                                            name="notice"
                                            options={Notice}
                                            onChange={(e) => this.handleSelect(e, 'notice')}
                                        />
                                        <div id="notice-required"
                                             className="display-error invalid-feedback p-0 m-0">Field required
                                        </div>
                                    </div>
                                    <div className="email-container mt-3">

                                        <AvRadioGroup name="willingToWork" className="" required errorMessage="please select one answer">
                                            <p className="email-container__title-Radio">Are you willing to work in
                                                6th of
                                                October city?</p>
                                            <AvRadio label="Yes" value="Yes" onChange={this.onValueChange} />
                                            <AvRadio label="No" value="No" onChange={this.onValueChange}/>
                                        </AvRadioGroup>
                                    </div>
                                    <div className="email-container mt-3">
                                        <AvRadioGroup name="smoker" className="" required errorMessage="please select one answer">
                                            <p className="email-container__title-Radio">Are you a smoker?</p>
                                            <AvRadio label="Yes" value="Yes" onChange={this.onValueChange} />
                                            <AvRadio label="No" value="No" onChange={this.onValueChange}/>
                                        </AvRadioGroup>
                                    </div>
                                    <div className="email-container mt-3">

                                        <AvRadioGroup name="medical" className="" required errorMessage="please select one answer">
                                            <p className="email-container__title-Radio">Do you have any Medical
                                                impediment?</p>
                                            <AvRadio label="Yes" value="Yes" onChange={this.onValueChange} />
                                            <AvRadio label="No" value="No" onChange={this.onValueChange}/>
                                        </AvRadioGroup>
                                    </div>
                                </div>
                            </div>

                        </main>
                    }, {
                        image: <LazyLoadImage className="imageSteps" alt="Endorsement" src={step8}/>,
                        title: "Endorsement",
                        content: <main className="container application-form__main">
                            <div className="card-container">
                                <div className="card-container__fields-container">
                                    {/*<div className="email-container mt-3">*/}

                                    {/*    <AvRadioGroup name="undersigned" className="" required errorMessage="please select one answer">*/}
                                    {/*        <p className="email-container__title-Radio">I am the undersigned have*/}
                                    {/*            mentioned*/}
                                    {/*            that*/}
                                    {/*            the data is complete and correct and that I may liable for dismissal*/}
                                    {/*            if it*/}
                                    {/*            was*/}
                                    {/*            not the accuracy of the reported:*</p>*/}
                                    {/*        <AvRadio label="yes, and I will mention its contacts in my references below" value="Yes" onChange={this.onValueChange} />*/}
                                    {/*        <AvRadio label="No" value="No" onChange={this.onValueChange}/>*/}
                                    {/*    </AvRadioGroup>*/}
                                    {/*</div>*/}
                                    <div className="email-container mt-2">
                                        <p className="email-container__title">signature: *</p>
                                        <AvGroup>
                                            <AvField type='text' placeholder='Your name'
                                                     name="signature"
                                                     validate={{
                                                         required: {
                                                             value: true,
                                                             errorMessage: 'Please enter a signature'
                                                         },
                                                         pattern: {
                                                             value: '/^[^\\s]+[a-zA-Z\\s]+[^\\s]+$/',
                                                             errorMessage: 'Signature must be only letters'
                                                         },
                                                         minLength: {
                                                             value: 3,
                                                             errorMessage: 'Signature cannot less than 3 char'
                                                         },
                                                         maxLength: {
                                                             value: 70,
                                                             errorMessage: 'Signature cannot more than 70 char'
                                                         }
                                                     }}
                                                     onChange={(e) => this.handleChangeInputs(e, 'signature')}
                                                     onSelectCapture={(e) => this.handleChangeInputs(e, 'signature')}
                                                     className="email-container__input"/>
                                        </AvGroup>
                                    </div>
                                </div>
                            </div>
                        </main>
                    }]
                })
                this.setState({singleJob, isLoading: false})
            });
    }

    uploadToCloud = async (file) => {
        let filename = file.name
        let filetype = file.type
        switch (true) {
            case filetype.includes('.document'):
                filetype = 'Word'
                break
            case filetype.includes('.sheet'):
                filetype = 'Excel'
                break
            case filetype.includes('.presentation'):
                filetype = 'Power Point'
                break
            case filetype.includes('pdf'):
                filetype = 'PDF'
                break
            default:
                break
        }

        let formData = new FormData();
        formData.append('file', file)
        let resp = {}
        await axios.post(process.env.REACT_APP_UPLOAD_API_URL, formData).then((response) => {
            resp = {
                'type': filetype, 'title': filename, 'link': response.data
            }
        });
        return resp
    }
    finishedForm = (value)=>{
        this.setState({hide:value})
    }


    render() {
        let {t} = this.props
        const {steps} = this.state

        let rendered = this.state.isLoading ? (<div className="container my-5">
            <div className="row">
                <div className="col-lg-12 col-md-12">
                    <div className="example">
                        <Spin size="large" style={{margin: "auto"}}/>
                    </div>
                </div>
            </div>
        </div>) : (<>
            <div id="FormJob" className="container-fluid noPadding" style={{direction: 'ltr'}}>
                <div id="ApplyFormJobs">
                    <div className="container">
                        <div className="row">
                            <div className="form-jobs">
                                <span className="sectionForms">{this.state.singleJob.title}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    {!this.state.hide ?
                    <Wizard
                        valuesFirstObject={{
                            email_address: this.state.email,
                            portfolio_urlif_any: this.state.portfolio,
                            applying_for: this.state.singleJob.title,
                            applying_date: moment().format('YYYY/MM/DD'),
                            upload_cv: this.state.cvFile,
                            upload_recent_photo: this.state.photoFile,
                            full_name: this.state.fullName,
                            gender: this.state.gender,
                            address: this.state.address,
                            mobile_number: this.state.mobile,
                            date_of_birth: this.state.date,
                            nationality: this.state.nationality,
                            national_id_number: this.state.nationalId,
                            military_status: this.state.military,
                            social_status: this.state.social,
                            english_language:this.state.english,
                            arabic_language:this.state.arabic,
                            microsoft_office:this.state.microsoft,
                            please_describe_your_knowledge_skills_and_abilities:this.state.knowledgeSkills,
                            can_we_call_your_current_employer:this.state.currentEmployer,
                            do_you_have_relatives_working_for_our_company:this.state.relatives,
                            reference_name1:this.state.reference1,
                            job_title1:this.state.reference1JobTitle,
                            mobile_number1:this.state.reference1MobileNumber,
                            reference_name2:this.state.reference2,
                            job_title2:this.state.reference2JobTitle,
                            mobile_number2:this.state.reference2MobileNumber,
                            net_expected_salary:this.state.expectedSalary,
                            what_makes_you_ideal_candidate_for_this_position:this.state.idealCandidate,
                            notice_period:this.state.notice,
                            are_you_willilng_to_work_in_6th_of_october_city:this.state.willingToWork,
                            are_you_a_smoker:this.state.smoker,
                            do_you_have_any_medical_impediment:this.state.medical,
                            signature:this.state.signature,
                            education:this.state.EducationValues.concat(this.state.singleEducationObject),
                            work_experiences:this.state.WorkValues.concat(this.state.singleWorkObject)
                        }}
                        anotherValues={
                            {
                                school:this.state.school,
                                degree:this.state.degree,
                                field_of_study:this.state.field_of_study,
                                graduation_year:this.state.graduation_year,
                            }
                        }
                        validate
                        sendFinish={this.finishedForm}
                        steps={steps}
                        getTitle={this.getTitle}
                    />:  <main className="container thanks__main">
                            <div className="thanks__main--img">
                                <LazyLoadImage src={ThanksImg} alt='thank-you'/>
                            </div>
                            <div className="thanks__main--text  mt-4">
                                <h2>Your application is successfully submitted</h2>
                            </div>
                            <div className="text-center my-5">
                                <Link to="/careers">
                                    <AwesomeButton type="secondary"
                                >{t("Thanks")}</AwesomeButton>
                                </Link>
                                </div>

                        </main>}
                </div>
            </div>
            <Helmet>
                <link href={"https://variiance.com" + this.state.url} rel="canonical"/>
            </Helmet>
            <MetaTags>
                <title>{this.state.singleJob.title}</title>
                <meta name="description" content={this.state.singleJob.meta_description}/>
                <meta content="IE=edge" httpEquiv="X-UA-Compatible"/>
                <meta property="og:type" content="website"/>
                <meta property="og:url" content={"https://variiance.com" + this.state.url}/>
                <meta property="og:description" content={this.state.singleJob.meta_description}/>
                <meta property="og:title" content={this.state.singleJob.title}/>
                <meta property="og:image" content={image}/>
                <meta property="og:site_name" content="Variiance"/>
                <meta property="og:image:alt" content="single-job"/>
                <meta content={this.state.singleJob.title} name="twitter:title"/>
                <meta content={this.state.singleJob.meta_description} name="twitter:description"/>
                <meta content={image} name="twitter:image"/>
                <meta content="summary" name="twitter:card"/>
                <meta name="twitter:url" content={"https://variiance.com" + this.state.url}/>
                <meta content="@Variianceglobal" name="twitter:site"/>
            </MetaTags>
        </>);
        return <div>
            {rendered}
        </div>

    }
}

export default withTranslation()(withRouter(AllFormSections));
