import React from "react";
import {withTranslation} from "react-i18next";
import Img404 from "../Images/404.png";
import ImgNotFound from "../Images/notfound.png";
import {Link} from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import axios from "axios";

class NotFound extends React.Component {
    componentDidMount() {
        axios.get(process.env.REACT_APP_API_URL + "/404").then(r=>{
        }).catch(error=>{
        })
    }

    render() {
        const {t} = this.props;
        return (
            <>
                <div className="container-fluid">
                    <div className="row py-5 Page404">
                        <div className="col-lg-6">
                            <div className="my-5 px-5 text-center">
                                <div className="div404">
                                <LazyLoadImage src={Img404} alt="404 not found" className="Img404"/>
                                <p className="NotfoundTxt my-3"> {t("PAGE NOT FOUND")}</p>
                                    <Link to="/">
                                <button  className="NotfoundButton">
                                    <span className="NotfoundButtonTxt">{t("Go Back")} </span>
                                </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="my-5 px-5 text-center">
                                <LazyLoadImage src={ImgNotFound} alt="404 not found" className="Img404"/>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withTranslation()(NotFound);
