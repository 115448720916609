import React, {Component} from "react";
import "react-multi-carousel/lib/styles.css";
import {Spin} from "antd";
import Cookies from "universal-cookie";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import axios from "axios";
import {Link, withRouter} from "react-router-dom";
import {Helmet} from "react-helmet";
import MetaTags from "react-meta-tags";
import {IoIosArrowDropleftCircle} from "react-icons/io";
import {withTranslation} from "react-i18next";
const cookies = new Cookies();
class SingleEvent extends Component {
    state = {
        metaItemsName:[],
        metaItemsProperty:[],
        singleEvent:[],
        isLoading: true,
        metatittle:'',
        url:""
    };

    async componentDidMount() {
        window.scroll(0, 0)
        this.setState({url: this.props.match.url})

        let lang = "";
        if (cookies.get("direction") && cookies.get("direction") === "ltr" ) {
            this.setState({lang: "en"});
            lang = "en";
        } else {
            this.setState({lang: "ar"});
            lang = "ar";
        }

        await axios.get(process.env.REACT_APP_API_URL,{
            params: {
                "url": "/api/resource/Events/"+encodeURIComponent(this.props.match.params.id)
            }
        })
            .then((response) => {
                let singleEvent = response.data.data
                this.setState({singleEvent,isLoading:false})
            });


    }

    render() {

        let rendered = this.state.isLoading ? (
            <>
                <div className="container my-5">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="example">
                                <Spin size="large" style={{margin: "auto"}}/>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        ) : (
            <>
                <header className="container-fluid p-0 m-0">
                    <div className="event__header mt-5">
                        <div className='container'>
                            <Link to="/Events" ><IoIosArrowDropleftCircle className="SingleBlogArrow" size={30}
                                                                          color="#FFF"/></Link>
                            <h1 className='mb-3'>{this.state.singleEvent.title}</h1>
                            <p>{this.props.t('Event Section some little words here')}</p>
                        </div>
                    </div>
                </header>
                <main className="event__main pt-0">
                    <div className="container">
                        <h1 className="event__main__header">
                            {this.state.singleEvent.title}
                        </h1>

                        <div className="event__main__body">
                            <h2>{this.state.singleEvent.event_content}</h2>
                        </div>


                        <div className="row my-5 py-5">
                            <div
                                className="col-12 d-flex flex-md-row flex-wrap flex-column justify-content-around ">
                                <LazyLoadImage src={this.state.singleEvent.image_one} alt={this.state.singleEvent.image_one_alt}
                                     className="event__main__body--img"/>
                                <LazyLoadImage src={this.state.singleEvent.image_two} alt={this.state.singleEvent.image_two_alt}
                                     className="event__main__body--img"/>
                                <LazyLoadImage src={this.state.singleEvent.image_three} alt={this.state.singleEvent.image_three_alt}
                                     className="event__main__body--img"/>
                            </div>
                        </div>
                    </div>
                </main>
                <Helmet>
                    <link href={"https://variiance.com" + this.state.url} rel="canonical"/>
                </Helmet>
                <MetaTags>
                    <title>{this.state.singleEvent.meta_title}</title>
                    <meta name="description" content={this.state.singleEvent.meta_description}/>
                    <meta content="IE=edge" httpEquiv="X-UA-Compatible"/>
                    <meta property="og:type" content="website"/>
                    <meta property="og:url" content={"https://variiance.com" + this.state.url}/>
                    <meta property="og:description" content={this.state.singleEvent.meta_description}/>
                    <meta property="og:title" content={this.state.singleEvent.meta_title}/>
                    <meta property="og:image" content={this.state.singleEvent.event_image}/>
                    <meta property="og:image:alt" content="single-job-nfo"/>
                    <meta property="og:site_name" content="Variiance"/>
                    <meta content={this.state.singleEvent.meta_title} name="twitter:title"/>
                    <meta content={this.state.singleEvent.meta_description} name="twitter:description"/>
                    <meta content={this.state.singleEvent.event_image} name="twitter:image"/>
                    <meta content="summary" name="twitter:card"/>
                    <meta name="twitter:url" content={"https://variiance.com" + this.state.url}/>
                    <meta content="@Variianceglobal" name="twitter:site"/>
                </MetaTags>
            </>
        );
        return <div>
            {rendered}
        </div>
    }
}

export default withTranslation()(withRouter(SingleEvent));




