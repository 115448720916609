import React, {Component} from "react";
import {withTranslation} from 'react-i18next';

import WhoWeAreImg from '../Images/Group 24276.svg'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import BlockOneImg from '../Images/humaaans-standing-14.svg'
import BlockOneArrowImg from '../Images/pointer-1.svg'

import BlockTwoImg from '../Images/humaaans-standing-4.svg'
import BlockTwoArrowImg from '../Images/pointer-2.svg'

import BlockThreeImg from '../Images/humaaans-standing-24.svg'
import BlockThreeArrowImg from '../Images/pointer-3.svg'

import BlockFourImg from '../Images/humaaans-sitting-6.svg'
import BlockFourArrowImg from '../Images/pointer-4.svg'

import VariiangeImg from '../Images/image accordion.png'


import IconCardOne from '../Images/TOP MANGMENT.svg'
import IconCardTwo from '../Images/marketing.svg'
import IconCardThree from '../Images/research and development.svg'
import IconCardFour from '../Images/media production.svg'
import IconCardFive from '../Images/learning and development.svg'
import IconCardSix from '../Images/business development.svg'
import IconCardSeven from '../Images/software development.svg'
import IconCardEight from '../Images/administration.svg'
import IconCardNine from '../Images/information technologies.svg'
import IconCardTen from '../Images/human-resources.svg'
import IconCardEleven from '../Images/Legal affairs.svg'
import IconCardTwelve from '../Images/investment sales.svg'
import {Link} from "react-router-dom";


class CareersSec extends Component {
    state = {
        // allData: this.props.careersData,
    };


    render() {
        let {t, careersData, depsData} = this.props
        return (
            <>
                <header className="container-fluid p-0">
                    <div className="career__header">
                        <div className='career__header__container'>
                            <div className='career__header__container--text'>
                                <h1 className='mb-1'>{t("CAREERS")}</h1>
                                <p>{t("We are looking for these talents")}</p>
                            </div>
                            <div className='career__header__container--button mt-4'>
                                <Link to="/jobsPage">
                                    <button type="button" className="btn">{t("Find a convenient job!")}</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </header>

                <main className="container career__main">
                    <div className="career__main__whoWeAre-container">
                        <LazyLoadImage src={careersData.who_are_we_image} className="career__main__whoWeAre-container--img"
                             alt={careersData.who_are_we_image_alt}/>
                        <div className="text-container">
                            <div className="text-container__header">
                                <div className="text-container__header--border-left"/>
                                <h1 className="text-container__header--text-header">{careersData.who_are_we_title}</h1>
                            </div>
                            <h2 className="text-container__body">{careersData.who_are_we_description}</h2>
                        </div>
                    </div>
                    <div className="career__main__whyUs-container">
                        <div className="text-container">
                            <div className="text-container__header">
                                <div className="text-container__header--border-left"/>
                                <h1 className="text-container__header--text-header">{careersData.why_work_us_title}</h1>
                            </div>
                            <h2 className="text-container__body">{careersData.why_work_us_description}</h2>
                        </div>

                        <div className="flow-container">
                            <div className="flow-container__blockOne">
                                <div className="flow-container__blockOne__container-text">
                                    <h1 className="header">
                                        {careersData.why_work_with_us_description_features[0]?.title}
                                    </h1>
                                    <p className="body">
                                        {careersData.why_work_with_us_description_features[0]?.description}
                                    </p>
                                </div>
                                <div className="flow-container__blockOne--img">
                                    <LazyLoadImage src={careersData.why_work_with_us_description_features[0]?.image} alt={careersData.why_work_with_us_description_features[0]?.image_alt}
                                         className="flow-container__blockOne--img-char"/>
                                    <LazyLoadImage src={BlockOneArrowImg} alt='arrow-black'
                                         className="flow-container__blockOne--img-arrow"/>
                                </div>
                            </div>

                            <div className="flow-container__blockTwo">
                                <div className="flow-container__blockTwo--img">
                                    <LazyLoadImage src={BlockTwoArrowImg} alt='black-arrow-two'
                                         className="flow-container__blockTwo--img-arrow"/>
                                    <LazyLoadImage src={careersData.why_work_with_us_description_features[1]?.image} alt={careersData.why_work_with_us_description_features[1]?.image_alt}
                                         className="flow-container__blockTwo--img-char"/>
                                </div>
                                <div className="flow-container__blockTwo__container-text">
                                    <h1 className="header">
                                        {careersData.why_work_with_us_description_features[1]?.title}
                                    </h1>
                                    <p className="body">
                                        {careersData.why_work_with_us_description_features[1]?.description}
                                    </p>
                                </div>
                            </div>

                            <div className="flow-container__blockThree">
                                <div className="flow-container__blockThree__container-text">
                                    <h1 className="header">
                                        {careersData.why_work_with_us_description_features[2]?.title}
                                    </h1>
                                    <p className="body">
                                        {careersData.why_work_with_us_description_features[2]?.description}
                                    </p>
                                </div>
                                <div className="flow-container__blockThree--img">
                                    <LazyLoadImage src={careersData.why_work_with_us_description_features[2]?.image} alt={careersData.why_work_with_us_description_features[2]?.image_alt}
                                         className="flow-container__blockThree--img-char"/>
                                    <LazyLoadImage src={BlockThreeArrowImg} alt='black-arrow-three'
                                         className="flow-container__blockThree--img-arrow"/>
                                </div>
                            </div>

                            <div className="flow-container__blockFour">
                                <div className="flow-container__blockFour--img">
                                    <LazyLoadImage src={BlockFourArrowImg} alt='black-arrow-four'
                                         className="flow-container__blockFour--img-arrow"/>
                                    <LazyLoadImage src={careersData.why_work_with_us_description_features[3]?.image} alt={careersData.why_work_with_us_description_features[3]?.image_alt}
                                         className="flow-container__blockFour--img-char"/>
                                </div>
                                <div className="flow-container__blockFour__container-text">
                                    <h1 className="header">
                                        {careersData.why_work_with_us_description_features[3]?.title}
                                    </h1>
                                    <p className="body">
                                        {careersData.why_work_with_us_description_features[3]?.description}
                                    </p>
                                </div>
                            </div>

                            <div className="flow-container__blockVariianceImg">
                                <LazyLoadImage src={VariiangeImg} alt='logo-variiance'/>
                            </div>
                        </div>
                    </div>
                    <div className="career__main__departments-container">
                        <div className="text-container">
                            <div className="text-container__header">
                                <div className="text-container__header--border-left"/>
                                <h1 className="text-container__header--text-header">{t("Our Departments")}</h1>
                            </div>
                        </div>
                        <div className="card-container">
                            {depsData.map(dep => (
                                <div className="card-container__frame" key={dep.name}>
                                    <div className='img-container'><LazyLoadImage src={dep.icon} alt={dep.icon_alt}
                                                                        className="img-container__icon"/>
                                    </div>
                                    <div className='card-container__frame__text-container'><h1
                                        className="header">{dep.title}</h1>
                                        <p className="body">{dep.description}</p>
                                    </div>
                                </div>))
                            }

                            {/*<div className="card-container__frame">*/}
                            {/*    <div className='img-container'><LazyLoadImage src={IconCardTwo} className="img-container__icon"/>*/}
                            {/*    </div>*/}
                            {/*    <div className='card-container__frame__text-container'><h1*/}
                            {/*        className="header">Marketing</h1>*/}
                            {/*        <p className="body">Provide a value to customers and promote*/}
                            {/*            the*/}
                            {/*            business and mission of an organization by using strategic and tactical set of*/}
                            {/*            functions</p></div>*/}
                            {/*</div>*/}

                            {/*<div className="card-container__frame">*/}
                            {/*    <div className='img-container'><LazyLoadImage src={IconCardThree}*/}
                            {/*                                        className="img-container__icon"/></div>*/}
                            {/*    <div className='card-container__frame__text-container'><h1*/}
                            {/*        className="header">Research & Development</h1>*/}
                            {/*        <p className="body">You will be responsible to keep a*/}
                            {/*            business*/}
                            {/*            competitive and develop knowledge-based products for a company</p>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            {/*<div className="card-container__frame">*/}
                            {/*    <div className='img-container'><LazyLoadImage src={IconCardFour} className="img-container__icon"/>*/}
                            {/*    </div>*/}
                            {/*    <div className='card-container__frame__text-container'><h1*/}
                            {/*        className="header">Media Production</h1>*/}
                            {/*        <p className="body">Be responsible for communicating*/}
                            {/*            effective*/}
                            {/*            messages through the creation of different forms of media</p>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            {/*<div className="card-container__frame">*/}
                            {/*    <div className='img-container'><LazyLoadImage src={IconCardFive} className="img-container__icon"/>*/}
                            {/*    </div>*/}
                            {/*    <div className='card-container__frame__text-container'><h1*/}
                            {/*        className="header">Learning & Development</h1>*/}
                            {/*        <p className="body">Develop and implement learning*/}
                            {/*            strategies,*/}
                            {/*            programs and blended learning solutions using digital tools</p>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            {/*<div className="card-container__frame">*/}
                            {/*    <div className='img-container'><LazyLoadImage src={IconCardSix} className="img-container__icon"/>*/}
                            {/*    </div>*/}
                            {/*    <div className='card-container__frame__text-container'><h1*/}
                            {/*        className="header">Business Development</h1>*/}
                            {/*        <p className="body">Build strategic partnerships and analyze*/}
                            {/*            the*/}
                            {/*            business environment that impact the company and suggest solutions for future*/}
                            {/*            business growth</p></div>*/}
                            {/*</div>*/}

                            {/*<div className="card-container__frame">*/}
                            {/*    <div className='img-container'><LazyLoadImage src={IconCardSeven}*/}
                            {/*                                        className="img-container__icon"/></div>*/}
                            {/*    <div className='card-container__frame__text-container'><h1*/}
                            {/*        className="header">Software Development</h1>*/}
                            {/*        <p className="body">Collaborate with fellow software*/}
                            {/*            engineers to*/}
                            {/*            drive improvements to our technology, design and develop new services and*/}
                            {/*            solutions</p></div>*/}
                            {/*</div>*/}

                            {/*<div className="card-container__frame">*/}
                            {/*    <div className='img-container'><LazyLoadImage src={IconCardEight}*/}
                            {/*                                        className="img-container__icon"/></div>*/}
                            {/*    <div className='card-container__frame__text-container'><h1*/}
                            {/*        className="header">Administration</h1>*/}
                            {/*        <p className="body">Ensure that the administrative activities*/}
                            {/*            within an organization run efficiently by providing structure to other employees*/}
                            {/*            throughout the organization</p></div>*/}
                            {/*</div>*/}

                            {/*<div className="card-container__frame">*/}
                            {/*    <div className='img-container'><LazyLoadImage src={IconCardNine} className="img-container__icon"/>*/}
                            {/*    </div>*/}
                            {/*    <div className='card-container__frame__text-container'><h1*/}
                            {/*        className="header">Information Technologies</h1>*/}
                            {/*        <p className="body">You will generally handle all of the*/}
                            {/*            technology that the business uses, and be responsible for technical support and*/}
                            {/*            communication within a company</p></div>*/}
                            {/*</div>*/}

                            {/*<div className="card-container__frame">*/}
                            {/*    <div className='img-container'><LazyLoadImage src={IconCardTen} className="img-container__icon"/>*/}
                            {/*    </div>*/}
                            {/*    <div className='card-container__frame__text-container'><h1*/}
                            {/*        className="header">Human Resources</h1>*/}
                            {/*        <p className="body">Work both both tactically and*/}
                            {/*            strategically to maximize employee engagement and development</p>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            {/*<div className="card-container__frame">*/}
                            {/*    <div className='img-container'><LazyLoadImage src={IconCardEleven}*/}
                            {/*                                        className="img-container__icon"/></div>*/}
                            {/*    <div className='card-container__frame__text-container'><h1*/}
                            {/*        className="header">Legal Affairs</h1>*/}
                            {/*        <p className="body">Advise the company about legal issues*/}
                            {/*            related to business operations and ensure it operates in compliance with*/}
                            {/*            applicable laws and regulations</p></div>*/}
                            {/*</div>*/}

                            {/*<div className="card-container__frame">*/}
                            {/*    <div className='img-container'><LazyLoadImage src={IconCardTwelve}*/}
                            {/*                                        className="img-container__icon"/></div>*/}
                            {/*    <div className='card-container__frame__text-container'><h1*/}
                            {/*        className="header">Investment Sales</h1>*/}
                            {/*        <p className="body">You will be required to secure*/}
                            {/*            investments that promote the financial interests of an organization as well as*/}
                            {/*            build client relationships</p></div>*/}
                            {/*</div>*/}
                        </div>
                        <div className='career__header__container--button mb-5 text-center'>
                            <Link to="/jobsPage">
                                <button type="button" className="btn">{t("Apply now")}</button>
                            </Link>
                        </div>
                    </div>
                </main>
            </>
        )
    }


}

export default withTranslation()(CareersSec);
