import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import translationsEN from './en/en.json'
import translationsAR from './ar/ar.json'
import Cookies from 'universal-cookie';

const cookies = new Cookies();
if(!cookies.get("direction")){
    cookies.set("direction","ltr")
}
const resources = {
    en: {
        translation: translationsEN
    },
    ar: {
        translation: translationsAR
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .init({
        resources,
        lng: cookies.get('direction') === "ltr" ? 'en' :  'ar' ,// this is the default language. Try to put this
        fallbackLng: 'en', // default language
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
